import { useSnackbar } from 'notistack'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import LoadingButton from '@mui/lab/LoadingButton'
import { FormControl, MenuItem, Stack, TextField, Typography } from '@mui/material'

import { CustomSidebar } from 'src/components/generic/CustomSidebar'
import HotKeyButton from 'src/components/generic/HotKeyButton'
import { Toolbox } from 'src/components/generic/Toolbox'
import { Form } from 'src/components/generic/forms'
import { config } from 'src/config/config'
import { roofStoreyKey } from 'src/config/misc'
import { setCurrentModel } from 'src/pages/IfcImporter/queries/getAndSetCurrentModel'
import { useEditModelStore } from 'src/pages/IfcImporter/stores/editModelStore'
import { PlanarModel, PlanarWall, WallPlacement } from 'src/pages/IfcImporter/types'
import { postRequest } from 'src/utils/requests'

import { useGeneratedModelStore } from '../../controllers/GeneratedModelController/generatedModelStore'

export function ChangeWallPlacementTab() {
  const { t } = useTranslation(['common', 'ifcImporter', 'step5Arch'])

  const [placement, setPlacement] = useState<WallPlacement>('Internal')

  const { projectId } = useParams<{ projectId: string }>()

  const { enqueueSnackbar } = useSnackbar()

  const availableStoreys = useGeneratedModelStore(state => state.availableStoreys)

  const activeStorey = useEditModelStore(state => state.activeStorey)
  const setActiveStorey = useEditModelStore(state => state.setActiveStorey)

  const selectedWallsForPlacement = useEditModelStore(state => state.selectedWallsForPlacement)
  const setSelectedWallsForPlacement = useEditModelStore(
    state => state.setSelectedWallsForPlacement,
  )

  useEffect(() => () => setSelectedWallsForPlacement([]), [activeStorey])

  const changePlacement = useMutation(
    async () => {
      const data = selectedWallsForPlacement.map(wall => ({
        element_guid: wall,
        placement: placement,
      }))

      return (
        await postRequest<PlanarModel>({
          url: config.apiRoutes.postUpdateWallPlacement(projectId),
          data: data,
        })
      ).data
    },
    {
      onSuccess: setCurrentModel,
      onError: () => {
        enqueueSnackbar(t('step5Arch:errors.changePlacement'), { variant: 'error' })
      },
    },
  )

  return (
    <>
      <CustomSidebar>
        <Stack spacing={2} mt={1}>
          <FormControl>
            <TextField
              select
              label={t('step5Arch:editModelTab.chooseSingleStorey')}
              value={activeStorey || ''}
              onChange={event => setActiveStorey((event.target.value as string) || undefined)}
              size="small"
              sx={{ bgcolor: 'grey.50' }}
            >
              <MenuItem value={''}>({t('step5Arch:editModelTab.leaveEditMode')})</MenuItem>

              {[...availableStoreys]
                .reverse()
                .filter(storey => storey !== roofStoreyKey)
                .map(storey => (
                  <MenuItem value={storey} key={storey}>
                    {storey}
                  </MenuItem>
                ))}
            </TextField>
          </FormControl>

          {!activeStorey && (
            <Typography textAlign="center">{t('step5Arch:editModelTab.chooseStorey')}</Typography>
          )}

          {!!activeStorey && (
            <>
              {selectedWallsForPlacement.length ? (
                <>
                  <Toolbox>
                    <HotKeyButton
                      onClick={() => setSelectedWallsForPlacement([])}
                      size="small"
                      variant="outlined"
                      fullWidth
                      hotkeys="q"
                      onHotkey={() => setSelectedWallsForPlacement([])}
                      hotkeyDescription={t('ifcImporter:hotkeys.deselectElements')}
                    >
                      {t('common:actions.deselectElements')}
                    </HotKeyButton>
                  </Toolbox>

                  <Form onSubmit={changePlacement.mutate}>
                    <Toolbox pt={3}>
                      <TextField
                        select
                        value={placement}
                        onChange={e => setPlacement(e.target.value as PlanarWall['placement'])}
                        label={t('step5Arch:editModelTab.wallType')}
                        size="small"
                        sx={{ bgcolor: 'grey.50' }}
                      >
                        <MenuItem value="Internal">
                          {t('step5Arch:editModelTab.innerWall')}
                        </MenuItem>
                        <MenuItem value="External">
                          {t('step5Arch:editModelTab.outerWall')}
                        </MenuItem>
                      </TextField>
                      <LoadingButton
                        loading={changePlacement.isLoading}
                        variant="contained"
                        type="submit"
                        fullWidth
                      >
                        {t('common:actions.save')}
                      </LoadingButton>
                    </Toolbox>
                  </Form>
                </>
              ) : (
                <Typography textAlign="center">{t('step5Arch:editModelTab.clickWall')}</Typography>
              )}
            </>
          )}
        </Stack>
      </CustomSidebar>
    </>
  )
}
