// the withAuthenticationRequired HOC provided by auth0 rerenders the app when
// the route gets changed (and all queries get refetched in this case). hence we
import React, { ReactElement, useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { CircularProgress } from '@mui/material'

import { useAuth0 } from '@auth0/auth0-react'

import { config } from 'src/config/config'

import { MuiBox } from '../generic/MuiBox'

// implemented our own simple version of this
export default function ProtectedRoute(props: RouteProps): ReactElement {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !config.loginDisabled)
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      })
  }, [isAuthenticated, isLoading])

  return (!isAuthenticated || isLoading) && !config.loginDisabled ? (
    <MuiBox flex={1} justifyContent="center" alignItems="center" display="flex">
      <CircularProgress />
    </MuiBox>
  ) : (
    <Route {...props} />
  )
}
