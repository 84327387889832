// ifc element ids that directly get exported to the backend
export const directIfcExports = ['walls', 'slabs', 'roof_slabs', 'columns', 'beams']

// ifc element ids that only get exported to the backend in case their parent
// elements are included in the export
export const indirectIfcExports = ['openings']

/*
// DICT USED BY THE BACKEND TO CONVERT TYPES

IFC_TYPE_TO_GROUP_ASSIGNMENT = {
  "IfcWallStandardCase": "walls",
  "IfcSlab": "slabs",
  "IfcOpeningElement": "openings",
  "IfcSpace": "spaces",
  "IfcRoof": "roofs",
  "IfcColumn": "columns",
  "IfcBeam": "beams",
  "IfcStair": "stairs",
  "IfcStairFlight": "stairs",
  "IfcMember": "members",
  "IfcFurnishingElement": "furniture",
  "IfcDoor": "doors",
  "IfcWindow": "windows",
  "IfcElementAssembly": "compositions",
  "IfcFlowTerminal": "tga",
  "IfcBuildingElementProxy": "unclassified",
  "IfcGrid": "unclassified",
  "IfcRailing": "unclassified",
  "IfcSite": "property",
}

stored inside: p_data["PSET_data"]["MGroup"]
*/
