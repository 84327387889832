import { find, toNumber } from 'lodash-es'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Stack, Theme, Typography } from '@mui/material'

import { RectangleXYZ } from '@modugen/scene/lib/types'
import { getFlatShapeNormal, getRectCornersFromStartAndEndPoint } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import { TextField } from 'src/components/generic/forms'
import { useGeneratedModelStore } from 'src/pages/IfcImporter/controllers/GeneratedModelController/generatedModelStore'
import useStoreSubscription from 'src/pages/IfcImporter/hooks/useStoreSubscription'
import { DrawnOpening, useEditModelStore } from 'src/pages/IfcImporter/stores/editModelStore'
import { PlanarWall } from 'src/pages/IfcImporter/types'
import { LocalCoordinateSystem } from 'src/pages/IfcImporter/utils/LocalCoordinateSystem'

import { DrawOpeningSchema } from './schema'

interface Props {
  disabled: boolean
}

const FormFields = ({ disabled }: Props): ReactElement => {
  const { t } = useTranslation(['step5Arch'])

  const setDrawnOpening = useEditModelStore(state => state.setDrawnOpening)

  const selectedWall = useEditModelStore(state => state.selectedWall)
  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)

  const wall = useMemo(
    () => find(currentModelPlanar?.walls, { guid: selectedWall }),
    [selectedWall, currentModelPlanar],
  ) as PlanarWall

  useStoreSubscription({
    writeCallback: ({ startX, startY, endX, endY }: DrawOpeningSchema) => {
      const start = new ImmutableVector3(toNumber(startX), toNumber(startY), 0)
      const end = new ImmutableVector3(toNumber(endX), toNumber(endY), 0)

      const points = getRectCornersFromStartAndEndPoint(start, end)

      const wallShapeAsVectors = wall.shape.points.map(
        p => new ImmutableVector3(p.x, p.y, p.z),
      ) as RectangleXYZ
      const matrix = LocalCoordinateSystem.makeFromPolygon(wallShapeAsVectors).matrixTransform

      const normal = getFlatShapeNormal(wallShapeAsVectors)

      const openingPoints = points.map(p => p.applyMatrix4(matrix).addScaledVector(normal, 0.1))
      const opening: DrawnOpening = {
        points: openingPoints as [
          ImmutableVector3,
          ImmutableVector3,
          ImmutableVector3,
          ImmutableVector3,
        ],
      }

      setDrawnOpening(opening)
    },
  })

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography width={(theme: Theme) => theme.spacing(5)} flexShrink={0}>
          {t('step5Arch:editModelTab.start')}:
        </Typography>

        <TextField
          inputLabel="X (m)"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="startX"
          unit="m"
          disabled={disabled}
        />

        <TextField
          inputLabel="Y (m)"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="startY"
          unit="m"
          disabled={disabled}
        />
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <Typography width={(theme: Theme) => theme.spacing(5)} flexShrink={0}>
          {t('step5Arch:editModelTab.end')}:
        </Typography>

        <TextField
          inputLabel="X (m)"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="endX"
          unit="m"
          disabled={disabled}
        />

        <TextField
          inputLabel="Y (m)"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="endY"
          unit="m"
          disabled={disabled}
        />
      </Stack>
    </Stack>
  )
}

export default FormFields
