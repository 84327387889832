import React, { ReactElement, ReactNode } from 'react'

import { DialogTitle, DialogContent, Dialog, DialogActions, DialogProps } from '@mui/material'

type ReducedDialogProps = Omit<DialogProps, 'open'>

interface Props {
  open: boolean
  onClose: () => void
  title: string
  children: [ReactNode, ReactNode]
  dialogProps?: ReducedDialogProps
}

// Component (mostly) copied from modugen static. Smaller improvements done
const DialogBase = ({ children, open, onClose, title, dialogProps = {} }: Props): ReactElement => {
  const Content = children[0]
  const Actions = children[1]

  return (
    <Dialog
      {...dialogProps}
      open={open}
      onClose={onClose}
      onBackdropClick={event => event.stopPropagation()}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{Content}</DialogContent>
      <DialogActions>{Actions}</DialogActions>
    </Dialog>
  )
}

export default DialogBase
