import { uniqueId } from 'lodash-es'

import { useEffect } from 'react'

import { ThreeEvent } from '@react-three/fiber'

export const onClickGltfModelListeners: Record<string, (event: ThreeEvent<MouseEvent>) => void> = {}

export function useOnClickGltfModel(
  callback: (event: ThreeEvent<MouseEvent>) => void,
  dependencies: unknown[] = [],
): void {
  useEffect(() => {
    const uuid = uniqueId()
    onClickGltfModelListeners[uuid] = callback

    return () => {
      delete onClickGltfModelListeners[uuid]
    }
  }, dependencies)
}
