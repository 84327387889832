import axios from 'axios'
import { Object3D } from 'three'

import { config } from 'src/config/config'
import { CoordinateSystem, PlanarModel } from 'src/pages/IfcImporter/types'
import { Project } from 'src/types'
import { getRequest } from 'src/utils/requests'

import { useGeneratedModelStore } from '../controllers/GeneratedModelController/generatedModelStore'
import { useGltfModelStore } from '../controllers/GltfModelController/gltfModelStore'
import { useAssignmentsStore } from '../stores/assignmentsStore'
import { useFiltersStore } from '../stores/filtersStore'
import { useProjectStore } from '../stores/projectStore'
import { IfcElementsFilter, IfcElementAssignments, ProjectState, IfcElement } from '../types'
import { getAndParseGltfModel } from './getAndParseGltfModel'
import { ElementsResponse, getElements, setElements } from './getAndSetElements'

interface ProjectHydrationData {
  gltfModel?: Object3D
  elements?: ElementsResponse
  isInitialUpload: boolean
  filters?: IfcElementsFilter[]
  assignments?: IfcElementAssignments[]
  currentModelPlanar?: PlanarModel
  projectData: Project
  coordinateSystem?: CoordinateSystem
}

export async function fetchProjectHydrationData(
  projectId: string,
  signal: AbortSignal,
): Promise<ProjectHydrationData> {
  // QUERY DATA

  let gltfModel: Object3D | undefined
  let elements: ElementsResponse | undefined
  let isInitialUpload = false

  try {
    elements = await getElements(projectId, signal)
    gltfModel = await getAndParseGltfModel(projectId, signal)
  } catch (error) {
    // 409 = model has not been uploaded yet, setting the initial upload flag is
    // required for allowing multiple uploads in the first step
    if (
      axios.isAxiosError(error) &&
      (error.response?.status === 409 || error.response?.status === 424)
    ) {
      isInitialUpload = true
    }
    // different problem, reject and let top-level logic handle it
    else {
      throw error
    }
  }

  let filters: IfcElementsFilter[] | undefined
  let assignments: IfcElementAssignments[] | undefined

  try {
    assignments = (
      await getRequest<IfcElementAssignments[] | undefined>({
        url: config.apiRoutes.getPostAssignmentDefinitions(projectId),
        signal,
      })
    ).data

    filters = (
      await getRequest<IfcElementsFilter[] | undefined>({
        url: config.apiRoutes.getPostFilterDefinitions(projectId),
        signal,
      })
    ).data
  } catch (error) {
    if (
      !(
        axios.isAxiosError(error) &&
        (error.response?.status === 409 || error.response?.status === 424)
      )
    ) {
      throw error
    }
  }

  let currentModelPlanar: PlanarModel | undefined

  try {
    currentModelPlanar = (
      await getRequest<PlanarModel>({
        url: config.apiRoutes.getCurrentModelPlanar(projectId),
        signal: signal,
      })
    ).data
  } catch (error) {
    // 409 = model has not been aligned yet
    if (
      !(
        axios.isAxiosError(error) &&
        (error.response?.status === 409 || error.response?.status === 424)
      )
    ) {
      throw error
    }
  }

  let coordinateSystem: CoordinateSystem | undefined

  try {
    coordinateSystem = (
      await getRequest<CoordinateSystem>({
        url: config.apiRoutes.getCoordinateSystem(projectId),
        signal: signal,
      })
    ).data
  } catch (error) {
    // 409 = model has not been aligned yet
    if (
      !(
        axios.isAxiosError(error) &&
        (error.response?.status === 409 || error.response?.status === 424)
      )
    ) {
      throw error
    }
  }

  const projectData = (
    await getRequest<Project>({
      url: config.apiRoutes.getProjectInfo(projectId),
      signal: signal,
    })
  ).data

  // STORE DATA

  return {
    gltfModel,
    filters,
    assignments,
    elements,
    isInitialUpload,
    currentModelPlanar,
    projectData,
    coordinateSystem,
  }
}

export function hydrateProject(data: ProjectHydrationData): void {
  if (data.gltfModel) useGltfModelStore.getState().setGltfModel(data.gltfModel)

  if (data.filters) useFiltersStore.getState().setFilters(data.filters)
  if (data.assignments) useAssignmentsStore.getState().setAssignments(data.assignments)

  if (data.elements) setElements(data.elements)
  if (data.isInitialUpload) useGltfModelStore.getState().setIsInitialUpload(true)

  if (data.currentModelPlanar) {
    useGeneratedModelStore.getState().setCurrentModelPlanar(data.currentModelPlanar)
    useGeneratedModelStore.getState().setStoreyAssignment(data.currentModelPlanar.storey_assignment)
  }

  if (data.coordinateSystem) {
    useGltfModelStore.getState().setCoordinateSystem(data.coordinateSystem as CoordinateSystem)
  }

  useProjectStore.getState().setProject(data.projectData)
}

export function getProjectState(data: {
  isInitialUpload: boolean
  model?: Object3D | null
  filters?: IfcElementsFilter[] | null
  ifcElements?: IfcElement[] | null
  planarModel?: PlanarModel | null
}): ProjectState {
  return {
    isInitialUpload: data.isInitialUpload,
    hasModel: !!data.model && !!data.ifcElements && !!data.ifcElements.length,
    hasFilters: !!data.filters?.length,
    isAligned: !!data.planarModel,
  }
}
