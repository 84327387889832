import { get, reduce } from 'lodash-es'
import { DoubleSide, Matrix4, Shape, Vector2, Vector3 } from 'three'

import React, { ReactElement, useMemo } from 'react'

import { BasicMesh, BasicMeshProps } from '@modugen/scene/lib/components/BasicMesh'
import { config as sceneConfig } from '@modugen/scene/lib/config'
import { Line } from '@react-three/drei'
import { Color } from '@react-three/fiber'

import { PlanarElement, Element } from 'src/pages/IfcImporter/types'
import sceneColors from 'src/styles/sceneColors'

import { generateShapeEdgesFromPoints } from './utils'

export interface ShapeMeshProps extends BasicMeshProps {
  data: PlanarElement | Element
  shapeColor?: Color
  outlines?: boolean
  outlinesColor?: Color
  translucent?: boolean
  applyThickness?: boolean
}

export function ShapeMesh({
  data,
  shapeColor,
  outlines,
  outlinesColor,
  translucent,
  applyThickness,
  ...basicMeshProps
}: ShapeMeshProps): ReactElement {
  const thickness = applyThickness ? get(data, 'thickness', 0) : 0

  // bounding points as Vector3D objects
  const points = useMemo(
    () => data.shape.points.map(point => new Vector3(point.x, point.y, point.z)),
    [data.shape.points],
  )

  const matrixTransform = useMemo(() => {
    // calculate normal to extrude along
    const norm = new Vector3()
      .crossVectors(
        new Vector3().copy(points[1]).sub(points[0]),
        new Vector3().copy(points[points.length - 1]).sub(points[0]),
      )
      .normalize()

    const axisX = new Vector3().copy(points[1]).sub(points[0]).normalize()
    const axisY = new Vector3()
      .copy(points[points.length - 1])
      .sub(points[0])
      .normalize()
    const axisZ = norm

    const transform = new Matrix4().makeBasis(axisX, axisY, axisZ)
    transform.setPosition(points[0])

    return transform
  }, [points])

  const shapeLines = useMemo(
    () =>
      generateShapeEdgesFromPoints(
        data.shape.points.map(p => new Vector3(p.x, p.y, p.z)),
        thickness,
      ),
    [data],
  )

  const shapeOpeningLines = useMemo(
    () =>
      reduce(
        data.openings,
        (collector, opening) => {
          const openingLines = generateShapeEdgesFromPoints(
            opening.shape.points.map(p => new Vector3(p.x, p.y, p.z)),
            thickness,
          )
          return [...collector, ...openingLines]
        },
        [] as Vector3[][],
      ),
    [data],
  )

  const shapeObject = useMemo(() => {
    const matrixRetransform = new Matrix4().copy(matrixTransform).invert()

    // create local 2D points because a extruded geometry is two dimensional by
    // default. later in the transformation the calculated above will be used to
    // move the created geometry to the correct place in scene
    const localPoints: Vector3[] = []
    const twoDPoints: Vector2[] = []

    points.forEach(point => {
      localPoints.push(new Vector3().copy(point).applyMatrix4(matrixRetransform))
    })

    localPoints.forEach(point => {
      twoDPoints.push(new Vector2(point.x, point.y))
    })

    let minPointY = Number.POSITIVE_INFINITY
    let maxPointY = Number.NEGATIVE_INFINITY
    let minPointX = Number.POSITIVE_INFINITY
    let maxPointX = Number.NEGATIVE_INFINITY

    twoDPoints.forEach(p => {
      if (p.y < minPointY) minPointY = p.y
      if (p.y > maxPointY) maxPointY = p.y
      if (p.x < minPointX) minPointX = p.x
      if (p.x > maxPointX) maxPointX = p.x
    })

    // create a shape as the basis for the extruded geometry
    const shape = new Shape(twoDPoints)

    // add openings to the shape (if there are any)
    const openings = data.openings.map(opening =>
      opening.shape.points.map(p => new Vector3(p.x, p.y, p.z)),
    )

    for (const holePoints of openings) {
      const localPoints = holePoints.map(holePoint =>
        new Vector3().copy(holePoint).applyMatrix4(matrixRetransform),
      )

      const vec2Points = localPoints.map(holePoint => {
        let x = holePoint.x
        let y = holePoint.y

        if (holePoint.x < minPointX) x = minPointX
        if (holePoint.x > maxPointX) x = maxPointX
        if (holePoint.y < minPointY) y = minPointY
        if (holePoint.y > maxPointY) y = maxPointY

        return new Vector2(x, y)
      })

      const hole = new Shape(vec2Points)
      shape.holes.push(hole)
    }

    return shape
  }, [points, matrixTransform, data.openings])

  return (
    <>
      <BasicMesh
        // fix typing issue
        onLostPointerCapture={undefined}
        name={data.guid}
        // why disabling matrixAutoUpdate should be fine:
        // https://github.com/pmndrs/react-three-fiber/issues/635#issuecomment-682439509
        matrix={matrixTransform}
        matrixAutoUpdate={false}
        {...basicMeshProps}
      >
        <extrudeGeometry
          args={[
            shapeObject,
            {
              depth: thickness,
              bevelEnabled: false,
            },
          ]}
        />

        <meshStandardMaterial
          side={DoubleSide}
          color={shapeColor || sceneColors.elements3d.walls}
          // TODO: add opacity value to config
          opacity={translucent ? 0.33 : 1}
          transparent={translucent}
        />
      </BasicMesh>
      {outlines && (
        <group visible={basicMeshProps.visible} layers={sceneConfig.R3FNonSelectableObjectLayer}>
          {[...shapeLines, ...shapeOpeningLines].map((line, i) => (
            <Line
              key={i}
              layers={sceneConfig.R3FNonSelectableObjectLayer}
              points={line}
              renderOrder={100}
              color={outlinesColor?.toString() || sceneColors.outlines}
            ></Line>
          ))}
        </group>
      )}
    </>
  )
}
