import { useIfcElementsStore } from '../stores/ifcElementsStore'
import { useDerivedIfcData } from './useDerivedIfcData'

export default function useSelectElement() {
  const { trueIfcGroups, groupIdByChildId, mergedSelectedIds } = useDerivedIfcData()

  // no state picking as we use almost all store methods and items
  const { selectGroupIds, deselectGroupId, selectStandAloneIds, deselectStandAloneId } =
    useIfcElementsStore()

  function selectElement(ifcElementId: string) {
    // element is a group
    if (trueIfcGroups[ifcElementId]) {
      mergedSelectedIds.has(ifcElementId)
        ? deselectGroupId(ifcElementId)
        : selectGroupIds([ifcElementId])
    }
    // element is a group child
    else if (groupIdByChildId[ifcElementId]) {
      const groupId = groupIdByChildId[ifcElementId]
      mergedSelectedIds.has(groupId) ? deselectGroupId(groupId) : selectGroupIds([groupId])
    }
    // element is a stand-alone element
    else {
      mergedSelectedIds.has(ifcElementId)
        ? deselectStandAloneId(ifcElementId)
        : selectStandAloneIds([ifcElementId])
    }
  }

  return selectElement
}
