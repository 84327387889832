import React, { ReactElement } from 'react'

import { Stack, StackProps } from '@mui/material'

export function Toolbox(props: StackProps): ReactElement {
  return (
    <Stack
      spacing={2}
      border={1}
      p={1}
      pt={2}
      pb={2}
      borderColor="grey.200"
      borderRadius={1}
      {...props}
    />
  )
}
