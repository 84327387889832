import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, NavLink, useLocation } from 'react-router-dom'

import { Stack, StackProps, Divider, IconButton, SvgIcon, Typography, Tooltip } from '@mui/material'

import { MuiBox } from 'src/components/generic/MuiBox'

import { useProjectState } from '../hooks/useProjectState'
import { useEditModelStore } from '../stores/editModelStore'
import subRouterConfig from '../subRouterConfig'

export function StepsNavigation({ ...rest }: StackProps): ReactElement {
  const { t } = useTranslation()

  const projectState = useProjectState()

  // TODO: consider including the storey inside the project state
  const activeDrawingStorey = useEditModelStore(state => state.activeStorey)

  const location = useLocation()

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      divider={<Divider sx={{ width: theme => theme.spacing(8) }} />}
      {...rest}
    >
      {subRouterConfig.map(route => {
        const isActive = !!matchPath(location.pathname, `/ifc-importer/:projectId/${route.path}`)
        // @ts-ignore
        const title = t(route.i18nTitleKey) as string

        return (
          <MuiBox
            key={route.path}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Tooltip
              // we pass in an empty string here when it is active as empty strings will never show a tooltip
              title={isActive ? '' : title}
            >
              <IconButton
                component={NavLink}
                to={route.path}
                key={route.path}
                disabled={!route.isEnabled(projectState) || !!activeDrawingStorey || isActive}
                sx={{
                  '&.active': {
                    color: 'primary.main',
                  },
                }}
              >
                <SvgIcon
                  fontSize={isActive ? 'medium' : 'small'}
                  viewBox="0 0 32 32"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <route.Icon style={{ width: 'inherit', height: 'inherit' }} />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            {isActive && (
              <Typography textAlign="center" variant="caption" style={{ display: 'block' }}>
                {title}
              </Typography>
            )}
          </MuiBox>
        )
      })}
    </Stack>
  )
}
