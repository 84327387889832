import cloneDeep from 'lodash-es/cloneDeep'
import create from 'zustand'
import { combine } from 'zustand/middleware'

import { useEffect } from 'react'

import { GltfOpening as GltfOpeningMesh } from '../../types'

interface OpeningsStoreState {
  openingMeshes: GltfOpeningMesh[]
  showOpenings: boolean
}

const initialState: OpeningsStoreState = {
  openingMeshes: [],
  showOpenings: true,
}

export const useOpeningsStore = create(
  combine(cloneDeep(initialState), set => ({
    clear: () => set(cloneDeep(initialState)),

    setOpeningMeshes: (openingMeshes: GltfOpeningMesh[]) => set({ openingMeshes: openingMeshes }),

    setShowOpenings: (showOpenings: boolean) => set({ showOpenings }),
  })),
)

export function useShowOpenings(showOpenings: boolean) {
  const setShowOpenings = useOpeningsStore(state => state.setShowOpenings)
  useEffect(() => setShowOpenings(showOpenings), [showOpenings])
}
