export default {
  terms: {
    address: 'Adresse',
    projectName: 'Projektname',
    constructionProject: 'Bauprojekt',
  },

  actions: {
    createNewProject: 'Neues Projekt erstellen',
    deleteProject: 'Projekt löschen',
  },

  prompts: {
    confirmProjectDeletion: 'Bestätige die Löschung des Projekts',
  },

  errors: {
    loadingProjects: 'Fehler beim Laden der Projekte',
    createProject: 'Fehler beim Erstellen des Projekts',
    deleteProject: 'Fehler beim Löschen des Projekts',
    noProjectsLeft:
      'Du hast keine Projekte zum Anlegen mehr frei. Bitte wende dich an den Support um neue zu kaufen',
  },
}
