import { every } from 'lodash-es'
import { Object3D, Intersection } from 'three'

import React, { useRef, useState, ReactElement } from 'react'
import { useKey } from 'react-use/lib'

import {
  DraggableLine,
  DraggableLineRef,
  LineHandles,
} from '@modugen/scene/lib/components/Lines/DraggableLine'
import {
  DrawController,
  DrawControllerRef,
  TransientDrawState,
} from '@modugen/scene/lib/controllers/DrawController'
import { getOrientedWorldNormalsForIntersection } from '@modugen/scene/lib/controllers/DrawController/utils'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { toImmutable } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import sceneColors from 'src/styles/sceneColors'

import useTapelineSnapTargets from '../../hooks/useTapelineSnapTargets'
import { useEditModelStore } from '../../stores/editModelStore'

interface TransientWallState {
  isCreating?: boolean
  isFirstMove?: boolean
  isUpdating?: boolean
  lineRef?: React.RefObject<DraggableLineRef>
  drawStartSnapGuid?: string
}

enum WallElements {
  Line = 'WallLine',
  Handle = 'WallHandle',
}

const minWallLength = 0.1

const defaultWallStart = new ImmutableVector3(-100, -100, 0)
const defaultWallEnd = new ImmutableVector3(-100, -100, 0)

export function WallDrawingController(): ReactElement | null {
  const {
    setDrawnWall,
    drawnWall,
    setIsDrawnWallSelected,
    isDrawnWallSelected,
    snapToAngles,
    snapToCornersAndEdges,
    additionalSnapTargets,
    snapOrthogonal: orthoSnap,
  } = useEditModelStore()

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const transientWallState = useRef<TransientWallState>({})
  const [showTempWall, setShowTempWall] = useState(false)
  const [enableIndicator, setEnableIndicator] = useState(true)
  const [isEditingActive, setIsEditingActive] = useState(false)
  const [isDrawnWallHovered, setIsDrawnWallHovered] = useState(false)

  const drawControllerRef = useRef<DrawControllerRef>(null)
  const wallLineRef = useRef<DraggableLineRef>(null)

  const tapelineTargets = useTapelineSnapTargets()

  // WALL DRAWING RELATED

  function updateDrawnWallBasedOnTransientState() {
    const { current: tscurr } = transientWallState
    const transientDrawState = drawControllerRef.current?.transientState as TransientDrawState

    if (
      (tscurr.isUpdating || tscurr.isCreating) &&
      tscurr.lineRef?.current &&
      // check for active handle to avoid processing mouse up events that were
      // not related to a draw action
      tscurr.lineRef?.current.activeHandle
    ) {
      const activeHandle = tscurr.lineRef.current.activeHandle
      const line = tscurr.lineRef.current.stopDraggingAndGetLine()

      if (line.start.distanceTo(line.end) >= minWallLength) {
        const { drawStartSnapGuid } = tscurr

        const drawEndSnapGuid = transientDrawState.snappedPointTarget?.metaData?.guid as
          | string
          | undefined

        setDrawnWall({
          start: line.start,
          end: line.end,
          startSnapGuid: activeHandle === LineHandles.End ? drawStartSnapGuid : drawEndSnapGuid,
          endSnapGuid: activeHandle === LineHandles.End ? drawEndSnapGuid : drawStartSnapGuid,
          placement: drawnWall?.placement || 'Internal',
        })
      }
    }

    transientWallState.current = {}
  }

  function onCreateStart(transientDrawState: TransientDrawState) {
    setDrawnWall(undefined)

    if (
      // deselect wall in case wall is selected (duh)
      isDrawnWallSelected &&
      // and the mouse does not intersect a wall element
      !transientDrawState.validIntersections?.some(intersection =>
        isDrawnWallSegment(intersection.object),
      )
    ) {
      setIsDrawnWallSelected(false)
    }

    // start drawing in case the wall and a draw point are available and no
    // other handle is active
    if (transientDrawState.drawPoint && wallLineRef.current) {
      const drawStartSnapGuid = transientDrawState.snappedPointTarget?.metaData?.guid as
        | undefined
        | string

      // in order to prevent zero-width lines on every mousedown, we only
      // prepare the state but do not start drawing. setHandleAndStartDragging()
      // gets triggered inside mousemove based on the state set here
      transientWallState.current = {
        isCreating: true,
        isFirstMove: true,
        lineRef: wallLineRef,
        drawStartSnapGuid,
      }

      // the angle snapping origin needs to be defined outside of the draw
      // controller to allow for flexibility (see onLineEditStart)
      const { drawPoint, drawTarget } = transientDrawState

      if (drawPoint && drawTarget) {
        // define the plane angular snapping needs to work inside
        const snappingNormals = getOrientedWorldNormalsForIntersection(drawTarget, true)

        if (snappingNormals) {
          drawControllerRef.current?.setAngleSnappingOrigin({
            origin: drawPoint,
            ...snappingNormals,
          })
        }
      }
    }
  }

  function onEditStart(activeHandle: LineHandles) {
    setIsEditingActive(true)

    // drawStartSnapGuid needs to be inverted to the active handle (because if
    // you pick up the start handle, the end handle snap point becomes the draw
    // start snap point)
    const drawStartSnapGuid =
      activeHandle === LineHandles.End ? drawnWall?.startSnapGuid : drawnWall?.endSnapGuid

    transientWallState.current = {
      isUpdating: true,
      lineRef: wallLineRef,
      drawStartSnapGuid,
    }

    // angle snapping for existing walls follows slightly different rules
    const drawController = drawControllerRef.current
    const drawTarget = drawController?.transientState.drawTarget

    if (drawController && drawTarget) {
      // define the plane angular snapping needs to work inside by calculating
      // its normals. we currently take the draw target for defining the
      // snapping normals, this, however, might lead to problems when the
      // start and end of a line are on different planes. in the future,
      // defining the plane could also work by firing a raycast which would
      // allow for more flexibility
      const snappingNormals = getOrientedWorldNormalsForIntersection(drawTarget, true)

      // the origin point for angle snapping when editing an existing line
      // needs to be the point opposing the currently active handle
      const opposingPoint = activeHandle === LineHandles.End ? drawnWall?.start : drawnWall?.end

      if (snappingNormals && opposingPoint) {
        drawController.setAngleSnappingOrigin({
          origin: opposingPoint,
          ...snappingNormals,
        })
      }
    }
  }

  function onDrawMouseMove(transientDrawState: TransientDrawState) {
    const { validIntersections, drawPoint, angleSnappingOrigin } = transientDrawState
    const { current: tscurr } = transientWallState

    // new variable assignment so the draw point can be overridden we need to
    // use the angle snapping origin here in case the mouse has moved since
    // onDrawStart.
    let actualDrawPoint = tscurr.isFirstMove ? angleSnappingOrigin?.origin : drawPoint

    // check if the mouse intersects a wall handle
    const intersectedWallHandle = validIntersections?.find(
      intersection => intersection.object.name === WallElements.Handle,
    )

    // hide the indicator and move the draw point to the handle if yes
    if (intersectedWallHandle) {
      if (enableIndicator) setEnableIndicator(false)
      actualDrawPoint = toImmutable(intersectedWallHandle.object.position)
    } else {
      if (!enableIndicator) setEnableIndicator(true)
    }

    if (actualDrawPoint && (tscurr.isCreating || tscurr.isUpdating) && tscurr.lineRef?.current) {
      // executed on the first mousemove after line creation was started
      if (tscurr.isCreating && tscurr.isFirstMove) {
        tscurr.lineRef.current.setHandleAndStartDragging(
          LineHandles.End,
          actualDrawPoint,
          actualDrawPoint,
        )
        tscurr.isFirstMove = false
        setShowTempWall(true)
      }

      tscurr.lineRef.current.updateActiveHandle(actualDrawPoint)
    }
  }

  function onCreateEnd() {
    if (isDrawnWallSelected) return

    setShowTempWall(false)

    updateDrawnWallBasedOnTransientState()
  }

  function onEditEnd() {
    setIsEditingActive(false)

    updateDrawnWallBasedOnTransientState()
  }

  // UTILS

  function isDrawnWallSegment(object?: Object3D): boolean {
    if (!object) return false
    return (Object.values(WallElements) as string[]).includes(object.name)
  }

  function isValidDrawTarget(object: Object3D): boolean {
    let isValidTarget = !isDrawnWallSegment(object)

    const { current: tscurr } = transientWallState

    // exclude the active line in case we are creating or updating a line
    if ((tscurr.isCreating || tscurr.isUpdating) && tscurr.lineRef?.current) {
      isValidTarget =
        isValidTarget &&
        object !== tscurr.lineRef.current.startHandleRef.current &&
        object !== tscurr.lineRef.current.endHandleRef.current
    }

    return isValidTarget
  }

  function isValidDrawStart(intersections: Intersection[]): boolean {
    return every(
      intersections,
      intersection =>
        intersection.object.name !== WallElements.Line &&
        intersection.object.name !== WallElements.Handle,
    )
  }

  useKey('Escape', () => {
    setShowTempWall(false)
    drawControllerRef.current?.abortDrawing()
    drawControllerRef.current?.setAngleSnappingOrigin(undefined)
    transientWallState.current = {}
  })

  // RENDER

  return (
    <>
      <DrawController
        ref={drawControllerRef}
        enabled={!isTapelineActive}
        enableIndicator={enableIndicator && !isEditingActive && !isDrawnWallHovered}
        color={sceneColors.elements2d.internalWalls}
        snapToCornersAndEdges={snapToCornersAndEdges}
        snapToAngles={snapToAngles}
        xyOnly
        orthoSnap={orthoSnap}
        additionalSnapTargets={[...(additionalSnapTargets || []), ...tapelineTargets]}
        onDrawStart={!isEditingActive ? onCreateStart : undefined}
        onDrawEnd={!isEditingActive ? onCreateEnd : undefined}
        onMouseMove={onDrawMouseMove}
        isValidDrawTarget={isValidDrawTarget}
        isValidDrawStart={isValidDrawStart}
        indicatorType="crosshair"
      />

      {drawnWall ? (
        <DraggableLine
          ref={wallLineRef}
          line={{
            start: drawnWall.start,
            end: drawnWall.end,
          }}
          lineName={WallElements.Line}
          handleName={WallElements.Handle}
          isSelected={isDrawnWallSelected}
          color={
            drawnWall.placement === 'Internal'
              ? sceneColors.elements2d.internalWalls
              : sceneColors.elements2d.externalWalls
          }
          editable={isDrawnWallSelected}
          onClick={() => setIsDrawnWallSelected(!isDrawnWallSelected)}
          onHandleClick={() => {
            if (!isEditingActive) setIsDrawnWallSelected(true)
          }}
          onDelete={() => {
            setDrawnWall(undefined)
            setIsDrawnWallSelected(false)
          }}
          onEditStart={onEditStart}
          onEditStop={onEditEnd}
          onHover={setIsDrawnWallHovered}
          showIndicators={!isEditingActive}
        />
      ) : (
        <DraggableLine
          ref={wallLineRef}
          // initial line should be far out of the viewport of the user as
          // otherwise there is a short flickering
          line={{ start: defaultWallStart, end: defaultWallEnd }}
          lineName={WallElements.Line}
          handleName={WallElements.Handle}
          isVisible={showTempWall}
          color={sceneColors.elements2d.internalWalls}
          showIndicators={false}
        />
      )}
    </>
  )
}
