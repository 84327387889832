import { useCallback, useEffect, useMemo, useState } from 'react'

interface EventWithData extends Event {
  data: {
    visibility: string
  }
}

export default function useChatVisibility() {
  const [chatVisible, setChatVisible] = useState(true)

  useEffect(() => {
    document.addEventListener('chatlio.ready', e => {
      const event = e as EventWithData
      setChatVisible(event.data.visibility === 'open')
    })

    document.addEventListener('chatlio.visibilityChange', e => {
      const event = e as EventWithData
      setChatVisible(event.data.visibility === 'open' || event.data.visibility === undefined)
    })

    return () => {
      document.removeEventListener('chatlio.visibilityChange', () => true)
      document.removeEventListener('chatlio.ready', () => true)
    }
  }, [])

  // Cannot use refs to get element since the element is only available in public/index.html
  const hideOrShowChatButton: HTMLElement | null = useMemo(
    () => document.querySelector('[data-chatlio-widget-button]'),
    [],
  )

  const toggleChatVisibility = useCallback(() => {
    hideOrShowChatButton?.click()
  }, [chatVisible])

  return { chatVisible, toggleChatVisibility }
}
