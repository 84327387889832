import de from './de'

const en: typeof de = {
  hotkeys: {
    hotkeys: 'Shortcuts',
    availableHotkeys: 'Verfügbare Shortcuts',
    description: 'Beschreibung',
    hotkey: 'Shortcut',
    toggleHotkeysWindow: 'Open/Close shortcuts window',
  },
  support: {
    toggleSupport: 'Toggle Chat Visibility',
  },

  errors: {
    errorBoundryTitle: 'An Error Occured',
    errorBoundryMessage: 'We are sorry an error occured, We will fix it.',
    resetErrorBoundry: 'Reset',
  },
}

export default en
