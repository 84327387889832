export default {
  parsingModal: {
    processParsing: 'Parsing wird ausgeführt',
    parsingModel: 'Parsen des Modells',
    alignWalls: 'Ermitteln der Wandorientierung',
    loadOrientedModel: 'Laden des orientierten Modells',
    verticalAlignment: 'Ausrichten des Modells',
  },

  marqueeSelection: {
    selectAreaHint:
      'Bereich selektieren. Halte Strg/Meta gedrückt und wähle den Bereich des Modells, den du selektieren willst',
  },

  elementVisibility: {
    hideElements: 'Elemente verstecken',
    unhideElements: 'Elemente sichtbar machen',
    toggleVisibilityTool: "Aktiviere/Deaktiviere das Werkzeug 'Verstecken'",
  },

  camera: {
    perspectiveSwitch: 'Perspektivisch / Orthographisch',
  },

  pan: {
    resetCamera: 'Kamera zurücksetzen',
  },

  tapeline: {
    drawTapeline: 'Maßband zeichnen',
    showTapeline: 'Maßband anzeigen',
    removeTapelines: 'Alle Maßbänder entfernen',
    toggleTapelineTool: "Aktiviere/Deaktiviere das Werkzeug 'Maßband'",
  },

  byTypeSidebar: {
    ifcTypesImportedLabel: 'Importierte IFC Typen',
    ifcTypes: 'IFC Typen',
    ifcGroupsNotImported: 'Nicht relevante IFC Typen',
    ifcOpeningsHint: 'IfcOpenings werden als Teil ihrer Elternelemente exportiert',
    groupProperties: 'Eigenschaften Gruppe',
    sharedProperties: 'Geteilte Eigenschaften aller',
    elements: 'Elemente',
    subElements: 'Unterelemente',
    properties: 'Eigenschaften',
    noSharedPropertiesExist: 'Keine (geteilten) Eigenschaften vorhanden',
    noSubElementsExist: 'Keine Unterelemente vorhanden',
    openings: 'Öffnungen',
    furtherSubelements: 'Weitere Unterelemente',
  },

  routedSceneController: {
    modelNotAvailable: 'Noch kein Modell verfügbar',
    ifcFileUpload: 'Lade eine IFC-Datei über die linke Seitenleiste hoch',
  },

  hotkeys: {
    deselectElements: 'Elementauswahl aufheben',
  },

  stepsNavigation: {
    uploadModel: 'Modell hochladen',
    componentAssignment: 'Bauteile zuweisen',
    cleanseModel: 'Modell bereinigen',
    checkModel: 'Modell überprüfen',
    adaptModel: 'Modell anpassen',
    export: 'Export',
  },

  issuesModal: {
    modalTitle: 'Bei der letzten Berechnung sind Fehler oder Warnungen aufgetreten',
    warningsOccured: 'Warnungen sind bei der Verarbeitung aufgetreten',
    errorsOccured: 'Fehler sind bei der Verarbeitung aufgetreten',
    openIssues: 'Probleme öffnen',
    unknownIssue: 'Unbekanntes Problem aufgetaucht',
    unknownCause: 'Unbekannte Ursache',
    selectElementWarning: 'Deaktivieren Sie dieses Element, bevor Sie versuchen, es auszublenden',
  },

  panControls: {
    resetÇamera: 'Kamera zurücksetzen',
  },

  drawControls: {
    snapToAngles: 'an Winkel ausrichten',
    snapToCornersAndEdges: 'an Elementen ausrichten',
    snapOrthogonal: 'orthogonal an Elementen ausrichten',
  },

  // use in combination with codeToKeyMapping in errors config
  issues: {
    errorParsingElement: 'Element konnte nicht verarbeitet werden',
  },
}
