import { some, first, every } from 'lodash-es'
import { Line3, Vector3 } from 'three'
import { number, object } from 'yup'

import { isPointOnLineSegment } from '@modugen/scene/lib/utils'
import { lineString, lineIntersect } from '@turf/turf'

import i18n from 'src/localization/i18n'
import { SimpleLine } from 'src/types'

const wallOverlapThreshold = 0.01

/**
 * shorten a line on both ends by a distance in meter
 * @param line line to shorten
 * @param shortenBy distance to shorten (in meter) on end and start of line
 * @returns new line with shortened start and end
 */
function shortenLine(line: Line3, shortenBy: number) {
  const distanceFactor = shortenBy / line.distance()
  const newStart = line.at(distanceFactor, new Vector3())
  const newEnd = line.at(1 - distanceFactor, new Vector3())

  return new Line3(newStart, newEnd)
}

/**
 * Check whether lines are overlapping (not intersecting), with a small threshold
 */
function linesOverlapping(line1: Line3, line2: Line3, threshold = 0.01) {
  // as the isPointOnLineSegment check below also returns true if a line point
  // is within the treshold distance at the start and end of line (before and
  // after), we need to shorten the line a bit by the treshold distance on both ends
  const line1Shortened = shortenLine(line1, threshold)
  const line2Shortened = shortenLine(line2, threshold)

  const anyLine1PointOnLine2 =
    isPointOnLineSegment(line2Shortened, line1Shortened.start, threshold) ||
    isPointOnLineSegment(line2Shortened, line1Shortened.end, threshold)

  const anyLine2PointOnLine1 =
    isPointOnLineSegment(line1Shortened, line2Shortened.start, threshold) ||
    isPointOnLineSegment(line1Shortened, line2Shortened.end, threshold)

  return anyLine2PointOnLine1 && anyLine1PointOnLine2
}

export interface WalllineOverlappingSchema {
  startX: number
  startY: number

  endX: number
  endY: number
}

export const wallLineOverlappingSchema = {
  startX: number(),
  startY: number(),
  endX: number(),
  endY: number(),
  isIntersecting: object().test({
    name: 'check if walls are intersecting or overlapping',
    // eslint-disable-next-line space-before-function-paren
    test: function () {
      const { planarWalls } = this.options.context as {
        planarWalls: SimpleLine[]
      }

      const { startX, endX, startY, endY } = this.parent as WalllineOverlappingSchema

      // somehow the form component transfers some values to strings, hence we
      // need to reconvert them to numbers here
      const editedWallLine = lineString([
        [startX, startY],
        [endX, endY],
      ])

      const wallStart = new Vector3(startX, startY)
      const wallEnd = new Vector3(endX, endY)
      const editedWallLine3 = new Line3(wallStart, wallEnd)

      const anyWallIntersected = some(planarWalls, wall => {
        const wallLine = lineString([
          [wall.start[0], wall.start[1]],
          [wall.end[0], wall.end[1]],
        ])

        const wallLineThree = new Line3(
          new Vector3(wall.start[0], wall.start[1]),
          new Vector3(wall.end[0], wall.end[1]),
        )

        // we check here if lines are actually overlapping. Below we will
        // check whether lines are intersected
        if (linesOverlapping(editedWallLine3, wallLineThree)) return true

        const intersectedFeature = first(lineIntersect(editedWallLine, wallLine).features)

        if (!intersectedFeature) return false

        const intersectedPoint = new Vector3(
          intersectedFeature.geometry.coordinates[0],
          intersectedFeature.geometry.coordinates[1],
        )

        // it is not enough to just get intersected features, as a line is
        // also intersecting if only the end point is on the other line (this
        // happens quite often as generally walls are connected to another
        // wall). Hence we also need to check whether the intersected feature
        // point is the end of either the drawn wall or the existing wall
        // (with a small threshold applied)
        const wallEnds = [
          ...editedWallLine.geometry.coordinates,
          ...wallLine.geometry.coordinates,
        ].map(p => new Vector3(p[0], p[1]))

        // if every wall end (for both the drawn wall and the existing wall)
        // is further away from the intersection point than the threshold
        // this indicates that the lines are really intersecting.
        const anyIntersectedFeatureNotOnWallEnd = every(wallEnds, wallEnd => {
          return wallEnd.distanceTo(intersectedPoint) > wallOverlapThreshold
        })

        return anyIntersectedFeatureNotOnWallEnd
      })

      if (anyWallIntersected) {
        // @ts-ignore
        return this.createError({
          message: i18n.t('step5Arch:errors.wallsOverlapping'),
        })
      }

      return true
    },
  }),
}
