import { cloneDeep } from 'lodash-es'
import create from 'zustand'
import { combine } from 'zustand/middleware'

export enum ProcessingSteps {
  ParseModel = 'parse-model',
  CalculateOrientation = 'calculate-orientation',
  VerticalAlignment = 'vertical-alignment',
  GetOrientationResult = 'get-orientation-result',
}

interface ParseAndOrientModelStoreState {
  // undefined if it is not processing on initiation
  currentProcessingStep: ProcessingSteps | undefined
  processedSteps: ProcessingSteps[]
}

const initialState: ParseAndOrientModelStoreState = {
  currentProcessingStep: undefined,
  processedSteps: [],
}

export const useParseAndOrientModelStore = create(
  combine(initialState, set => ({
    clear: () => set(cloneDeep(initialState)),

    setProcessingStep: (currentProcessedStep: ProcessingSteps | undefined) =>
      set({ currentProcessingStep: currentProcessedStep }),

    addProcessedStep: (step: ProcessingSteps) =>
      set(state => ({ processedSteps: [...state.processedSteps, step] })),
  })),
)
