import { KeyHandler } from 'hotkeys-js'

import React, { ReactElement } from 'react'

import { Button, ButtonProps } from '@mui/material'

import useStoreHotkey from 'src/hooks/useStoreHotkey'

export type HotKeyComponentProps<T> = T & {
  hotkeys: string
  hotkeyDescription: string
  onHotkey: KeyHandler
  hotkeysDeps?: unknown[]
}

export default function HotKeyButton({
  hotkeys,
  hotkeyDescription,
  onHotkey,
  hotkeysDeps,
  ...props
}: HotKeyComponentProps<ButtonProps>): ReactElement {
  useStoreHotkey({
    keys: hotkeys,
    description: hotkeyDescription,
    callback: onHotkey,
    options: undefined,
    deps: hotkeysDeps,
  })

  return <Button {...props}>{props.children}</Button>
}
