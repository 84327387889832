import React, { ReactElement, ReactNode, useEffect } from 'react'
import { useForm, FormProvider, UseFormReturn } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

interface Props {
  onSubmit: (values: any) => void // eslint-disable-line @typescript-eslint/no-explicit-any
  children: ReactNode | ((methods: UseFormReturn) => ReactNode)
  id?: string
  validationSchema?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  validationContext?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  defaultValues?: { [key: string]: any | undefined } // eslint-disable-line @typescript-eslint/no-explicit-any
  enableReinitialize?: boolean
}

// Component copied from modugen static
const Form = ({
  onSubmit,
  children,
  defaultValues = {},
  validationSchema,
  id = '',
  enableReinitialize = false,
  validationContext,
}: Props): ReactElement => {
  const methods: UseFormReturn = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
    context: validationContext,
    ...(validationSchema ? { resolver: yupResolver(validationSchema) } : {}),
  })

  useEffect(() => {
    if (enableReinitialize) methods.reset(defaultValues)
  }, [defaultValues, enableReinitialize])

  return (
    <FormProvider {...methods}>
      <form
        id={id}
        onSubmit={event => {
          event.stopPropagation()
          methods.handleSubmit(onSubmit)(event)
        }}
      >
        {typeof children === 'function' ? children(methods) : children}
      </form>
    </FormProvider>
  )
}

export default Form
