import { config } from 'src/config/config'
import { uploadFile, postRequest, retryUntilSuccessOrFailure } from 'src/utils/requests'

export async function uploadAndConvertIfcFile({
  projectId,
  ifcFile,
  signal,
}: {
  projectId: string
  ifcFile: File
  signal: AbortSignal
}): Promise<void> {
  await uploadFile({
    url: config.apiRoutes.postUploadIfcFile(projectId),
    file: ifcFile,
    signal,
  })

  const computeGeometry = async () => {
    const response = await postRequest<{ task_id: string }>({
      url: config.apiRoutes.computeIfcToGeometry(projectId),
      signal,
    })

    await retryUntilSuccessOrFailure({
      url: config.apiRoutes.getTaskStatus(projectId, response.data.task_id),
      signal,
    })
  }

  const computeXML = async () => {
    const response = await postRequest<{ task_id: string }>({
      url: config.apiRoutes.computeIfcToXML(projectId),
      signal,
    })

    await retryUntilSuccessOrFailure({
      url: config.apiRoutes.getTaskStatus(projectId, response.data.task_id),
      signal,
    })
  }

  await Promise.all([await computeGeometry(), await computeXML()])

  const response = await postRequest<{ task_id: string }>({
    url: config.apiRoutes.computeMetaData(projectId),
    signal,
  })

  await retryUntilSuccessOrFailure({
    url: config.apiRoutes.getTaskStatus(projectId, response.data.task_id),
    signal,
  })
}
