import { Matrix4, Vector3 } from 'three'

import { getFlatShapeNormal } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

export class LocalCoordinateSystem {
  readonly xDirection: Vector3
  readonly yDirection: Vector3
  readonly zDirection: Vector3
  readonly origin: Vector3
  readonly matrixTransform: Matrix4

  constructor(xDirection: Vector3, yDirection: Vector3, zDirection: Vector3, origin: Vector3) {
    this.xDirection = xDirection
    this.yDirection = yDirection
    this.zDirection = zDirection
    this.origin = origin

    this.matrixTransform = new Matrix4().makeBasis(
      this.xDirection,
      this.yDirection,
      this.zDirection,
    )
    this.matrixTransform.setPosition(this.origin)
  }

  /**
   * Only the first three points of the polygon are used to make the local coordinate system
   * Origin is first point of polygon
   * xDirection is direction of first to second point
   * yDirection is direction of first to fourth point
   * zdirection is normal of area of first 3 points in global space
   * @param polygonPoints
   * @returns
   */
  static makeFromPolygon(polygonPoints: ImmutableVector3[]): LocalCoordinateSystem {
    const xDirection = polygonPoints[1].sub(polygonPoints[0]).normalize()
    const zDirection = getFlatShapeNormal(
      polygonPoints as [ImmutableVector3, ImmutableVector3, ImmutableVector3, ImmutableVector3],
    )

    // If polygon is a triangle, on needs to recalculate the yDirection after the cross product, otherwise
    // x and y direction are not orthogonal
    // xyz -> yzx -> zxy

    const yDirection = new ImmutableVector3().crossVectors(zDirection, xDirection).normalize()
    const origin = polygonPoints[0]

    return new LocalCoordinateSystem(xDirection.v, yDirection.v, zDirection.v, origin.v)
  }
}
