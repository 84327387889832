import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BorderInner } from '@mui/icons-material/'
import { Stack, Switch, Typography } from '@mui/material'

import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'

import { HeaderButtonGroup } from 'src/components/generic/HeaderButtonGroup'

import { EditMode, useEditModelStore } from '../../stores/editModelStore'
import { ControlsDropDown } from '../../views/step5-Arch/ModelControls/ControlsDropDown'

const DrawControls = (): ReactElement | null => {
  const { t } = useTranslation(['ifcImporter'])

  const {
    editMode,
    selectedWall,
    snapToAngles: snapToAnglesEditModel,
    snapToCornersAndEdges: snapToCornersAndEdgesEditModel,
    setSnapToAngles: setSnapToAnglesEditModel,
    setSnapToCornersAndEdges: setSnapToCornersAndEdgesEditModel,
    snapOrthogonal: snapOrthogonalEditModel,
    setSnapOrthogonal: setSnapOrthogonalEditModel,
    snapToTapelineCenters,
    setSnapToTapelineCenters,
  } = useEditModelStore()

  const {
    isActive: isTapelineActive,
    snapToAngles: snapToAnglesTapeline,
    setSnapToAngles: setSnapToAnglesTapeline,
    snapToCornersAndEdges: snapToCornersAndEdgesTapeline,
    setSnapToCornersAndEdges: setSnapToCornersAndEdgesTapeline,
    snapOrthogonal: snapOrthogonalTapeline,
    setSnapOrthogonal: setSnapOrthogonalTapeline,
    // snapToCenters: snapToCentersTapeline,
    // setSnapToCenters: setSnapToCentersTapeline,
  } = useTapelineStore()

  const isOrthographic = useCameraStore(state => state.isOrthographic)
  const enableXYFeaturesInOrthographic = useCameraStore(
    state => state.enableXYFeaturesInOrthographic,
  )

  const showControls = useMemo(() => {
    if (isTapelineActive) return true
    if (editMode === 'draw') return true
    if (selectedWall && editMode) {
      const editModesWithControls: EditMode[] = ['change', 'draw', 'move', 'opening']
      return editModesWithControls.includes(editMode)
    }
  }, [isTapelineActive, selectedWall, editMode])

  // editModelStore is leader and tapeLineStore is follower
  const snapToCornersAndEdges = snapToCornersAndEdgesEditModel
  if (snapToCornersAndEdgesEditModel !== snapToCornersAndEdgesTapeline) {
    setSnapToCornersAndEdgesTapeline(snapToCornersAndEdges)
  }
  const snapOrthogonal = snapOrthogonalEditModel
  if (snapOrthogonal !== snapOrthogonalTapeline) {
    setSnapOrthogonalTapeline(snapOrthogonal)
  }

  if (!showControls) return null

  return (
    <HeaderButtonGroup>
      <ControlsDropDown icon={<BorderInner fontSize="small" />}>
        {editMode === 'move' ? (
          // it doesn't make sense to show all controls in moving, as only
          // snapping orthogonal is relevant here
          <>
            <Stack direction="row" alignItems="center">
              <Switch
                checked={snapOrthogonal}
                onChange={event => {
                  setSnapToCornersAndEdgesTapeline(event.target.checked)
                  setSnapToCornersAndEdgesEditModel(event.target.checked)
                  setSnapOrthogonalEditModel(event.target.checked)
                  setSnapOrthogonalTapeline(event.target.checked)
                }}
                size="small"
              />
              <Typography sx={{ p: 1 }} noWrap>
                {t('ifcImporter:drawControls.snapOrthogonal')}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Switch
                checked={snapToTapelineCenters}
                onChange={event => {
                  setSnapToTapelineCenters(event.target.checked)
                }}
                size="small"
              />
              <Typography sx={{ p: 1 }} noWrap>
                an Mittelpunkten von Maßlinien ausrichten
              </Typography>
            </Stack>
          </>
        ) : (
          <>
            <Stack direction="row" alignItems="center">
              <Switch
                checked={snapToCornersAndEdges}
                disabled={snapOrthogonal && isOrthographic && !enableXYFeaturesInOrthographic}
                onChange={event => {
                  setSnapToCornersAndEdgesTapeline(event.target.checked)
                  setSnapToCornersAndEdgesEditModel(event.target.checked)
                }}
                size="small"
              />
              <Typography sx={{ p: 1 }} noWrap>
                {t('ifcImporter:drawControls.snapToCornersAndEdges')}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Switch
                checked={snapToTapelineCenters}
                onChange={event => {
                  setSnapToTapelineCenters(event.target.checked)
                }}
                size="small"
              />
              <Typography sx={{ p: 1 }} noWrap>
                an Mittelpunkten von Maßlinien ausrichten
              </Typography>
            </Stack>

            {isOrthographic && enableXYFeaturesInOrthographic && (
              <Stack direction="row" alignItems="center">
                <Switch
                  checked={snapOrthogonal}
                  disabled={!snapToCornersAndEdges}
                  onChange={event => {
                    setSnapOrthogonalTapeline(event.target.checked)
                    setSnapOrthogonalEditModel(event.target.checked)
                  }}
                  size="small"
                />
                <Typography sx={{ p: 1 }} noWrap>
                  {t('ifcImporter:drawControls.snapOrthogonal')}
                </Typography>
              </Stack>
            )}

            <Stack direction="row" alignItems="center">
              <Switch
                checked={snapToAnglesTapeline && snapToAnglesEditModel}
                onChange={event => {
                  setSnapToAnglesTapeline(event.target.checked)
                  setSnapToAnglesEditModel(event.target.checked)
                }}
                size="small"
              />
              <Typography sx={{ p: 1 }} noWrap>
                {t('ifcImporter:drawControls.snapToAngles')}
              </Typography>
            </Stack>
          </>
        )}
      </ControlsDropDown>
    </HeaderButtonGroup>
  )
}

export default DrawControls
