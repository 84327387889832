import React, { ReactElement } from 'react'

import { VisibilityOutlined, VisibilityOffOutlined, HighlightAlt } from '@mui/icons-material'
import { Typography, IconButton, Stack } from '@mui/material'

import { MuiBox } from 'src/components/generic/MuiBox'
import { capitalizeFirstLetter } from 'src/utils/misc'

export function IfcTypeItem(props: {
  title: string
  visible: boolean
  active?: boolean
  onToggleVisibility: () => void
  onSelectType?: () => void
  color?: string
}): ReactElement {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      pl={1}
      pr={0.5}
      bgcolor="grey.100"
      borderRadius={1}
    >
      <Typography>{capitalizeFirstLetter(props.title)}</Typography>

      <Stack direction="row" alignItems="center" sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}>
        {props.color && (
          <MuiBox
            sx={{ width: 40, height: 6, borderRadius: 10, mr: 1, backgroundColor: props.color }}
          />
        )}
        <IconButton onClick={props.onToggleVisibility} size="small">
          {props.visible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
        </IconButton>

        {props.onSelectType && (
          <IconButton onClick={props.onSelectType} size="small">
            <HighlightAlt />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}
