import de from './de'

const en: typeof de = {
  terms: {
    assign: 'Assign',
    assignment: 'Assignment',
  },

  actions: {
    removeAssignments: 'Remove assignment',
  },

  hints: {
    chooseIfcGroup: 'Choose the IFC Type that should be assigned to the elements',
  },

  labels: {
    existingAssignments: 'Existing Assignments',

    capturedElements: 'Captured elements',

    assignConstructionParts: 'Assignment of elements',

    filterElements: 'Filtering of elements',
  },

  hotkeys: {
    deleteLastAssignment: 'Delete last assignment',
  },

  errors: {
    saveAssignments: 'Error saving assignments',
  },
}

export default en
