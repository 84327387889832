import { toNumber } from 'lodash-es'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Stack, Theme, Typography } from '@mui/material'

import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import { TextField } from 'src/components/generic/forms'
import Select from 'src/components/generic/forms/Select'
import useStoreSubscription from 'src/pages/IfcImporter/hooks/useStoreSubscription'
import { DrawnWall, useEditModelStore } from 'src/pages/IfcImporter/stores/editModelStore'

import { DrawWallSchema } from './schema'

export default function FormFields(): ReactElement {
  const { t } = useTranslation(['step5Arch'])

  const setDrawnWall = useEditModelStore(state => state.setDrawnWall)

  useStoreSubscription({
    writeCallback: ({ startX, startY, endX, endY, placement }: DrawWallSchema) => {
      const drawnWall: DrawnWall = {
        start: new ImmutableVector3(toNumber(startX), toNumber(startY), 0),
        end: new ImmutableVector3(toNumber(endX), toNumber(endY), 0),
        placement,
      }
      setDrawnWall(drawnWall)
    },
  })

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography width={(theme: Theme) => theme.spacing(5)} flexShrink={0}>
          {t('step5Arch:editModelTab.start')}:
        </Typography>

        <TextField
          inputLabel="X (m)"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="startX"
        />

        <TextField
          inputLabel="Y (m)"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="startY"
        />
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <Typography width={(theme: Theme) => theme.spacing(5)} flexShrink={0}>
          {t('step5Arch:editModelTab.end')}:
        </Typography>

        <TextField
          inputLabel="X (m)"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="endX"
        />

        <TextField
          inputLabel="Y (m)"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="endY"
        />
      </Stack>

      <Select
        name="placement"
        inputLabel={t('step5Arch:editModelTab.wallType')}
        options={[
          {
            value: 'Internal',
            label: t('step5Arch:editModelTab.innerWall'),
          },
          {
            value: 'External',
            label: t('step5Arch:editModelTab.outerWall'),
          },
        ]}
      />
    </Stack>
  )
}
