import de from './de'

const en: typeof de = {
  parsingModal: {
    processParsing: 'Parsing',

    parsingModel: 'Parsing of the model',
    alignWalls: 'Determining wall alignment',
    loadOrientedModel: 'Loading oriented model',
    verticalAlignment: 'Aligning model',
  },

  marqueeSelection: {
    selectAreaHint:
      'Select area. Hold down Strg/Meta and choose the area of the model you wish to select',
  },

  elementVisibility: {
    hideElements: 'Hide elements',
    unhideElements: 'Unhide elements',
    toggleVisibilityTool: 'Enable/Disable visibility tool',
  },

  camera: {
    perspectiveSwitch: 'Perspective / Orthographic',
  },

  pan: {
    resetCamera: 'Kamera zurücksetzen',
  },

  tapeline: {
    drawTapeline: 'Draw tapeline',
    showTapeline: 'Show tapeline',
    removeTapelines: 'Remove all tapelines',
    toggleTapelineTool: 'Enable/Disable tapeline tool',
  },

  byTypeSidebar: {
    ifcTypesImportedLabel: 'Imported IFC types',
    ifcTypes: 'IFC types',
    ifcGroupsNotImported: 'Skipped IFC types',
    ifcOpeningsHint: 'IfcOpenings will be exported as part of their parent elements',
    groupProperties: 'Eigenschaften Gruppe',
    sharedProperties: 'Shared properties of all',
    elements: 'Elements',
    subElements: 'Subelements',
    properties: 'Properties',
    noSharedPropertiesExist: 'No (shared) properties exist',
    noSubElementsExist: 'No subelements exist',
    openings: 'Openings',
    furtherSubelements: 'Further subelements',
  },

  routedSceneController: {
    modelNotAvailable: 'Model not available yet',
    ifcFileUpload: 'Upload an IFC file using the left sidebar',
  },

  hotkeys: {
    deselectElements: 'Deselect all elements',
  },

  stepsNavigation: {
    uploadModel: 'Upload building model',
    componentAssignment: 'Assign components',
    cleanseModel: 'Model cleansing',
    checkModel: 'Check model',
    adaptModel: 'Model Alignment',
    export: 'Export',
  },

  issuesModal: {
    modalTitle: 'Errors or warnings occured during the last calculation',
    warningsOccured: 'Warnings occured during calculation',
    errorsOccured: 'Errors occured during calculation',
    openIssues: 'Open issues',
    unknownIssue: 'Unknown issue detected',
    unknownCause: 'Unknown cause',
    selectElementWarning: 'Disable this item before attempting to hide it',
  },

  panControls: {
    resetÇamera: 'Reset camera',
  },

  drawControls: {
    snapToAngles: 'on angles',
    snapToCornersAndEdges: 'on elements',
    snapOrthogonal: 'orthogonal on elements',
  },

  // use in combination with codeToKeyMapping in errors config
  issues: {
    errorParsingElement: 'Element could not be parsed',
  },
}

export default en
