import { Vector3 } from 'three'

import { removeDuplicatePoints, toImmutable } from '@modugen/scene/lib/utils'

import { PlanarWall } from '../types'

// project walls on plane defined by x & y coordinates and remove all
// duplicate vertices caused by the projection
export function projectPlanarWall(wall: PlanarWall) {
  return {
    guid: wall.guid,
    placement: wall.placement,
    points: removeDuplicatePoints(
      wall.shape.points.map(point => new Vector3(point.x, point.y, 0)),
    ).map(p => toImmutable(p)),
  }
}
