import { cloneDeep } from 'lodash-es'
import create, { PartialState } from 'zustand'
import { combine } from 'zustand/middleware'

import { NonNullableProperties } from 'src/types'

import { SearchableIfcElements, IfcGroups } from '../types'

interface DerivedIfcStoreState {
  trueIfcGroups: IfcGroups
  groupIdByChildId: Record<string, string>
  mergedFilteredIds: Set<string>
  mergedSelectedIds: Set<string>
  mergedSelectedIdsWithChildIds: Set<string>
  searchableIfcElements: SearchableIfcElements
}

const initialState: DerivedIfcStoreState = {
  trueIfcGroups: {},
  groupIdByChildId: {},
  mergedFilteredIds: new Set(),
  mergedSelectedIds: new Set(),
  mergedSelectedIdsWithChildIds: new Set(),
  searchableIfcElements: {},
}

// zustand does not offer derived / computed states (e.g. states that re-compute
// based on passed dependencies). however, zustand has powerful and constrained
// state-picking abilities we need to use in order to make sure that the big
// parts of the application do not re-render on every change of a derived state.
// this store is a shadow of the values calculated inside useDerivedIfcData
export const useDerivedIfcDataStore = create(
  combine(cloneDeep(initialState), set => ({
    clear: () => set(cloneDeep(initialState)),

    // generic setter that enables overriding one or multiple state properties
    setPartialState: (partialState: NonNullableProperties<PartialState<DerivedIfcStoreState>>) =>
      set(partialState),
  })),
)
