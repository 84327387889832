import React, { ReactElement } from 'react'
import { RouteProps, Redirect, Route } from 'react-router-dom'

interface ConditionalSubRouteProps extends RouteProps {
  component?: React.ComponentType
  isEnabled?: boolean
  fallbackRoute?: string
}

export function ConditionalSubRoute({
  component: Component,
  isEnabled = true,
  fallbackRoute,
  ...props
}: ConditionalSubRouteProps): ReactElement {
  return (
    <Route
      render={() =>
        isEnabled ? Component ? <Component /> : null : <Redirect to={fallbackRoute || '/'} />
      }
      {...props}
    />
  )
}
