import React, { ReactElement } from 'react'
import { useController } from 'react-hook-form'

import { MenuItem, TextField } from '@mui/material'

import FormControl from '../FormControl'

interface Props {
  name: string
  options: {
    value: string | number
    label: string
  }[]
  placeholder?: string
  label?: string
  inputLabel?: string
  disabled?: boolean
  tooltip?: TooltipContents
}

const Select = ({
  name,
  options,
  placeholder = '',
  label = '',
  inputLabel,
  disabled = false,
  tooltip,
}: Props): ReactElement => {
  const {
    field: { onChange, onBlur, name: fieldName, ref, value },
    fieldState: { error },
    // formState: { touchedFields, dirtyFields }
  } = useController({
    name,
  })

  return (
    <FormControl label={!inputLabel ? label : undefined} error={error?.message} tooltip={tooltip}>
      <TextField
        select
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        name={fieldName}
        inputRef={ref}
        value={value}
        size="small"
        disabled={disabled}
        label={inputLabel}
      >
        {options.map(({ value, label }, index: number) => (
          <MenuItem key={value.toString() + index} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
}

export default Select
