import { find } from 'lodash-es'
import { Group, Matrix4 } from 'three'

import React, { ReactElement, useMemo, useRef } from 'react'

import { getCoordinateSystemMatrix } from 'src/utils/getCoordinateSystemMatrix'

import { useIssuesStore } from '../../stores/issuesStore'
import { useGltfModelStore } from '../GltfModelController/gltfModelStore'
import Opening from './Opening'
import { useOpeningsStore } from './openingsStore'

export default React.memo(function IssuesOpeningsController(): ReactElement | null {
  const groupRef = useRef<Group>(null)

  const openingMeshes = useOpeningsStore(state => state.openingMeshes)
  const coordinateSystem = useGltfModelStore(state => state.coordinateSystem)

  const emphasizedIssue = useIssuesStore(state => state.emphasizedIssue)

  const emphasizedIssueOpening = useMemo(() => {
    if (emphasizedIssue?.element_guid) {
      return find(openingMeshes, { element_guid: emphasizedIssue.element_guid })
    }
  }, [emphasizedIssue, openingMeshes])

  const isError = useIssuesStore(
    state =>
      !!state.errors.find(
        backendError => backendError.element_guid === emphasizedIssue?.element_guid,
      ),
  )

  const matrix = useMemo(
    () => (coordinateSystem ? getCoordinateSystemMatrix(coordinateSystem) : new Matrix4()),
    [coordinateSystem],
  )

  return emphasizedIssueOpening ? (
    <group ref={groupRef} matrixAutoUpdate={false} matrix={matrix}>
      <Opening
        opening={emphasizedIssueOpening}
        state={isError ? 'hovered-issue-error' : 'hovered-issue-warning'}
      />
    </group>
  ) : null
})
