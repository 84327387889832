// React Three Fiber drei Html component uses zIndex range between 0 and
// 16777271, hence we need to set it here accordingly
export const R3FDreiHtmlMaxZIndex = 16777271

// from the threejs documentation on layers on Object3D: "This property can also
// be used to filter out unwanted objects in ray-intersection tests when using
// Raycaster.". By default react three fiber uses first layer (0) for raycasting
// and all newly created objects. We can set layer 1 for all objects that should
// not be covered by raycasting (currently used for lineSegments as messes with
// click events)"
export const R3FNonSelectableObjectLayer = 1

// roofs do no have a storey key by default, however, the frontend needs one in
// order to identify the related elements
export const roofStoreyKey = 'Dach'

// we set a really small offset for the edges geometries because the line
// segments generated by EdgesGeometry for the openings will be buggy if they
// are on the exact same x, y, or z axis. this case happens quite often for the
// sample models and will result in a line between two openings, if their upper
// bound is on the same height and axis. this is the only workaround we found
// without re-implementing EdgesGeometry ourselves
export const R3FEdgesGeoemtryOffset = 0.00001
