import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import * as yup from 'yup'

import React, { useEffect, ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'

import { yupResolver } from '@hookform/resolvers/yup'

import { config } from 'src/config/config'
import yupLocales from 'src/config/yupLocales'
import { Project } from 'src/types'
import { postRequest } from 'src/utils/requests'

import { useProjectStore } from '../IfcImporter/stores/projectStore'

yup.setLocale(yupLocales)

const validationSchema = yup.object().shape({
  name: yup.string().min(5).max(100),
  // build in functionality of yup '.notRequired()' not working here
  // see: https://github.com/jquense/yup/issues/1267
  location: yup.string().matches(/.{5,100}/, {
    excludeEmptyString: true,
    message: 'Must be 5 characters',
  }),
  construction_project: yup.string().matches(/.{5,100}/, {
    excludeEmptyString: true,
    message: 'Must be 5 characters',
  }),
})

interface CreationFormData {
  name: string
  location: string
  construction_project: string
}

export function CreationDialog({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}): ReactElement {
  const { t } = useTranslation(['common', 'main'])
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const setProject = useProjectStore(state => state.setProject)

  const {
    register: registerFormInput,
    handleSubmit: handleFormSubmit,
    formState: { errors: formErrors },
    reset: resetForm,
  } = useForm<CreationFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  })

  useEffect(resetForm, [isOpen])

  const newProjectMutation = useMutation(
    (data: CreationFormData) =>
      postRequest<Project>({
        url: config.apiRoutes.getPostProjects,
        data,
      }),
    {
      onSuccess: ({ data }) => {
        onClose()
        setProject(data)
        history.push(`/ifc-importer/${data.guid}/step-1-upload`)
        queryClient.invalidateQueries('getRemainingProjects')
      },
      onError: error => {
        if ((error as AxiosError).response?.status === 402) {
          enqueueSnackbar(t('main:errors.noProjectsLeft'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('main:errors.createProject'), { variant: 'error' })
        }
      },
    },
  )

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box
        component="form"
        width={theme => theme.spacing(50)}
        noValidate
        autoComplete="off"
        onSubmit={handleFormSubmit(data => newProjectMutation.mutate(data))}
      >
        <DialogTitle>{t('main:actions.createNewProject')}</DialogTitle>

        <DialogContent>
          <TextField
            {...registerFormInput('name')}
            label={t('main:terms.projectName')}
            error={!!formErrors.name}
            helperText={formErrors.name?.message}
            fullWidth
            margin="dense"
            multiline
          />

          <TextField
            {...registerFormInput('location')}
            label={t('main:terms.address')}
            error={!!formErrors.location}
            helperText={formErrors.location?.message}
            fullWidth
            margin="dense"
          />

          <TextField
            {...registerFormInput('construction_project')}
            label={t('main:terms.constructionProject')}
            error={!!formErrors.construction_project}
            helperText={formErrors.construction_project?.message}
            fullWidth
            margin="dense"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>{t('common:actions.cancel')}</Button>
          <LoadingButton loading={newProjectMutation.isLoading} variant="contained" type="submit">
            {t('common:actions.create')}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
