import { find } from 'lodash-es'

import React, { ReactElement, useEffect, useMemo, useRef } from 'react'
import { useKey } from 'react-use/lib'

import {
  DraggableRectangle,
  DraggableRectangleRef,
} from '@modugen/scene/lib/components/DraggableRectangle/DraggableRectangle'
import { RectangleHandles } from '@modugen/scene/lib/components/DraggableRectangle/types'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import {
  DrawController,
  DrawControllerRef,
  TransientDrawState,
} from '@modugen/scene/lib/controllers/DrawController'
import { MeasurementScaleController } from '@modugen/scene/lib/controllers/MeasurementScaleController'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { useOrthoCamPosition } from '@modugen/scene/lib/hooks/useOrthoCamPosition'
import { RectangleXYZ } from '@modugen/scene/lib/types'
import { getCenterMultiple, getFlatShapeNormal } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import { PlanarWall } from 'src/pages/IfcImporter/types'

import useTapelineSnapTargets from '../../hooks/useTapelineSnapTargets'
import { useEditModelStore } from '../../stores/editModelStore'
import { useGeneratedModelStore } from '../GeneratedModelController/generatedModelStore'
import { useGltfModelStore } from '../GltfModelController/gltfModelStore'
import { OpeningElements } from '../SelectOpeningController'

const minOpeningSize = 0.3

interface Props {
  guid: string
}

export function WallDrawOpeningController({ guid }: Props): ReactElement {
  const gltfModelTranslucent = useGltfModelStore(state => state.gltfModelTranslucent)
  const setGltfModelTranslucent = useGltfModelStore(state => state.setGltfModelTranslucent)

  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)
  const currentModelTranslucent = useGeneratedModelStore(state => state.currentModelTranslucent)
  const setCurrentModelTranslucent = useGeneratedModelStore(
    state => state.setCurrentModelTranslucent,
  )

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const drawnOpening = useEditModelStore(state => state.drawnOpening)
  const setDrawnOpening = useEditModelStore(state => state.setDrawnOpening)
  const setSelectedOpening = useEditModelStore(state => state.setSelectedOpening)
  const isDrawingOpeningActive = useEditModelStore(state => state.isDrawingOpeningActive)
  const setIsDrawingOpeningActive = useEditModelStore(state => state.setIsDrawingOpeningActive)
  const snapToCornersAndEdges = useEditModelStore(state => state.snapToCornersAndEdges)
  const snapToAngles = useEditModelStore(state => state.snapToAngles)

  const setEnableXYFeaturesInOrthographic = useCameraStore(
    state => state.setEnableXYFeaturesInOrthographic,
  )

  const draggableRectangleRef = useRef<DraggableRectangleRef>(null)
  const drawControllerRef = useRef<DrawControllerRef>(null)

  const tapelineTargets = useTapelineSnapTargets()

  const wall = useMemo(
    () => find(currentModelPlanar?.walls, { guid }),
    [guid, currentModelPlanar],
  ) as PlanarWall

  const wallShape = useMemo(
    () => wall?.shape.points.map(p => new ImmutableVector3(p.x, p.y, p.z)) as RectangleXYZ,
    [wall],
  )

  const wallNormal = useMemo(() => getFlatShapeNormal(wallShape), [wallShape])

  const wallWidthDirection = useMemo(() => wallShape[1].sub(wallShape[0]).normalize(), [wallShape])
  const wallHeightDirection = useMemo(() => new ImmutableVector3(0, 0, 1), [wallShape])

  const target = useMemo(() => getCenterMultiple(wallShape), [wallShape])
  const normal = useMemo(() => getFlatShapeNormal(wallShape), [wallShape])

  // EFFECTS

  useEffect(() => () => setDrawnOpening(undefined), [])

  useEffect(() => {
    const savedGLTFTranslucentState = gltfModelTranslucent
    const savedCurrentModelTranslucentState = currentModelTranslucent

    setGltfModelTranslucent(true)
    setCurrentModelTranslucent(true)
    setEnableXYFeaturesInOrthographic(false)
    return () => {
      setGltfModelTranslucent(savedGLTFTranslucentState)
      setCurrentModelTranslucent(savedCurrentModelTranslucentState)
      setEnableXYFeaturesInOrthographic(true)
    }
  }, [])

  useOrthoCamPosition(target, normal, { zoom: 70 })

  // OPENING DRAW EVENTS

  const onDrawStart = (state: TransientDrawState) => {
    if (state.drawPoint) {
      setDrawnOpening(undefined)
      setSelectedOpening(undefined)
      draggableRectangleRef.current?.setHandleAndStartDragging(
        RectangleHandles.End,
        state.drawPoint,
      )
    }
    setIsDrawingOpeningActive(true)
  }

  const onDrawMouseMove = (state: TransientDrawState) => {
    if (state.drawPoint && isDrawingOpeningActive) {
      draggableRectangleRef.current?.updateActiveHandle(
        state.drawPoint,
        wallWidthDirection,
        wallHeightDirection,
      )
    }
  }

  const onDrawEnd = () => {
    if (isDrawingOpeningActive && draggableRectangleRef.current) {
      const points = draggableRectangleRef.current?.stopDraggingAndGetPoints() as RectangleXYZ
      const size = points[0].distanceTo(points[2])
      if (size > minOpeningSize) setDrawnOpening({ points })

      setIsDrawingOpeningActive(false)
    }
  }

  useKey('Escape', () => {
    setIsDrawingOpeningActive(false)
    drawControllerRef.current?.abortDrawing()
    drawControllerRef.current?.setAngleSnappingOrigin(undefined)
  })

  return (
    <>
      <DrawController
        ref={drawControllerRef}
        enabled={!isTapelineActive}
        enableIndicator
        onDrawStart={onDrawStart}
        onMouseMove={onDrawMouseMove}
        onDrawEnd={onDrawEnd}
        color="blue"
        isValidDrawTarget={object => object.name !== OpeningElements.Content}
        additionalSnapTargets={tapelineTargets}
        snapToCornersAndEdges={snapToCornersAndEdges}
        snapToAngles={snapToAngles}
      />

      <DraggableRectangle
        color="blue"
        ref={draggableRectangleRef}
        showLengthIndicator={!!drawnOpening}
        // ok hear me out. I have no idea why but if I do not provide
        // shapeAsVectors as default when the points of drawnOpening are not set
        // yet, the rectangle drawn will not be visible (but only if you are too
        // close with the camera, as soon as you zoom out it works fine)
        points={drawnOpening?.points || wallShape}
        isVisible={isDrawingOpeningActive || !!drawnOpening}
        rectangleProps={{
          nonSelectable: true,
          // in order not to display the drawn opening within the wall of any of
          // the models (e.g. gltf) we apply a big offset here. As the camera is
          // orthographic in this instance this will not have any effect on
          // displaying
          offset: {
            direction: wallNormal,
            distance: 0.5,
          },
        }}
      />

      <MeasurementScaleController
        start={wallShape[0]}
        end={wallShape[2]}
        widthDirection={wallWidthDirection}
        heightDirection={wallHeightDirection}
        enabled={!isTapelineActive}
        offsetToElement={0.5}
      />
    </>
  )
}
