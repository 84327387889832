import de from './de'

const en: typeof de = {
  actions: {
    cleanseModel: 'Model cleansing',
    removeElements: 'Remove elements',
  },

  labels: {
    existingFilters: 'Existing filters',
    filter: 'Filter',
    removeFilter: 'Remove filter',
    filteredElements: 'Filtered elements',
  },

  hotkeys: {
    deleteLastFilter: 'Delete last filter',
    filterSelectedElements: 'Filter all selected elements',
  },

  errors: {
    saveFilters: 'Error saving filters',
  },
}

export default en
