import { useRef, useState } from 'react'

export function useStateRef<S>(initialState: S) {
  const [state, setState] = useState(initialState)
  const stateRef = useRef(initialState)

  function set(newState: S) {
    stateRef.current = newState
    setState(newState)
  }

  return {
    state,
    stateRef,
    set,
  }
}
