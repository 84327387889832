import { object, string } from 'yup'

import { WallPlacement } from 'src/pages/IfcImporter/types'

import {
  WalllineOverlappingSchema,
  wallLineOverlappingSchema,
} from '../../wallLineOverlappingSchema'

export interface DrawWallSchema extends WalllineOverlappingSchema {
  placement: WallPlacement
}

export const schema = object({
  placement: string().required(),
  ...wallLineOverlappingSchema,
})
