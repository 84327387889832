import { useSnackbar } from 'notistack'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams } from 'react-router'

import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'

import { MuiBox } from 'src/components/generic/MuiBox'
import { Toolbox } from 'src/components/generic/Toolbox'
import { config } from 'src/config/config'
import { PlanarModel } from 'src/pages/IfcImporter/types'
import { postRequest } from 'src/utils/requests'

import { setCurrentModel } from '../../../queries/getAndSetCurrentModel'
import { useEditModelStore } from '../../../stores/editModelStore'

export function RemoveWallSubTab(): ReactElement {
  const { t } = useTranslation('step5Arch')
  const { projectId } = useParams<{ projectId: string }>()

  const { enqueueSnackbar } = useSnackbar()

  const selectedWall = useEditModelStore(state => state.selectedWall)
  const setSelectedWall = useEditModelStore(state => state.setSelectedWall)

  const removeWallMutation = useMutation(
    async (ifcId: string) =>
      (
        await postRequest<PlanarModel>({
          url: config.apiRoutes.postRemoveWall(projectId),
          data: [ifcId],
        })
      ).data,
    {
      onSuccess: currenModelResponse => {
        setSelectedWall(undefined)
        setCurrentModel(currenModelResponse)
      },
      onError: () => {
        enqueueSnackbar(t('errors.removeWall'), { variant: 'error' })
      },
    },
  )

  return selectedWall ? (
    <>
      <Toolbox>
        <MuiBox overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" flexGrow={1}>
          <Typography variant="body2" fontWeight="bold" color="grey.600">
            {t('editModelTab.wall')}:
          </Typography>
          <Typography overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {selectedWall}
          </Typography>
        </MuiBox>

        <LoadingButton
          onClick={() => removeWallMutation.mutate(selectedWall)}
          loading={removeWallMutation.isLoading}
          variant="contained"
          fullWidth
        >
          {t('editModelTab.remove')}
        </LoadingButton>
      </Toolbox>
    </>
  ) : (
    <Typography textAlign="center">{t('editModelTab.clickWall')}</Typography>
  )
}
