import { config } from 'src/config/config'
import { getRequest } from 'src/utils/requests'

import { useGeneratedModelStore } from '../controllers/GeneratedModelController/generatedModelStore'
import { PlanarModel } from '../types'

export async function getCurrentModel(
  projectId: string,
  signal?: AbortSignal,
): Promise<PlanarModel> {
  const planarResult = await getRequest<PlanarModel>({
    url: config.apiRoutes.getCurrentModelPlanar(projectId),
    signal,
  })

  return planarResult.data
}

export function setCurrentModel(planarModel: PlanarModel): void {
  useGeneratedModelStore.getState().setCurrentModelPlanar(planarModel)
  useGeneratedModelStore.getState().setStoreyAssignment(planarModel.storey_assignment)
}
