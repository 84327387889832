import { Group } from 'three'

import React, { useRef, useLayoutEffect, ReactElement } from 'react'

import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { ThreeEvent } from '@react-three/fiber'

import sceneColors from 'src/styles/sceneColors'

import { IfcElementsStoreState } from '../../stores/ifcElementsStore'
import { PlanarModel } from '../../types'
import { getCenterFromPlanarModel } from '../../utils/getCenterFromPlanarModel'
import { GeneratedModelMesh } from './elements/GeneratedModelMesh'
import { useGeneratedModelStore } from './generatedModelStore'
import { onClickCurrentModelListeners } from './useOnClickGeneratedModel'

function hiddenIdSelectorCurrentModel(state: IfcElementsStoreState, ifcId: string) {
  return state.hiddenIfcIdsCurrentModel.has(ifcId)
}

// memoizing the controller is essential to prevent useless re-renders
export const GeneratedModelController = React.memo(
  function GeneratedModelController(): ReactElement | null {
    const groupRef = useRef<Group>(null)

    // no state picking as we use almost all store methods
    const { currentModelPlanar, currentModelTranslucent, outlinesColor } = useGeneratedModelStore()

    const setCameraRotationTarget = useCameraStore(state => state.setRotationTarget)

    useLayoutEffect(() => {
      if (!currentModelPlanar) return

      const center = getCenterFromPlanarModel(currentModelPlanar)

      setCameraRotationTarget(center)
    }, [currentModelPlanar])

    return (
      <group ref={groupRef}>
        {currentModelPlanar && (
          <GeneratedModel
            model={currentModelPlanar}
            translucent={currentModelTranslucent}
            hiddenIdSelector={hiddenIdSelectorCurrentModel}
            applyThickness={false}
            onClickMesh={event =>
              Object.values(onClickCurrentModelListeners).forEach(callback => callback(event))
            }
            outlinesColor={
              outlinesColor === 'dark' ? sceneColors.outlinesDark : sceneColors.outlines
            }
          />
        )}
      </group>
    )
  },
)

interface GeneratedModelProps {
  model: PlanarModel
  translucent: boolean
  applyThickness: boolean
  hiddenIdSelector: (state: IfcElementsStoreState, ifcId: string) => boolean
  onClickMesh: (event: ThreeEvent<MouseEvent>) => void
  outlinesColor?: string
}

function GeneratedModel({
  model,
  translucent,
  applyThickness,
  hiddenIdSelector,
  onClickMesh,
  outlinesColor,
}: GeneratedModelProps): ReactElement {
  return (
    <group>
      {model.walls.map(wall => (
        <GeneratedModelMesh
          key={wall.guid}
          data={wall}
          translucent={translucent}
          shapeColor={
            wall.placement === 'Internal'
              ? sceneColors.elements3d.walls
              : sceneColors.elements3d.wallsExternal
          }
          hiddenIdSelector={hiddenIdSelector}
          applyThickness={applyThickness}
          onClick={onClickMesh}
          outlinesColor={outlinesColor}
        />
      ))}

      {model.slabs.map(slab => (
        <GeneratedModelMesh
          key={slab.guid}
          data={slab}
          translucent={translucent}
          shapeColor={sceneColors.elements3d.slabs}
          hiddenIdSelector={hiddenIdSelector}
          applyThickness={applyThickness}
          onClick={onClickMesh}
          outlinesColor={outlinesColor}
        />
      ))}

      {model.roof_slabs.map(roofSlab => (
        <GeneratedModelMesh
          key={roofSlab.guid}
          data={roofSlab}
          translucent={translucent}
          shapeColor={sceneColors.elements3d.roof_slabs}
          onClick={onClickMesh}
          hiddenIdSelector={hiddenIdSelector}
          applyThickness={applyThickness}
          outlinesColor={outlinesColor}
        />
      ))}
    </group>
  )
}
