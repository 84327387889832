import { first, some } from 'lodash-es'
import { Box3, Vector3 } from 'three'
import { number, object, boolean } from 'yup'

import { getCenterMultiple, getRectCornersFromStartAndEndPoint } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { booleanContains, point, polygon, Position } from '@turf/turf'

import i18n from 'src/localization/i18n'
import { PlanarWall } from 'src/pages/IfcImporter/types'
import { LocalCoordinateSystem } from 'src/pages/IfcImporter/utils/LocalCoordinateSystem'
import { box3fromPoints } from 'src/pages/IfcImporter/utils/box3FromPoints'
import { polygonToVectors } from 'src/pages/IfcImporter/utils/polygonToVectors'

export interface DrawOpeningSchema {
  startX: number
  startY: number
  endX: number
  endY: number
}

const exceedWallThreshold = 0.01

export const schema = object({
  startX: number(),
  startY: number(),
  endX: number(),
  endY: number(),
  isExceedingWall: boolean()
    .default(false)
    .test({
      name: 'opening-exceeds-wall',
      // eslint-disable-next-line space-before-function-paren
      test: function () {
        const { wall } = this.options.context as { wall: PlanarWall }
        const { startX, startY, endX, endY } = this.parent

        const wallPoints = polygonToVectors(wall.shape)
        const wallCoordinateSystem = LocalCoordinateSystem.makeFromPolygon(wallPoints)

        const wallCenter = getCenterMultiple(wallPoints)

        // as we also want to allow drawing on the wall border, we need to apply
        // a small threshold here to the wallpoints as the turfjs contains
        // function only checks whether the opening point is fully contained
        // (see below)
        const wallPointsMoved = wallPoints.map(p => {
          const centerToPointDirection = p.sub(wallCenter).normalize()
          return p.addScaledVector(centerToPointDirection, exceedWallThreshold)
        })

        const retransform = wallCoordinateSystem.matrixTransform.clone().invert()

        const wallPointsLocal = wallPointsMoved.map(p => p.applyMatrix4(retransform))

        const localPolygonPoints = wallPointsLocal.map(p => [p.x, p.y] as Position)

        const wallPolygonLocal = polygon([
          [...localPolygonPoints, first(localPolygonPoints) as Position],
        ])

        const openingStartLocal = point([startX, startY])
        const openingEndLocal = point([endX, endY])

        if (
          !booleanContains(wallPolygonLocal, openingStartLocal) ||
          !booleanContains(wallPolygonLocal, openingEndLocal)
        ) {
          // @ts-ignore
          return this.createError({
            message: i18n.t('step5Arch:errors.openingsExceedingWall'),
          })
        }

        return true
      },
    }),
  isIntersecting: boolean()
    .default(false)
    .test({
      name: 'intersects-with-other-openings',
      // eslint-disable-next-line space-before-function-paren
      test: function () {
        const { wall } = this.options.context as { wall: PlanarWall }
        const { startX, startY, endX, endY } = this.parent

        const wallPoints = polygonToVectors(wall.shape)
        const wallCoordinateSystem = LocalCoordinateSystem.makeFromPolygon(wallPoints)

        const openingStartLocal = new ImmutableVector3(startX, startY, 0)
        const openingEndLocal = new ImmutableVector3(endX, endY, 0)

        // compute missing corners of rectangle as we need to find the min/max
        // point to construct the box that is used for intersection
        const openingCorners = getRectCornersFromStartAndEndPoint(
          openingStartLocal,
          openingEndLocal,
        )

        const drawnOpeningBox = box3fromPoints(openingCorners)

        const retransform = wallCoordinateSystem.matrixTransform.clone().invert()

        const wallOpeningBoxes = wall.openings.map(o => {
          // we need to transform the openings of global space to the local wall
          // coordinate system
          const oStart = new Vector3(o.shape.points[0].x, o.shape.points[0].y, o.shape.points[0].z)
          const oStartLocal = oStart.clone().applyMatrix4(retransform)
          const oEnd = new Vector3(o.shape.points[2].x, o.shape.points[2].y, o.shape.points[2].z)
          const oEndLocal = oEnd.clone().applyMatrix4(retransform)
          return new Box3(oStartLocal, oEndLocal)
        })

        const intersecting = some(wallOpeningBoxes, box => box.intersectsBox(drawnOpeningBox))
        if (intersecting) {
          // @ts-ignore
          return this.createError({
            message: i18n.t('step5Arch:errors.openingsOverlapping'),
          })
        }

        return true
      },
    }),
})
