import de from './de'

const en: typeof de = {
  hints: {
    loadingProject: 'Your project is being prepared',
  },

  errors: {
    hydrateProject: 'Fehler beim Vorbereiten des Projekts',
  },
}

export default en
