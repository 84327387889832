import React, { ReactElement, ReactNode, useMemo } from 'react'
import { useController, useFormContext } from 'react-hook-form'

import {
  TextField as MuiTextField,
  InputProps,
  OutlinedTextFieldProps,
  InputAdornment,
} from '@mui/material'

import FormControl from '../FormControl'
import NumberInput from '../NumberInput'

interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  name: string
  type?: 'text' | 'number'
  placeholder?: string
  label?: string | ReactNode
  inputLabel?: string
  tooltip?: TooltipContents
  InputProps?: InputProps
  format?: (value: string | number) => string | number
  unit?: string
}

// Component copied from modugen static
const TextField = ({
  name,
  type = 'text',
  rows = 1,
  multiline = false,
  autoFocus = false,
  placeholder = '',
  disabled = false,
  label = '',
  inputLabel,
  InputProps = {},
  tooltip,
  unit,
  format = value => value,
  ...textFieldProps
}: Props): ReactElement => {
  const { control } = useFormContext()
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({ name, control })

  const formattedValue = useMemo(() => format(value), [value])

  return (
    <FormControl label={!inputLabel ? label : undefined} error={error?.message} tooltip={tooltip}>
      <MuiTextField
        variant="outlined"
        onChange={onChange}
        onBlur={onBlur}
        value={formattedValue}
        // number format needs text type
        type={type}
        placeholder={placeholder}
        autoFocus={autoFocus}
        name={fieldName}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        inputRef={ref}
        size="small"
        InputProps={{
          ...(type === 'number'
            ? {
                // eslint-disable-next-line
                inputComponent: NumberInput as any,
              }
            : {}),
          endAdornment: unit ? <InputAdornment position="end">{unit}</InputAdornment> : null,
          ...InputProps,
        }}
        label={inputLabel}
        {...textFieldProps}
      />
    </FormControl>
  )
}

export default TextField
