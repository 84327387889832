import { cloneDeep } from 'lodash-es'
import create from 'zustand'
import { combine } from 'zustand/middleware'

interface Hotkey {
  key: string
  description: string
}

interface HotkeysStoreState {
  hotkeys: Hotkey[]
  hotKeyModalOpen: boolean
}

const initialState: HotkeysStoreState = {
  hotkeys: [],
  hotKeyModalOpen: false,
}

export const useHotkeysStore = create(
  combine(cloneDeep(initialState), set => ({
    clear: () => set(cloneDeep(initialState)),

    addHotkey: (hotkey: Hotkey) => set(state => ({ hotkeys: [...state.hotkeys, hotkey] })),

    removeHotkey: (hotkey: Hotkey) =>
      set(state => ({ hotkeys: state.hotkeys.filter(hkey => hkey !== hotkey) })),

    setHotKeyModalOpen: (open: boolean) => set({ hotKeyModalOpen: open }),
  })),
)
