/* eslint-disable no-template-curly-in-string */
import { LocaleObject } from 'yup/lib/locale'

export default {
  mixed: {
    default: 'Eingabe nicht gültig',
    required: 'Eingabe benötigt',
  },
  string: {
    min: 'Mindestens ${min} Zeichen benötigt',
    max: 'Maximal ${max} Zeichen erlaubt',
  },
} as LocaleObject
