import axios from 'axios'

import React, { ReactElement, useEffect, useRef } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { Stack, Toolbar } from '@mui/material'

import { OAuthError, useAuth0 } from '@auth0/auth0-react'

import { HeaderBar } from 'src/components/global/HeaderBar'
import ProtectedRoute from 'src/components/router/ProtectedRoute'
import { config } from 'src/config/config'

import Authentication from './Authentication'
import { IfcImporter } from './IfcImporter'
import { Main } from './Main'

export function App(): ReactElement {
  const { isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const authInterceptorRef = useRef<number | undefined>()

  useEffect(() => {
    console.info('Starting app with revision: ' + config.rev)
    console.info('Environment: ' + config.environment)
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      authInterceptorRef.current = axios.interceptors.request.use(async axiosConfig => {
        let token: string

        const scopeOptions = {
          audience: config.auth0Audience,
          scope: 'access:importer',
        }

        try {
          token = await getAccessTokenSilently(scopeOptions)
        } catch (error) {
          // this is needed when the user has not yet given consent to the usage
          // of his data. note: this will only be happening when running the
          // frontend on localhost as all our applications are registered as
          // first level applications and therefore do not need user consent
          // (only needed for third party applications). localhost however is
          // handled as third party application, hence we have this check here
          if ((error as OAuthError).error === 'consent_required') {
            token = await getAccessTokenWithPopup(scopeOptions)
          } else {
            throw error
          }
        }

        axiosConfig.headers = {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        }

        return axiosConfig
      })
    } else if (authInterceptorRef.current) {
      axios.interceptors.request.eject(authInterceptorRef.current)
      authInterceptorRef.current = undefined
    }

    return () => {
      if (authInterceptorRef.current) {
        axios.interceptors.request.eject(authInterceptorRef.current)
        authInterceptorRef.current = undefined
      }
    }
  }, [isAuthenticated])

  return (
    <Stack height="100vh">
      <HeaderBar />
      {/* recommended way for offsetting the height of the fixed header bar */}
      <Toolbar />

      <Switch>
        <Route path="/authorize" component={Authentication} />

        <ProtectedRoute path="/" component={Main} exact />
        <ProtectedRoute path="/ifc-importer/:projectId" component={IfcImporter} />

        {/* fallback for undefined routes */}
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Stack>
  )
}
