export default {
  actions: {
    cancel: 'Abbrechen',
    leave: 'Verlassen',
    close: 'Schließen',
    create: 'Erstellen',
    save: 'Speichern',
    delete: 'Löschen',
    confirm: 'Bestätigen',

    show: 'Anzeigen',

    deselectElements: 'Ausw. aufheben',
    confirmAndContinue: 'Bestätigen & weiter',
    saveAndContinue: 'Speichern & Weiter',
    deleteAndClose: 'Löschen & Schließen',
  },

  terms: {
    hint: 'Hinweis',
    attention: 'Achtung!',
    transparent: 'Transparent',
    model: 'Modell',
    errors: 'Fehler',
    warnings: 'Warnungen',
    error: 'Fehler',
    warning: 'Warnung',

    ifcType: 'IFC Type',
    toType: 'Zum Typ',

    ifcElementTypes: {
      walls: 'Wände',
      slabs: 'Decken',
      openings: 'Öffnungen',
      roofs: 'Dächer',
      // underscore here because we use this key for translating of the
      // automatically assigned ifc types
      roof_slabs: 'Dachflächen',
      columns: 'Stützen',
      beams: 'Träger',
    },
  },

  properties: {
    chosen: 'Ausgewählt',
  },

  prompts: {
    processNotCompleted:
      'Der Vorgang ist noch nicht abgeschlossen. Wenn du die Ansicht jetzt verlässt, wird der Vorgang abgebrochen',
  },

  errors: {
    retriesExhausted: 'Vorgang wiederholt fehlgeschlagen',
    taskExecution: 'Fehler beim Ausführen der Aufgabe',
    tokenRequest: 'Benutzertoken konnte nicht ermittelt werden. Nicht eingeloggt?',
  },
}
