import React, { useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AddCircleOutline,
  Crop54,
  OpenInFullOutlined,
  PanToolOutlined,
  Gite,
} from '@mui/icons-material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  Typography,
  TextField,
  MenuItem,
  FormControl,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from '@mui/material'

import { CustomSidebar } from 'src/components/generic/CustomSidebar'
import { roofStoreyKey } from 'src/config/misc'

import { useGeneratedModelStore } from '../../../controllers/GeneratedModelController/generatedModelStore'
import { useEditModelStore } from '../../../stores/editModelStore'
import { ChangeWallPlacement } from '../components/ChangeWallPlacement'
import { AddOpeningSubTab } from './AddOpeningSubTab'
import { ChangeWallLengthSubTab } from './ChangeWallLengthSubTab'
import { DrawWallSubTab } from './DrawWallSubTab'
import { MoveWallOrthogonalSubTab } from './MoveWallOrthogonalSubTab'
import { RemoveWallSubTab } from './RemoveWallSubTab'

export function EditModelTab(): ReactElement {
  const { t } = useTranslation(['common', 'step5Arch'])
  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)
  const availableStoreys = useGeneratedModelStore(state => state.availableStoreys)
  const deselectPlanarElements = useGeneratedModelStore(state => state.deselectElements)

  const activeStorey = useEditModelStore(state => state.activeStorey)
  const setActiveStorey = useEditModelStore(state => state.setActiveStorey)

  const setDrawnWall = useEditModelStore(state => state.setDrawnWall)
  const selectedWall = useEditModelStore(state => state.selectedWall)
  const setSelectedWall = useEditModelStore(state => state.setSelectedWall)
  const setEditedWall = useEditModelStore(state => state.setEditedWall)

  const editMode = useEditModelStore(state => state.editMode)
  const setEditMode = useEditModelStore(state => state.setEditMode)

  // ensure no wall is left drawn or edited when the user switches storeys
  useEffect(() => {
    setDrawnWall(undefined)
    setEditedWall(undefined)
    deselectPlanarElements()
  }, [activeStorey])

  // set initial state based on selected tab
  useEffect(() => {
    if (editMode === 'draw') {
      setSelectedWall(undefined)
    } else {
      setDrawnWall(undefined)
    }
  }, [editMode])

  // ensure no active storey, drawn or selected wall is left on leave drawing mode
  useEffect(
    () => () => {
      setActiveStorey(undefined)
      setDrawnWall(undefined)
      setEditedWall(undefined)
      setSelectedWall(undefined)
    },
    [],
  )

  return currentModelPlanar ? (
    <CustomSidebar>
      <Stack spacing={2} mt={1}>
        <FormControl>
          <TextField
            select
            label={t('step5Arch:editModelTab.chooseSingleStorey')}
            value={activeStorey || ''}
            onChange={event => {
              setSelectedWall(undefined)
              setActiveStorey((event.target.value as string) || undefined)
            }}
            size="small"
            sx={{ bgcolor: 'grey.50' }}
            disabled={!!selectedWall && editMode === 'opening'}
          >
            <MenuItem value={''}>({t('step5Arch:editModelTab.leaveEditMode')})</MenuItem>

            {[...availableStoreys]
              .reverse()
              .filter(storey => storey !== roofStoreyKey)
              .map(storey => (
                <MenuItem value={storey} key={storey}>
                  {storey}
                </MenuItem>
              ))}
          </TextField>
        </FormControl>

        {!activeStorey && (
          <Typography textAlign="center">{t('step5Arch:editModelTab.chooseStorey')}</Typography>
        )}

        {!!activeStorey && (
          <>
            <ToggleButtonGroup size="small" fullWidth>
              <ToggleButton
                value="visible"
                size="small"
                onClick={() => setEditMode('delete')}
                selected={editMode === 'delete'}
              >
                <Tooltip title={t('step5Arch:editModelTab.remove')}>
                  <DeleteOutlineIcon />
                </Tooltip>
              </ToggleButton>

              <ToggleButton
                value="active"
                size="small"
                onClick={() => setEditMode('draw')}
                selected={editMode === 'draw'}
              >
                <Tooltip title={t('step5Arch:editModelTab.draw')}>
                  <AddCircleOutline />
                </Tooltip>
              </ToggleButton>

              <ToggleButton
                value="active"
                size="small"
                onClick={() => setEditMode('change')}
                selected={editMode === 'change'}
              >
                <Tooltip title={t('step5Arch:editModelTab.change')}>
                  <OpenInFullOutlined />
                </Tooltip>
              </ToggleButton>

              <ToggleButton
                value="active"
                size="small"
                onClick={() => {
                  setEditMode('move')
                }}
                selected={editMode === 'move'}
              >
                <Tooltip title={t('step5Arch:editModelTab.moveWall')}>
                  <PanToolOutlined />
                </Tooltip>
              </ToggleButton>

              <ToggleButton
                value="active"
                size="small"
                onClick={() => {
                  setEditMode('opening')
                }}
                selected={editMode === 'opening'}
              >
                <Tooltip title={t('step5Arch:editModelTab.drawOpening')}>
                  <Crop54 />
                </Tooltip>
              </ToggleButton>
              <ToggleButton
                value="active"
                size="small"
                onClick={() => {
                  setEditMode('placement')
                }}
                selected={editMode === 'placement'}
              >
                <Tooltip title={t('step5Arch:editModelTab.changeWallPlacement')}>
                  <Gite />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>

            {editMode === 'delete' && <RemoveWallSubTab />}
            {editMode === 'draw' && <DrawWallSubTab />}
            {editMode === 'change' && <ChangeWallLengthSubTab />}
            {editMode === 'move' && <MoveWallOrthogonalSubTab />}
            {editMode === 'opening' && <AddOpeningSubTab />}
            {editMode === 'placement' && <ChangeWallPlacement />}
          </>
        )}
      </Stack>
    </CustomSidebar>
  ) : (
    <CustomSidebar>
      <Typography mt={1} mb={2}>
        <strong>{t('common:terms.hint')}:</strong> {t('step5Arch:editModelTab.drawHint')}
      </Typography>
    </CustomSidebar>
  )
}
