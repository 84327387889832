import de from './de'

const en: typeof de = {
  actions: {
    uploadIfc: 'Please upload your IFC file over here',
    dropIfcFile: 'Drag and Drop an IFC file to this area or click here to select files',
    uploadIfcFile: 'Upload IFC file',
  },

  hints: {
    filterModel:
      'The displayed model to the left can be filtered in the next steps and therefore be prepared for further processing',
    changeModel:
      'You can still change the displayed model. After confirming the upload you cannot change the model within this project',
  },

  errors: {
    uploadAndConvertIfc: 'Error uploading IFC model',
    forbiddenCharactersInFilename: 'Filename contains forbidden characters (e.g. ö)',
  },
}

export default en
