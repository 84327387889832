// most of the time the object name inside a GLTF model is the IFC id. however,
// sometimes we have objects (e.g. window frames) that consist of multiple
// meshes and are part of a group. in this case only the group has the correct
// IFC id and the other parts (e.g. the actually visible meshes that can be
// clicked) do not. we need to iterate through the elements of the model to
import { Object3D } from 'three'

// assign a known IFC id to a all child IFC elements
export function fixGroupChildIds(ifcIds: Set<string>, object: Object3D, parentName: string | null) {
  if (
    !ifcIds.has(object.name) && // name is not a valid IFC id
    parentName && // but parent name is available
    ifcIds.has(parentName) // and is a valid IFC id
  ) {
    object.name = parentName
  }

  object.children.forEach(child => {
    fixGroupChildIds(ifcIds, child, object.name)
  })
}
