// strongly typed to enable spinning up a separate routing config with different
// endpoints in case we require it in the future (for example for mocks)
interface ApiRoutes {
  // GENERIC ROUTES

  getTaskStatus: (projectId: string, taskId: string) => string

  // PROJECT MANAGEMENT RELATED

  getProjectInfo: (projectId: string) => string
  getPostProjects: string
  deleteProject: (projectId: string) => string
  getRemainingProjects: string

  // PROJECT INITIATION RELATED

  postUploadIfcFile: (projectId: string) => string

  getGltfModel: (projectId: string) => string
  getSimplifiedMetaData: (projectId: string) => string
  computeIfcToGeometry: (projectId: string) => string
  computeIfcToXML: (projectId: string) => string
  computeMetaData: (projectId: string) => string
  getOpeningMeshes: (projectId: string) => string

  // FILTERING AND ASSIGNING RELATED

  getPostFilterDefinitions: (projectId: string) => string
  getPostAssignmentDefinitions: (projectId: string) => string

  // ARCH VIEW RELATED

  postFilterOutput: (projectId: string) => string
  postStartParsing: (projectId: string) => string
  postStartExternalWallOrientation: (projectId: string) => string

  postInitializeArchView: (projectId: string) => string
  postStartVerticalAlignment: (projectId: string) => string

  getCurrentModelPlanar: (projectId: string) => string

  getCoordinateSystem: (projectId: string) => string

  postUpdateStoreyHeights: (projectId: string) => string

  postAddWall: (projectId: string) => string
  postAddOpeningToWall: (projectId: string) => string
  postRemoveWall: (projectId: string) => string
  postRemoveOpening: (projectId: string) => string
  postChangeWall: (projectId: string) => string
  postTranslateWall: (projectId: string) => string
  postUpdateWallPlacement: (projectId: string) => string
  postRemoveStorey: (projectId: string, storey: number) => string
  postRotate90: (projectId: string) => string
  postRemoveSlab: (projectId: string) => string
  postRemoveRoofSlab: (projectId: string) => string

  getTaskSummary: (projectId: string) => string
}

export const apiRoutes: ApiRoutes = {
  getTaskStatus: (projectId: string, taskId: string) => `/task-status/${taskId}`,

  getProjectInfo: (projectId: string) => `/projects/${projectId}`,
  getPostProjects: '/projects',
  deleteProject: (projectId: string) => `/projects/${projectId}`,
  getRemainingProjects: '/projects/management/remaining-projects',

  postUploadIfcFile: (projectId: string) => `/projects/${projectId}/upload`,

  getGltfModel: (projectId: string) => `/projects/${projectId}/geometry`,
  computeIfcToGeometry: (projectId: string) => `/projects/${projectId}/ifc2geometry/compute`,
  computeIfcToXML: (projectId: string) => `/projects/${projectId}/ifc2xml/compute`,
  computeMetaData: (projectId: string) => `/projects/${projectId}/metadata/compute`,
  getSimplifiedMetaData: (projectId: string) => `/projects/${projectId}/metadata`,
  getOpeningMeshes: (projectId: string) => `/projects/${projectId}/openings`,

  getPostFilterDefinitions: (projectId: string) =>
    `/projects/${projectId}/filter-view/filter-definitions`,
  getPostAssignmentDefinitions: (projectId: string) =>
    `/projects/${projectId}/assignment-definitions`,

  postFilterOutput: (projectId: string) => `/projects/${projectId}/filter-view/output`,
  postStartParsing: (projectId: string) => `/projects/${projectId}/parsing/compute`,

  postStartExternalWallOrientation: (projectId: string) =>
    `/projects/${projectId}/external-wall-orientation/compute`,

  postInitializeArchView: (projectId: string) => `projects/${projectId}/arch-view/initialize`,
  postStartVerticalAlignment: (projectId: string) =>
    `/projects/${projectId}/initial-vertical-alignment/compute`,

  getCurrentModelPlanar: (projectId: string) => `/projects/${projectId}/arch-view/current-model`,

  getCoordinateSystem: (projectId: string) => `/projects/${projectId}/coordinate-system`,

  postUpdateStoreyHeights: (projectId: string) =>
    `/projects/${projectId}/arch-view/update-storey-heights/compute`,

  postAddWall: (projectId: string) => `/projects/${projectId}/arch-view/add-wall`,
  postAddOpeningToWall: (projectId: string) => `/projects/${projectId}/arch-view/add-opening`,
  postRemoveWall: (projectId: string) => `/projects/${projectId}/arch-view/remove-wall`,
  postRemoveOpening: (projectId: string) => `/projects/${projectId}/arch-view/remove-opening`,
  postChangeWall: (projectId: string) =>
    `/projects/${projectId}/arch-view/update-wall-start-or-end`,
  postTranslateWall: (projectId: string) => `/projects/${projectId}/arch-view/translate-wall`,
  postUpdateWallPlacement: (projectId: string) =>
    `/projects/${projectId}/arch-view/update-wall-placement`,
  postRemoveStorey: (projectId: string, storey: number) =>
    `/projects/${projectId}/arch-view/remove-storey/${storey}`,
  postRotate90: (projectId: string) => `/projects/${projectId}/arch-view/rotate-90`,

  postRemoveSlab: (projectId: string) => `/projects/${projectId}/arch-view/remove-slab`,
  postRemoveRoofSlab: (projectId: string) => `/projects/${projectId}/arch-view/remove-roof-slab`,

  getTaskSummary: (projectId: string) => `/projects/${projectId}/task-summary`,
}
