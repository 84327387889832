import de from './de'

const en: typeof de = {
  hints: {
    accessRequired: 'You need permissions for access',
    reopenSignInPopup: 'Reopen popup for sign in',
  },

  actions: {
    signIn: 'Login',
    createAccount: 'Register',
  },

  errors: {
    userAuthentication: 'Error signing in user',
  },

  terms: {
    ifc: 'IFC',
    semanticModel: 'Semantic Model',
    theSimpleWayToImportIFCFiles: 'The simple way to import IFC-Files',
    static: 'Static',
    ifcNote: 'In the first step, we import 3D design plans and process them automatically.',
    semanticModelNote:
      'Through intelligent analysis, we create a complete semantic building model.',
    staticNote:
      'From this, together with the structural engineer, we derive a complete, verifiable statics for wooden buildings.',
  },
}

export default en
