import { config } from 'src/config/config'
import { getRequest } from 'src/utils/requests'

import { useOpeningsStore } from '../controllers/OpeningsControllers/openingsStore'
import { useIfcElementsStore } from '../stores/ifcElementsStore'
import { IfcElement, IfcGroups, IfcOpenings, SimplifiedIfcMetaData, GltfOpening } from '../types'

export interface ElementsResponse {
  propertyData: IfcElement[]
  groupDefinition: IfcGroups
  openingDefinition: IfcOpenings
  openingMeshes: GltfOpening[]
}

export async function getElements(
  projectId: string,
  signal: AbortSignal,
): Promise<ElementsResponse> {
  useIfcElementsStore.getState().clear()

  const simplifiedMetaDataResponse = await getRequest<SimplifiedIfcMetaData>({
    url: config.apiRoutes.getSimplifiedMetaData(projectId),
    signal,
  })

  const openingMeshesResponse = await getRequest<GltfOpening[]>({
    url: config.apiRoutes.getOpeningMeshes(projectId),
    signal,
  })

  return {
    propertyData: Object.values(simplifiedMetaDataResponse.data.property_data.data),
    groupDefinition: simplifiedMetaDataResponse.data.group_definition.data,
    openingDefinition: simplifiedMetaDataResponse.data.opening_definition.data,
    openingMeshes: openingMeshesResponse.data,
  }
}

export function setElements(data: ElementsResponse): void {
  useIfcElementsStore.getState().setElements(data.propertyData)
  useIfcElementsStore.getState().setGroups(data.groupDefinition)
  useIfcElementsStore.getState().setOpenings(data.openingDefinition)
  useOpeningsStore.getState().setOpeningMeshes(data.openingMeshes)
}
