import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { PhotoSizeSelectSmall } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

import { useMarqueeSelectionStore } from '@modugen/scene/lib/controllers/MarqueeSelectionController/marqueeSelectionStore'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'

import { HeaderToggleButton } from 'src/components/generic/HeaderToggleButtonGroup'

import { useDerivedIfcData } from '../../hooks/useDerivedIfcData'
import { useIfcElementsStore } from '../../stores/ifcElementsStore'
import { useIntermediateSelectedElementsStore } from '../../stores/intermediateSelectedElementsStore'

export function MarqueeSelectionControls(): ReactElement {
  const { t } = useTranslation('ifcImporter')
  const { isActive, selectedObjects, clearSelectedObjects } = useMarqueeSelectionStore()
  const isTapelineActive = useTapelineStore(state => state.isActive)

  const selectGroupIds = useIfcElementsStore(state => state.selectGroupIds)
  const selectStandAloneIds = useIfcElementsStore(state => state.selectStandAloneIds)

  const { trueIfcGroups, groupIdByChildId } = useDerivedIfcData()

  const {
    setSelectedGroupElementIds,
    setSelectedStandAloneElementIds,
    selectedGroupElementIds,
    selectedStandAloneElementIds,
    clear,
  } = useIntermediateSelectedElementsStore()

  useEffect(() => {
    if (!isActive) {
      selectGroupIds(Array.from(selectedGroupElementIds))
      selectStandAloneIds(Array.from(selectedStandAloneElementIds))

      clearSelectedObjects()
      clear()
    }
  }, [isActive])

  useEffect(() => {
    if (selectedObjects) {
      const groupIds = new Set<string>()
      const standAloneIds = new Set<string>()

      selectedObjects.forEach(object => {
        const ifcElementId = object.name

        if (!ifcElementId) return

        // element is a group
        if (trueIfcGroups[ifcElementId]) {
          groupIds.add(ifcElementId)
        }
        // element is a group child
        else if (groupIdByChildId[ifcElementId]) {
          const groupId = groupIdByChildId[ifcElementId]
          groupIds.add(groupId)
        }
        // element is a stand-alone element
        else {
          standAloneIds.add(ifcElementId)
        }
      })

      setSelectedGroupElementIds(groupIds)
      setSelectedStandAloneElementIds(standAloneIds)
    }
  }, [selectedObjects])

  return (
    <Tooltip title={t('marqueeSelection.selectAreaHint')} sx={{ mr: 1 }}>
      <HeaderToggleButton
        value="active"
        size="small"
        selected={isActive}
        disabled={isTapelineActive}
      >
        <PhotoSizeSelectSmall fontSize="small" />
      </HeaderToggleButton>
    </Tooltip>
  )
}
