import produce from 'immer'
import cloneDeep from 'lodash-es/cloneDeep'
import create from 'zustand'
import { combine } from 'zustand/middleware'

import { IfcElement, IfcGroups, IfcOpenings } from '../types'

export interface IfcElementsStoreState {
  ifcElements: IfcElement[] | null
  ifcGroups: IfcGroups | null
  ifcOpenings: IfcOpenings | null

  allIfcIds: Set<string>

  hiddenIfcIdsGltfModel: Set<string>
  hiddenIfcIdsCurrentModel: Set<string>

  // despite both containing IFC element ids, we keep the selected groupd
  // IDs and stand-alone IDs arrays separate as it simplifies all further logic
  selectedGroupIds: Set<string>
  selectedStandAloneIds: Set<string>
}

const initialState: IfcElementsStoreState = {
  ifcElements: null,
  ifcGroups: null,
  ifcOpenings: null,

  allIfcIds: new Set(),

  hiddenIfcIdsGltfModel: new Set(),
  hiddenIfcIdsCurrentModel: new Set(),

  selectedGroupIds: new Set(),
  selectedStandAloneIds: new Set(),
}

export const useIfcElementsStore = create(
  combine(cloneDeep(initialState), set => ({
    clear: () => set(cloneDeep(initialState)),

    setElements: (ifcElements: IfcElement[]) =>
      set({
        ifcElements,
        allIfcIds: new Set(ifcElements.map(element => element.PSET_data.id)),
      }),

    setGroups: (ifcGroups: IfcGroups) => set({ ifcGroups }),

    setOpenings: (ifcOpenings: IfcOpenings) => set({ ifcOpenings }),

    // HIDDEN ELEMENTS RELATED

    setHiddenIdsGltfModel: (hiddenIfcIds: Set<string>) =>
      set({ hiddenIfcIdsGltfModel: hiddenIfcIds }),

    setHiddenIdsCurrentModel: (hiddenIfcIds: Set<string>) =>
      set({ hiddenIfcIdsCurrentModel: hiddenIfcIds }),

    // ELEMENTS SELECTION RELATED

    deselectIds: (ids: string[]) =>
      set(
        produce((state: IfcElementsStoreState) => {
          for (const id of ids) {
            // no need to check if the id is already contained
            state.selectedGroupIds.delete(id)
            state.selectedStandAloneIds.delete(id)
          }
        }),
      ),

    deselectAllIds: () =>
      set(
        produce((state: IfcElementsStoreState) => {
          state.selectedGroupIds.clear()
          state.selectedStandAloneIds.clear()
        }),
      ),

    // AGGREGATE SELECTION RELATED

    selectGroupIds: (selectedGroupIds: string[]) =>
      set(
        produce((state: IfcElementsStoreState) => {
          selectedGroupIds.forEach(id => state.selectedGroupIds.add(id))
        }),
      ),

    deselectGroupId: (id: string) =>
      set(
        produce((state: IfcElementsStoreState) => {
          state.selectedGroupIds.delete(id)
        }),
      ),

    deselectAllGroupIds: () =>
      set(
        produce((state: IfcElementsStoreState) => {
          state.selectedGroupIds.clear()
        }),
      ),

    // STAND ALONE SELECTION RELATED

    selectStandAloneIds: (selectedStandAloneIds: string[]) =>
      set(
        produce((state: IfcElementsStoreState) => {
          selectedStandAloneIds.forEach(id => state.selectedStandAloneIds.add(id))
        }),
      ),

    deselectStandAloneId: (id: string) =>
      set(
        produce((state: IfcElementsStoreState) => {
          state.selectedStandAloneIds.delete(id)
        }),
      ),

    deselectAllStandAloneIds: () =>
      set(
        produce((state: IfcElementsStoreState) => {
          state.selectedStandAloneIds.clear()
        }),
      ),
  })),
)
