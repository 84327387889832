import common from 'src/localization/common'
import global from 'src/localization/global'

// we import all locales globally for typing reasons. each namespace that is
// added in the config below will be added to react-i18n.d.ts and thus will be
// typed when using the useTranslation hook of react-i18n
import authentication from '../pages/Authentication/locales'
import ifcImporter from '../pages/IfcImporter/locales'
import step0Hydrate from '../pages/IfcImporter/views/Step0-Hydrate/locales'
import step1Upload from '../pages/IfcImporter/views/Step1-Upload/locales'
import step2Assignments from '../pages/IfcImporter/views/Step2-Assignments/locales'
import step3Filter from '../pages/IfcImporter/views/Step3-Filter/locales'
import step4Confirm from '../pages/IfcImporter/views/Step4-Confirm/locales'
import step6Export from '../pages/IfcImporter/views/Step6-Export/locales'
import step5Arch from '../pages/IfcImporter/views/step5-Arch/locales'
import main from '../pages/Main/locales'
import { apiRoutes } from './apiRoutes'

export const config = {
  apiURL: process.env.REACT_APP_API_URL + '/api-v2',
  modugenStatikFrontendURL: process.env.REACT_APP_MODUGEN_STATIK_FRONTEND_URL,
  helpURL: process.env.REACT_APP_HELP_URL,
  modugenRegisterURL: process.env.REACT_APP_MODUGEN_REGISTER_URL,

  environment: process.env.REACT_APP_ENVIRONMENT,

  loginDisabled: process.env.REACT_APP_LOGIN_DISABLED
    ? process.env.REACT_APP_LOGIN_DISABLED === 'TRUE'
    : false,

  auth0Domain: process.env.REACT_APP_AUTH_0_DOMAIN,
  auth0ClientID: process.env.REACT_APP_AUTH_0_CLIENT_ID,
  auth0Audience: process.env.REACT_APP_AUTH_0_AUDIENCE,

  sentryDSN: process.env.REACT_APP_SENTRY_DSN,

  apiRoutes,

  i18n: {
    languages: ['de', 'en'] as const,
    ressources: {
      de: {
        common: common.de,
        global: global.de,

        main: main.de,
        authentication: authentication.de,
        ifcImporter: ifcImporter.de,

        step0Hydrate: step0Hydrate.de,
        step1Upload: step1Upload.de,
        step2Assignments: step2Assignments.de,
        step3Filter: step3Filter.de,
        step4Confirm: step4Confirm.de,
        step5Arch: step5Arch.de,
        step6Export: step6Export.de,
      },

      en: {
        common: common.en,
        global: global.en,

        main: main.en,
        authentication: authentication.en,
        ifcImporter: ifcImporter.en,

        step0Hydrate: step0Hydrate.en,
        step1Upload: step1Upload.en,
        step2Assignments: step2Assignments.en,
        step3Filter: step3Filter.en,
        step4Confirm: step4Confirm.en,
        step5Arch: step5Arch.en,
        step6Export: step6Export.en,
      },
    },
  },

  // this is used for development purposes to know which version is deployed
  rev: process.env.REACT_APP_REVISION,
}
