import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import { Polygon } from 'src/pages/IfcImporter/types'

export function polygonToVectors(
  polygon: Polygon,
): [ImmutableVector3, ImmutableVector3, ImmutableVector3, ImmutableVector3] {
  return polygon.points.map(p => new ImmutableVector3(p.x, p.y, p.z)) as [
    ImmutableVector3,
    ImmutableVector3,
    ImmutableVector3,
    ImmutableVector3,
  ]
}
