import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Stack, Typography } from '@mui/material'

interface Props {
  resetError: () => void
}

function ErrorFallBackUI({ resetError }: Props): ReactElement {
  const { t } = useTranslation('global')

  return (
    <Stack spacing={2} padding={4}>
      <Typography variant="h3">{t('errors.errorBoundryTitle')}</Typography>
      <Typography variant="h5">{t('errors.errorBoundryMessage')}</Typography>
      <Button variant="outlined" color="primary" size="small" onClick={resetError}>
        {t('errors.resetErrorBoundry')}
      </Button>
    </Stack>
  )
}

export default ErrorFallBackUI
