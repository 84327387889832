import de from './de'

const en: typeof de = {
  // GENERAL TERMS

  terms: {
    draw: 'Draw',
    align: 'Align',
    storey: 'Storey',

    storeyHeight: 'Storey height',
    buildingHeight: 'Building height',
  },

  actions: {
    drawElement: 'Draw element',
    jumpToElement: 'Jump to element',
  },

  labels: {
    determineStoreyHeights: 'Determine the final floor heights',
    elementData: 'Element data',
    adaptModel: 'Adapt model',
  },

  prompts: {
    goBack: 'If you go back and change assignments and filtering the model will be recalculated',
  },

  // COMPONENT GROUPS

  export: {
    exportToStatik: 'Export to statik',
    downloadJson: 'Download JSON',

    modelExportHint:
      'As soon as the model has reached the desired state, you are able to download it for ModuGen Statik',
  },

  modelControls: {
    imported: 'Imported',
    original: 'Original',
    modelCurrent: 'Current Model',
  },

  tabs: {
    alignment: 'Alignment',
    edit: 'Edit',
    placement: 'Platzierung',
  },

  editModelTab: {
    chooseStorey: 'Choose a storey to switch to editing mode',
    chooseSingleStorey: 'Choose storey',
    showGltfFloorplan: 'Show floorplan gltf',
    leaveEditMode: 'Leave edit mode',
    remove: 'Remove',
    draw: 'Draw',
    change: 'Change',
    moveWall: 'Move wall',
    drawOpening: 'Draw opening',
    drawHint: 'To draw elements you need to execute Wall alignment to determine the storey heights',
    clickWall: 'Click an existing wall',
    drawOpeningByDragNDrop: 'Draw an opening per drag&drop',
    saveDrawnOpening: 'Save opening',
    alignDrawing: 'Align drawing on elements',
    alignDrawingOnAngles: 'Align drawing on angles',
    start: 'Start',
    end: 'End',
    addNewWall: 'Add new wall',
    drawNewInnerWall: 'Draw new inner wall',
    innerWall: 'Inner wall',
    outerWall: 'Outer wall',

    wallType: 'Wall type',
    wall: 'Wall',
    changeWallPlacement: 'Change wall placement',
  },

  errors: {
    schnittbombeCalculation: 'Error while executing wall alignment',
    modugenStatikTransition: 'Error while transitioning to Modugen Statik',
    addWall: 'Error while adding the new wall',
    removeWall: 'Error while removing the wall',
    wallsOverlapping: 'Walls are not allowed to overlap',
    openingsOverlapping: 'Openings should not overlap',
    openingsExceedingWall: 'Openings should not exceed the wall borders',
    changeWall: 'Error changing the wall',
    addOpening: 'Error adding an opening to the wall',
    removeOpening: 'Error removing an opening of the wall',
    changePlacement: 'Error changing wall placement',
    removeStorey: 'Error removing storey',
    rotate90: 'Error rotating model',
    removeElement: 'Error while removing the element',
  },

  successMessages: {
    changePlacement: 'wall placement changed successfully',
  },

  // STATUS MESSAGES

  statusMessages: {
    noDataForElement: 'No data available for this element',
  },
}

export default en
