export default {
  hotkeys: {
    availableHotkeys: 'Verfügbare Shortcuts',
    description: 'Beschreibung',
    hotkey: 'Shortcut',
    hotkeys: 'Shortcuts',
    toggleHotkeysWindow: 'Shortcuts öffnen/schließen',
  },
  support: {
    toggleSupport: 'Chat-Sichtbarkeit umschalten',
  },

  errors: {
    errorBoundryTitle: 'Sie haben einen Fehler gefunden',
    errorBoundryMessage:
      'Es tut uns leid, dass auf unserer Seite ein Problem aufgetreten ist. Wir beheben es',
    resetErrorBoundry: 'zurücksetzen',
  },
}
