import produce from 'immer'
import cloneDeep from 'lodash-es/cloneDeep'
import create from 'zustand'
import { combine } from 'zustand/middleware'

import { roofStoreyKey } from 'src/config/misc'

import { PlanarModel, StoreyAssignment } from '../../types'

interface GeneratedModelStoreState {
  currentModelPlanar: PlanarModel | null
  currentModelTranslucent: boolean

  currentModelStoreyByGuid: Record<string, string>
  availableStoreys: Set<string>
  visibleStoreys: Set<string>

  selectedIds: Set<string>

  outlinesColor: 'dark' | 'light'
}

const initialState: GeneratedModelStoreState = {
  currentModelPlanar: null,
  currentModelTranslucent: false,

  currentModelStoreyByGuid: {},
  availableStoreys: new Set(),
  visibleStoreys: new Set(),

  selectedIds: new Set(),

  outlinesColor: 'light',
}

export const useGeneratedModelStore = create(
  combine(cloneDeep(initialState), set => ({
    clear: () => set(cloneDeep(initialState)),

    // CURRENT MODEL RELATED

    setCurrentModelPlanar: (currentModelPlanar: PlanarModel | null) =>
      set({
        currentModelPlanar,
      }),

    setCurrentModelTranslucent: (currentModelTranslucent: boolean) =>
      set({ currentModelTranslucent }),

    // CURRENT MODEL STOREYS RELATED

    setStoreyAssignment: (storeyAssignment: StoreyAssignment) => {
      // elements belonging to the roof will never get assigned to a storey by
      // the backend, the roofStoreyKey is therefore a frontend-only construct
      const availableStoreys = new Set([roofStoreyKey])
      const currentModelStoreyByGuid: Record<string, string> = {}

      Object.entries(storeyAssignment.wall_storey_id_assignment).forEach(([storey, guids]) => {
        if (!availableStoreys.has(storey)) availableStoreys.add(storey)

        guids.forEach(guid => {
          currentModelStoreyByGuid[guid] = storey
        })
      })

      set({
        currentModelStoreyByGuid,
        availableStoreys,
        visibleStoreys: new Set(availableStoreys),
      })
    },

    setVisibleStoreys: (visibleStoreys: Set<string>) => set({ visibleStoreys }),

    toggleAllStoreysVisibility: () => {
      set(state => ({
        visibleStoreys: new Set(
          state.availableStoreys.size === state.visibleStoreys.size ? [] : state.availableStoreys,
        ),
      }))
    },

    toggleSingleStoreyVisibility: (storey: string) => {
      set(
        produce(state => {
          state.visibleStoreys.has(storey)
            ? state.visibleStoreys.delete(storey)
            : state.visibleStoreys.add(storey)
        }),
      )
    },

    selectElement: (element: string) =>
      set(
        produce(state => {
          state.selectedIds.add(element)
        }),
      ),

    deselectElements: () => set({ selectedIds: new Set() }),

    setOutlinesColor: (outlinesColor: 'dark' | 'light') => set({ outlinesColor }),
  })),
)
