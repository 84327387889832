export default {
  actions: {
    cleanseModel: 'Modell bereinigen',
    removeElements: 'Elemente entfernen',
  },

  labels: {
    existingFilters: 'Bestehende Filter',
    filter: 'Filter',
    removeFilter: 'Filter entfernen',
    filteredElements: 'Gefilterte Elemente',
  },

  hotkeys: {
    deleteLastFilter: 'Letzten Filter löschen',
    filterSelectedElements: 'Alle ausgewählten Elemente filtern',
  },

  errors: {
    saveFilters: 'Fehler beim Speichern der Filter',
  },
}
