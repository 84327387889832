import { cloneDeep } from 'lodash-es'
import create from 'zustand'
import { combine } from 'zustand/middleware'

import { Project } from 'src/types'

interface ProjectStoreState {
  project?: Project
}

const initialState: ProjectStoreState = {
  project: undefined,
}

export const useProjectStore = create(
  combine(cloneDeep(initialState), set => ({
    clear: () => set(cloneDeep(initialState)),

    setProject: (project: Project) =>
      set({
        project,
      }),
  })),
)
