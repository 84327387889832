export default {
  actions: {
    downloadJson: 'JSON herunterladen',
  },

  labels: {
    exportToStatik: 'Export nach Statik',
  },

  hints: {
    modelExport:
      'Sobald das Modell den gewünschten Zustand erreicht hat, kannst du es hier für die ModuGen Statik herunterladen',
  },
}
