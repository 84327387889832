import { uniqueId } from 'lodash-es'

import { useEffect } from 'react'

import { ThreeEvent } from '@react-three/fiber'

export const onClickCurrentModelListeners: Record<string, (event: ThreeEvent<MouseEvent>) => void> =
  {}

export function useOnClickCurrentModel(
  callback: (event: ThreeEvent<MouseEvent>) => void,
  dependencies: unknown[] = [],
): void {
  useEffect(() => {
    const uuid = uniqueId()
    onClickCurrentModelListeners[uuid] = callback

    return () => {
      delete onClickCurrentModelListeners[uuid]
    }
  }, dependencies)
}
