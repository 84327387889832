import { MeshBasicMaterialParameters, DoubleSide } from 'three'

import React, { ReactElement, useCallback } from 'react'

import { BasicMeshProps, BasicMesh } from '@modugen/scene/lib/components/BasicMesh'
import { config } from '@modugen/scene/lib/config'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'

import { useDerivedIfcDataStore } from 'src/pages/IfcImporter/stores/derivedIfcDataStore'
import { useHiddenElementsStore } from 'src/pages/IfcImporter/stores/hiddenElementsIdsStore'
import { useIfcElementsStore } from 'src/pages/IfcImporter/stores/ifcElementsStore'
import { useIntermediateSelectedElementsStore } from 'src/pages/IfcImporter/stores/intermediateSelectedElementsStore'
import sceneColors from 'src/styles/sceneColors'

import { useGltfModelStore } from '../gltfModelStore'
import { onClickGltfModelListeners } from '../useOnClickGltfModel'

/*
  due to the recursive nature of the IfcModel creation and the fact that we need
  to constrain re-renders as much as possible, we need to put external logic inside
  the presentational component

  important notes:

  * its essential to use scoped state-picking wherever possible (for example for
    isSelected, changes to the selectedIds Set would force the whole gltf model
    to re-render otherwise)

  * we follow the recommendation to memoize selectors to be as performant as
    possible: https://github.com/pmndrs/zustand#memoizing-selectors
*/
export default function GltfMesh({
  material,
  children,
  ...meshProps
}: BasicMeshProps): ReactElement | null {
  const ifcId = meshProps.name

  // id of parent element if available
  const groupIfcId = useDerivedIfcDataStore(
    useCallback(state => ifcId && state.groupIdByChildId[ifcId], [ifcId]),
  )

  const relatedIfcElement = useDerivedIfcDataStore(
    useCallback(state => !!ifcId && state.searchableIfcElements[ifcId]?.ifcElement, [ifcId]),
  )

  const relatedIfcGroup = useDerivedIfcDataStore(
    useCallback(
      state => !!groupIfcId && state.searchableIfcElements[groupIfcId]?.ifcElement,
      [groupIfcId],
    ),
  )

  // element can get selected alone or as part of its group parent
  const isSelected = useDerivedIfcDataStore(
    useCallback(
      state =>
        (!!ifcId && state.mergedSelectedIds.has(ifcId)) ||
        (!!groupIfcId && state.mergedSelectedIds.has(groupIfcId)),
      [ifcId, groupIfcId],
    ),
  )

  const isIntermediateSelected = useIntermediateSelectedElementsStore(
    useCallback(
      state =>
        (!!groupIfcId && state.selectedGroupElementIds.has(groupIfcId)) ||
        (!!ifcId && state.selectedStandAloneElementIds.has(ifcId)),
      [ifcId, groupIfcId],
    ),
  )

  // element can get hidden alone or as part of its group parent
  const isHiddenByIfcStore = useIfcElementsStore(
    useCallback(
      state =>
        (!!ifcId && state.hiddenIfcIdsGltfModel.has(ifcId)) ||
        (!!groupIfcId && state.hiddenIfcIdsGltfModel.has(groupIfcId)),
      [ifcId, groupIfcId],
    ),
  )

  // element can get hidden alone or as part of its group parent
  const isHiddenByElementsStore = useHiddenElementsStore(
    useCallback(
      state =>
        (!!ifcId && state.hiddenElementIds.has(ifcId)) ||
        (!!groupIfcId && state.hiddenElementIds.has(groupIfcId)),
      [ifcId, groupIfcId],
    ),
  )

  const defaultColor =
    (material as MeshBasicMaterialParameters).color || sceneColors.elements3d.walls
  const newColor =
    (relatedIfcElement && sceneColors.elements3d[relatedIfcElement.PSET_data.MGroup]) ||
    (relatedIfcGroup && sceneColors.elements3d[relatedIfcGroup.PSET_data.MGroup]) ||
    defaultColor

  const isTranslucent = useGltfModelStore(state => state.gltfModelTranslucent)
  const isNonSelectable = useGltfModelStore(state => state.gltfModelNonSelectable)

  const isTapelineActive = useTapelineStore(state => state.isActive)

  return (
    // @ts-ignore
    <BasicMesh
      {...meshProps}
      cursor="pointer"
      visible={!isHiddenByIfcStore && !isHiddenByElementsStore}
      onClick={event =>
        Object.values(onClickGltfModelListeners).forEach(callback => callback(event))
      }
      noPointerInteractions={isTapelineActive}
      layers={isNonSelectable ? config.R3FNonSelectableObjectLayer : 0}
    >
      <meshStandardMaterial
        color={isSelected || isIntermediateSelected ? sceneColors.selection : newColor}
        transparent={isTranslucent}
        opacity={isTranslucent ? 0.33 : 1}
        // polygonOffset is used to prevent rendering artifacts when gltfModel
        // elements are rendered in the same place as parsed model elements
        polygonOffset
        polygonOffsetUnits={100}
        // required as some faces of some models are (most likely due to ifc
        // export issues) only visible from one side
        side={DoubleSide}
      />
      {children}
    </BasicMesh>
  )
}
