import { Vector3 } from 'three'

import { getFlatShapeNormal, toImmutable } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import { Point } from 'src/pages/IfcImporter/types'

export function generateEdgesFromPoints(points: Vector3[]): Vector3[][] {
  return points.map((start, i) => {
    const end: Point = i + 1 === points.length ? points[0] : points[i + 1]

    return [new Vector3(start.x, start.y, start.z), new Vector3(end.x, end.y, end.z)]
  })
}

export function generateShapeEdgesFromPoints(points: Vector3[], thickness?: number): Vector3[][] {
  const edges = generateEdgesFromPoints(points)
  if (!thickness || thickness === 0) return edges

  const normal = getFlatShapeNormal(
    points.map(p => toImmutable(p)) as [
      ImmutableVector3,
      ImmutableVector3,
      ImmutableVector3,
      ImmutableVector3,
      ...ImmutableVector3[],
    ],
  )

  const thicknessEdges = points.map(p => [p, p.clone().addScaledVector(normal.v, thickness)])
  const movedEdges = edges.map(edge =>
    edge.map(p => p.clone().addScaledVector(normal.v, thickness)),
  )

  return [...edges, ...thicknessEdges, ...movedEdges]
}
