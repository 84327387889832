import { object, string } from 'yup'

import {
  WalllineOverlappingSchema,
  wallLineOverlappingSchema,
} from '../../wallLineOverlappingSchema'

export interface ChangeWallLengthSchema extends WalllineOverlappingSchema {
  guid: string
}

export const schema = object({
  guid: string().required(),
  ...wallLineOverlappingSchema,
})
