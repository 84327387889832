import { Matrix4, Vector3 } from 'three'

import { CoordinateSystem } from 'src/pages/IfcImporter/types'

export function getCoordinateSystemMatrix(coordinateSystem: CoordinateSystem): Matrix4 {
  const xVector = new Vector3(
    coordinateSystem.x_direction.x,
    coordinateSystem.x_direction.y,
    coordinateSystem.x_direction.z,
  )

  const yVector = new Vector3(
    coordinateSystem.y_direction.x,
    coordinateSystem.y_direction.y,
    coordinateSystem.y_direction.z,
  )

  const zVector = new Vector3(
    coordinateSystem.z_direction.x,
    coordinateSystem.z_direction.y,
    coordinateSystem.z_direction.z,
  )

  const matrix = new Matrix4().makeBasis(xVector, yVector, zVector)

  matrix.setPosition(
    new Vector3(coordinateSystem.origin.x, coordinateSystem.origin.y, coordinateSystem.origin.z),
  )

  return matrix
}
