import produce from 'immer'
import create from 'zustand'
import { combine } from 'zustand/middleware'

import { IfcElementsFilter } from '../types'

interface IfcFiltersStoreState {
  filters: IfcElementsFilter[]
}

export const useFiltersStore = create(
  combine({ filters: [] } as IfcFiltersStoreState, set => ({
    clear: () => set({ filters: [] }),

    setFilters: (filters: IfcElementsFilter[]) => set({ filters }),

    addFilter: (filter: IfcElementsFilter) =>
      set(
        produce((state: IfcFiltersStoreState) => {
          state.filters.push(filter)
        }),
      ),

    removeFilter: (indexToRemove: number) =>
      set(
        produce((state: IfcFiltersStoreState) => {
          state.filters = (state.filters as IfcElementsFilter[]).filter(
            (_, index) => index !== indexToRemove,
          )
        }),
      ),
  })),
)
