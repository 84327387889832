import { cloneDeep } from 'lodash-es'
import create from 'zustand'
import { combine } from 'zustand/middleware'

interface IntermediateSelectedElementsStoreState {
  selectedStandAloneElementIds: Set<string>
  selectedGroupElementIds: Set<string>
}

const initialState: IntermediateSelectedElementsStoreState = {
  selectedStandAloneElementIds: new Set(),
  selectedGroupElementIds: new Set(),
}

export const useIntermediateSelectedElementsStore = create(
  combine(cloneDeep(initialState), set => ({
    clear: () => set(cloneDeep(initialState)),

    setSelectedStandAloneElementIds: (selectedStandAloneElementIds: Set<string>) =>
      set({ selectedStandAloneElementIds }),

    setSelectedGroupElementIds: (selectedGroupElementIds: Set<string>) =>
      set({ selectedGroupElementIds }),
  })),
)
