import React, { ReactNode, ReactElement } from 'react'

import { BoxProps } from '@mui/material'

import { MuiBox } from './MuiBox'

export function TabPanel({
  children,
  value,
  index,
  ...rest
}: {
  children: ReactNode | string
  index: number
  value: number
} & BoxProps): ReactElement {
  return (
    <MuiBox hidden={value !== index} {...rest}>
      {children}
    </MuiBox>
  )
}
