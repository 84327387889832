export default {
  hints: {
    confirmExport: 'Modell für Import bestätigen',

    // All hints for the checkbox the user has to check
    removedGroundPlates: 'Bodenplatte entfernt',
    removedFrontWalls: 'Vorsatzwände entfernt',
    removedAtticasAndBalustrades: 'Attikas und Brüstungen entfernt',
    appliedRoofHack: 'Dach-hack angewandt',
  },

  errors: {
    transformationsApplication: 'Fehler beim Anwenden der Transformationen',
  },
}
