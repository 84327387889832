import { Group, Matrix4 } from 'three'

import React, { ReactElement, useMemo, useRef } from 'react'

import { getCoordinateSystemMatrix } from 'src/utils/getCoordinateSystemMatrix'

import { useDerivedIfcDataStore } from '../../stores/derivedIfcDataStore'
import { useGltfModelStore } from '../GltfModelController/gltfModelStore'
import Opening from './Opening'
import { useOpeningsStore } from './openingsStore'

export const OpeningsController = React.memo(function OpeningsController(): ReactElement | null {
  const groupRef = useRef<Group>(null)

  const openingMeshes = useOpeningsStore(state => state.openingMeshes)
  const showOpenings = useOpeningsStore(state => state.showOpenings)
  const coordinateSystem = useGltfModelStore(state => state.coordinateSystem)

  const mergedFilteredIds = useDerivedIfcDataStore(state => state.mergedFilteredIds)
  const mergedSelectedIds = useDerivedIfcDataStore(state => state.mergedSelectedIds)

  const matrix = useMemo(
    () => (coordinateSystem ? getCoordinateSystemMatrix(coordinateSystem) : new Matrix4()),
    [coordinateSystem],
  )

  const filteredOpenings = openingMeshes.filter(
    opening =>
      mergedFilteredIds.has(opening.element_guid) || mergedSelectedIds.has(opening.element_guid),
  )

  return showOpenings ? (
    <group ref={groupRef} matrixAutoUpdate={false} matrix={matrix}>
      {filteredOpenings.map(opening => (
        <Opening
          key={opening.element_guid}
          opening={opening}
          // as we already filter the openingMeshes by mergedFilteredIds or
          // mergedSelectedIds, all openings that are not within
          // mergedFilteredIds are selected
          state={mergedFilteredIds.has(opening.element_guid) ? 'removed' : 'selected'}
        />
      ))}
    </group>
  ) : null
})
