import produce from 'immer'
import { cloneDeep } from 'lodash-es'
import create from 'zustand'
import { combine } from 'zustand/middleware'

import { directIfcExports } from 'src/config/exportConfig'

import { ElementTypes, IfcIdsByType, IfcGroups, ModelType, PlanarModel } from '../types'

export type VisibilityByType = Record<ElementTypes, boolean>

interface TypeVisibilityStoreState {
  visibilityByType: VisibilityByType
  activeView?: string
}

function getDefaultTypeVisibility() {
  // whitelisted types become true, non-whitelisted groups become undefined
  return directIfcExports.reduce(
    (collector, type) => ({ ...collector, [type]: true }),
    {} as VisibilityByType,
  )
}

const initialState: TypeVisibilityStoreState = {
  visibilityByType: getDefaultTypeVisibility(),
}

function createTypeVisibilityStore() {
  return create(
    combine(cloneDeep(initialState), set => ({
      setTypeVisibility: (type: ElementTypes, visibility: boolean) =>
        set(
          produce((state: TypeVisibilityStoreState) => {
            state.visibilityByType[type] = visibility
          }),
        ),

      setVisibilityForAllTypes: (visibility: boolean) => {
        set(
          produce((state: TypeVisibilityStoreState) => {
            for (const key in state.visibilityByType) {
              state.visibilityByType[key as ElementTypes] = visibility
            }
          }),
        )
      },

      clear: () => set(cloneDeep(initialState)),
    })),
  )
}

export const typeVisibilityStoreInstances: {
  [instanceId: string]: ReturnType<typeof createTypeVisibilityStore>
} = {}

export function useTypeVisibilityStoreByInstance(
  model: ModelType,
  instanceId: string,
): ReturnType<typeof createTypeVisibilityStore> {
  if (!typeVisibilityStoreInstances[model + instanceId]) {
    typeVisibilityStoreInstances[model + instanceId] = createTypeVisibilityStore()
  }

  return typeVisibilityStoreInstances[model + instanceId]
}

export function getHiddenIdsFromTypes(
  ifcIdsByType: IfcIdsByType[],
  visibilityByType: VisibilityByType,
  trueIfcGroups: IfcGroups,
): string[] {
  const hiddenStandaloneIds = ifcIdsByType.reduce((collector, idsByType) => {
    if (!visibilityByType[idsByType.type]) {
      collector = [...collector, ...idsByType.standAloneIds]
    }

    return collector
  }, [] as string[])

  const hiddenGroupIds = ifcIdsByType.reduce((collector, idsByType) => {
    if (!visibilityByType[idsByType.type]) {
      collector = [...collector, ...idsByType.groupIds]
    }

    return collector
  }, [] as string[])

  const hiddenGroupAndChildIds = hiddenGroupIds.reduce((collector, groupId) => {
    return [...collector, groupId, ...trueIfcGroups[groupId]]
  }, [] as string[])

  return [...hiddenStandaloneIds, ...hiddenGroupAndChildIds]
}

export function getHiddenIdsFromPlanarModel(
  visibilityByType: VisibilityByType,
  parsingResult: PlanarModel,
): string[] {
  const hiddenIds: string[] = []
  if (!visibilityByType.roof_slabs) {
    hiddenIds.push(
      ...parsingResult.roof_slabs.reduce(
        (collector, roofSlab) => [...collector, roofSlab.guid],
        [] as string[],
      ),
    )
  }
  if (!visibilityByType.slabs) {
    hiddenIds.push(
      ...parsingResult.slabs.reduce((collector, slab) => [...collector, slab.guid], [] as string[]),
    )
  }
  if (!visibilityByType.walls) {
    hiddenIds.push(
      ...parsingResult.walls.reduce((collector, wall) => [...collector, wall.guid], [] as string[]),
    )
  }

  return hiddenIds
}
