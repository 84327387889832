export default {
  actions: {
    signIn: 'Einloggen',
    createAccount: 'Account erstellen',
  },

  hints: {
    accessRequired: 'Du benötigst eine Berechtigung für den Zugang',
    reopenSignInPopup: 'Das Fenster zum Einloggen erneut öffnen',
  },

  errors: {
    userAuthentication: 'Fehler beim Einloggen des Nutzers',
  },

  terms: {
    ifc: 'IFC',
    semanticModel: 'Semantisches Modell',
    theSimpleWayToImportIFCFiles: 'Der einfach Weg, IFC-Dateien zu importieren',
    static: 'Statik',
    ifcNote: 'Im ersten Schritt lesen wir 3D Entwurfspläne ein und verarbeiten diese automatisch.',
    semanticModelNote:
      'Durch intelligente Analysen erstellen wir ein vollständiges semantisches Gebäudemodell.',
    staticNote:
      'Daraus leiten wir gemeinsam mit dem Statiker eine vollständige, prüfbare Statik für Holzgebäude ab.',
  },
}
