import React, { useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Straighten, Preview, DeleteForever } from '@mui/icons-material/'
import { Stack, Tooltip } from '@mui/material'

import { useMarqueeSelectionStore } from '@modugen/scene/lib/controllers/MarqueeSelectionController/marqueeSelectionStore'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'

import {
  HeaderToggleButtonGroup,
  HeaderToggleButton,
} from 'src/components/generic/HeaderToggleButtonGroup'
import HotKeyHeaderToggleButton from 'src/components/generic/HotKeyHeaderToggleButton'

import { useEditModelStore } from '../../stores/editModelStore'

// TODO: consider integrating an event bus that allows components to react to
// states without importing their stores / other dependencies
export function TapelineControls(): ReactElement {
  const { t } = useTranslation('ifcImporter')

  // no state picking as we use almost all store methods
  const tapelineStore = useTapelineStore()

  const isMarqueeSelectionActive = useMarqueeSelectionStore(state => state.isActive)

  // set to xy mode as soon as a storey was selected and edit mode is not opening
  const activeStorey = useEditModelStore(state => state.activeStorey)
  const editMode = useEditModelStore(state => state.editMode)
  useEffect(
    () => tapelineStore.setXyOnly(!!activeStorey && editMode !== 'opening'),
    [activeStorey, editMode],
  )

  return (
    <Stack direction="row" spacing={1}>
      {/* we need to disable this button while marquee selection
      is active as this will also lock the camera  */}
      <HeaderToggleButtonGroup size="small" disabled={isMarqueeSelectionActive}>
        <Tooltip title={t('tapeline.drawTapeline')}>
          <HotKeyHeaderToggleButton
            value="active"
            size="small"
            onClick={() => {
              tapelineStore.setIsActive(!tapelineStore.isActive)
              tapelineStore.isVisible && tapelineStore.setIsVisible(false)
            }}
            selected={tapelineStore.isActive}
            hotkeys="m"
            hotkeysDeps={[tapelineStore.isActive, tapelineStore.isVisible]}
            hotkeyDescription={t('tapeline.toggleTapelineTool')}
            onHotkey={() => {
              tapelineStore.setIsActive(!tapelineStore.isActive)
              tapelineStore.isVisible && tapelineStore.setIsVisible(false)
            }}
          >
            <Straighten fontSize="small" />
          </HotKeyHeaderToggleButton>
        </Tooltip>

        <Tooltip title={t('tapeline.showTapeline')}>
          <HeaderToggleButton
            value="visible"
            size="small"
            onClick={() => {
              tapelineStore.setIsVisible(!tapelineStore.isVisible)
              tapelineStore.isActive && tapelineStore.setIsActive(false)
            }}
            selected={tapelineStore.isVisible}
          >
            <Preview fontSize="small" />
          </HeaderToggleButton>
        </Tooltip>
      </HeaderToggleButtonGroup>

      {tapelineStore.isActive && (
        <>
          <Tooltip title={t('tapeline.removeTapelines')}>
            <HeaderToggleButton
              value="delete"
              size="small"
              onClick={() => tapelineStore.removeAllLines()}
            >
              <DeleteForever fontSize="small" />
            </HeaderToggleButton>
          </Tooltip>
        </>
      )}
    </Stack>
  )
}
