export default {
  actions: {
    uploadIfc: 'Bitte lade hier deine IFC-Datei hoch',
    dropIfcFile: 'Ziehe einfach eine IFC-Datei in diese Fläche oder klicke hier',
    uploadIfcFile: 'IFC-Datei jetzt hochladen',
  },

  hints: {
    filterModel:
      'Das links dargestellte Modell kann in den nächsten Schritten gefiltert und für die weitere Verarbeitung vorbereitet werden.',
    changeModel:
      'Du kannst das gewählte Modell noch ändern. Nach Bestätigung ist eine Änderung des Modells innerhalb dieses Projekts nicht mehr möglich.',
  },

  errors: {
    uploadAndConvertIfc: 'Fehler beim Hochladen des IFC Modells',
    forbiddenCharactersInFilename: 'Der Dateiname enthält nicht erlaubte Zeichen (z.B. ö)',
  },
}
