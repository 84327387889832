import { filter } from 'lodash-es'
import { Group, Vector3, Object3D, BufferGeometry, Line as ThreeLine } from 'three'

import React, { useRef, useEffect, useMemo, ReactElement, ReactNode } from 'react'

import { HighlightableLine } from '@modugen/scene/lib/components/Lines/HighlightableLine'
import { config as sceneConfig } from '@modugen/scene/lib/config'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { Line } from '@react-three/drei'

import sceneColors from 'src/styles/sceneColors'

import { usePlanarWallsInStorey } from '../../hooks/usePlanarWallsInStorey'
import { useEditModelStore } from '../../stores/editModelStore'
import { projectOpening } from '../../utils/projectOpening'
import { projectPlanarWall } from '../../utils/projectPlanarWall'

interface Props {
  highlightedWalls?: string[]
  excludeGuids?: string[]
  onClickWall?: (wallId: string) => void
  onClickScene?: () => void
  wallsSelectable?: boolean
  children?: ReactNode
}

export function FloorPlanController({
  highlightedWalls = [],
  excludeGuids = [],
  wallsSelectable = false,
  onClickWall,
  onClickScene,
  children,
}: Props): ReactElement | null {
  const wallsGroupRef = useRef<Group>(null)

  const planarWallsInStorey = usePlanarWallsInStorey()

  const cameraRotationTarget = useCameraStore(state => state.rotationTarget)

  const setTapelineSnapTargets = useTapelineStore(state => state.setAdditionalSnapTargets)

  const activeStorey = useEditModelStore(state => state.activeStorey)

  const setWallDrawingSnapTargets = useEditModelStore(state => state.setAdditionalSnapTargets)

  // STOREY GEOMETRY RELATED

  const [projectedWalls, projectedOpenings] = useMemo(() => {
    if (!activeStorey) return [[], []]

    const visibleWalls = filter(planarWallsInStorey, wall => !excludeGuids.includes(wall.guid))

    const projectedWalls = visibleWalls.map(wall => projectPlanarWall(wall))

    const projectedOpenings = visibleWalls.reduce((collector, wall) => {
      collector.push(...wall.openings.map(opening => projectOpening(opening)))

      return collector
    }, [] as { guid: string; points: ImmutableVector3[]; pointsV: Vector3[] }[])

    return [projectedWalls, projectedOpenings]
  }, [planarWallsInStorey, activeStorey, excludeGuids])

  // EFFECT HOOKS

  // ensure no snap targets are left on model remove
  useEffect(
    () => () => {
      setTapelineSnapTargets(undefined)
      setWallDrawingSnapTargets(undefined)
    },
    [],
  )

  useEffect(() => {
    // snap targets need to be specifically set as we need to enable snapping to
    // close elements without actually hovering them (which is not the standard
    // snap behavior) due to the nature of the orthographic projection

    const snapTargets: Object3D[] = []

    // only snap to walls, ignore openings for now
    for (const wall of projectedWalls) {
      const geometry = new BufferGeometry().setFromPoints(wall.points.map(p => p.v))
      const line = new ThreeLine(geometry)
      // used for identifying the wall when snapping to it
      line.userData.guid = wall.guid
      snapTargets.push(line)
    }

    setTapelineSnapTargets(snapTargets)
    setWallDrawingSnapTargets(snapTargets)
  }, [projectedWalls])

  // RENDER

  return (
    <>
      <group ref={wallsGroupRef}>
        {projectedWalls.map(wall => (
          <HighlightableLine
            key={wall.guid}
            line={wall.points}
            color={
              wall.placement === 'Internal'
                ? sceneColors.elements2d.internalWalls
                : sceneColors.elements2d.externalWalls
            }
            cursor={wallsSelectable ? 'pointer' : 'auto'}
            isHighlighted={highlightedWalls.includes(wall.guid)}
            hoverable={wallsSelectable}
            clickable={wallsSelectable}
            onClick={() => onClickWall?.(wall.guid)}
          />
        ))}

        {/* slight elevation of 0.1 to put openings above the walls, different
            layer as openings should not be selectable */}
        <group position={[0, 0, 0.1]} layers={sceneConfig.R3FNonSelectableObjectLayer}>
          {projectedOpenings &&
            projectedOpenings.map(opening => (
              <Line
                key={opening.guid}
                points={opening.pointsV}
                color={sceneColors.elements2d.openings}
              />
            ))}
        </group>
      </group>

      {/* invisible plane serves as a hit target for the draw controller */}
      <mesh
        position={[cameraRotationTarget.x, cameraRotationTarget.y, -0.1]}
        onPointerDown={event => event.nativeEvent.button === 0 && onClickScene?.()}
      >
        <planeGeometry args={[100, 100]} />
        <meshStandardMaterial transparent opacity={0} />
      </mesh>

      {children}
    </>
  )
}
