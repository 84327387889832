import { first, last, sortBy } from 'lodash-es'
import { Box3, Vector3 } from 'three'

import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

export function box3fromPoints(points: Vector3[] | ImmutableVector3[]) {
  const origin = points[0] instanceof Vector3 ? new Vector3() : new ImmutableVector3()
  const sorted = sortBy(points, (p: Vector3 | ImmutableVector3) =>
    // @ts-ignore
    p.distanceTo(origin),
  )
  const min = first(sorted) as Vector3
  const max = last(sorted) as Vector3
  return new Box3(min, max)
}
