import { Edit, SvgIconComponent } from '@mui/icons-material'

import i18n from 'src/localization/i18n'

import { ReactComponent as Step1ActiveIconRaw } from '../../assets/navigation_icons/step-1.svg'
import { ReactComponent as Step2ActiveIconRaw } from '../../assets/navigation_icons/step-2.svg'
import { ReactComponent as Step3ActiveIconRaw } from '../../assets/navigation_icons/step-3.svg'
import { ReactComponent as Step4ActiveIconRaw } from '../../assets/navigation_icons/step-4.svg'
import { ReactComponent as Step5ActiveIconRaw } from '../../assets/navigation_icons/step-5.svg'
import { ProjectState } from './types'
import * as step1Upload from './views/Step1-Upload'
import * as step2Assignments from './views/Step2-Assignments'
import * as step3Filter from './views/Step3-Filter'
import * as step4Confirm from './views/Step4-Confirm'
import * as step6Export from './views/Step6-Export/index'
import * as step5Arch from './views/step5-Arch/index'

interface Route {
  path: string
  // we need to use title key here and use useTranslation hook so the title will
  // be updated once the language changes
  i18nTitleKey: string
  Icon: SvgIconComponent
  isEnabled: (projectState: ProjectState) => boolean
  SidebarLeft: React.ComponentType
  Scene: React.ComponentType
  SidebarRight: React.ComponentType
}

export default [
  {
    path: 'step-1-upload',
    i18nTitleKey: 'ifcImporter:stepsNavigation.uploadModel',
    Icon: Step1ActiveIconRaw as SvgIconComponent,
    // by checking against isInitialUpload in addition to hasModel, we allow
    // users to upload multiple models in the first step without disabling the
    // route after the first upload. isInitialUpload gets set to false when
    // the user confirms the upload
    isEnabled: projectState => !projectState.hasModel || projectState.isInitialUpload,
    ...step1Upload,
  },
  {
    path: 'step-2-assignment',
    title: i18n.t('ifcImporter:stepsNavigation.componentAssignment'),
    i18nTitleKey: 'ifcImporter:stepsNavigation.componentAssignment',
    Icon: Step2ActiveIconRaw as SvgIconComponent,
    isEnabled: projectState => projectState.hasModel && !projectState.isInitialUpload,
    ...step2Assignments,
  },
  {
    path: 'step-3-filter',
    i18nTitleKey: 'ifcImporter:stepsNavigation.cleanseModel',
    Icon: Step3ActiveIconRaw as SvgIconComponent,
    isEnabled: projectState => projectState.hasModel && !projectState.isInitialUpload,
    ...step3Filter,
  },
  {
    path: 'step-4-confirm',
    i18nTitleKey: 'ifcImporter:stepsNavigation.checkModel',
    Icon: Step4ActiveIconRaw as SvgIconComponent,
    // hasFilters is not a condition as in some (rare) cases the model might not
    // require any kind of cleanup
    isEnabled: projectState => projectState.hasModel && !projectState.isInitialUpload,
    ...step4Confirm,
  },
  {
    path: 'step-5-arch',
    i18nTitleKey: 'ifcImporter:stepsNavigation.adaptModel',
    Icon: Edit,
    isEnabled: projectState =>
      projectState.hasModel && !projectState.isInitialUpload && projectState.isAligned,
    ...step5Arch,
  },
  {
    path: 'step-6-export',
    i18nTitleKey: 'ifcImporter:stepsNavigation.export',
    Icon: Step5ActiveIconRaw as SvgIconComponent,
    isEnabled: projectState =>
      projectState.hasModel && !projectState.isInitialUpload && projectState.isAligned,
    ...step6Export,
  },
] as Route[]
