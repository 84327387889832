import { useGeneratedModelStore } from '../controllers/GeneratedModelController/generatedModelStore'
import { useGltfModelStore } from '../controllers/GltfModelController/gltfModelStore'
import { getProjectState } from '../queries/hydrateProject'
import { useFiltersStore } from '../stores/filtersStore'
import { useIfcElementsStore } from '../stores/ifcElementsStore'
import { ProjectState } from '../types'

export function useProjectState(): ProjectState {
  const isInitialUpload = useGltfModelStore(state => state.isInitialUpload)
  const ifcElements = useIfcElementsStore(state => state.ifcElements)
  const filters = useFiltersStore(state => state.filters)
  const gltfModel = useGltfModelStore(state => state.gltfModel)
  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)

  return getProjectState({
    isInitialUpload,
    filters,
    ifcElements,
    model: gltfModel,
    planarModel: currentModelPlanar,
  })
}
