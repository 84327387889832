import { cloneDeep } from 'lodash-es'
import create from 'zustand'
import { combine } from 'zustand/middleware'

import { ElementFeedback } from '../types'

// TODO: make arrays to sets to improve performance
interface ErrorsStoreState {
  errors: ElementFeedback[]
  warnings: ElementFeedback[]
  exceptionModalOpen: boolean
  emphasizedIssue: ElementFeedback | null
  emphasizedIssueLocked: boolean
}

const initialState: ErrorsStoreState = {
  errors: [],
  warnings: [],
  exceptionModalOpen: false,
  emphasizedIssue: null,
  emphasizedIssueLocked: false,
}

export const useIssuesStore = create(
  combine(cloneDeep(initialState), set => ({
    clear: () => set(cloneDeep(initialState)),

    setSummary: (errors: ElementFeedback[], warnings: ElementFeedback[]) =>
      set({
        errors,
        warnings,
      }),

    setExceptionModalOpen: (open: boolean) => set({ exceptionModalOpen: open }),
    toggleExceptionModalOpen: () =>
      set(state => ({ exceptionModalOpen: !state.exceptionModalOpen })),
    closeExceptionModal: () => set({ exceptionModalOpen: false }),

    setEmphasizedIssue: (hoveredIssue: ElementFeedback) => set({ emphasizedIssue: hoveredIssue }),
    clearEmphasizedIssue: () => set({ emphasizedIssue: null }),

    toggleEmphasizedIssueLock: () =>
      set(state => ({ emphasizedIssueLocked: !state.emphasizedIssueLocked })),
  })),
)
