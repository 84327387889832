export default {
  terms: {
    draw: 'Einzeichnen',
    align: 'Ausrichten',
    storey: 'Stockwerk',
    storeyHeight: 'Stockwerkshöhe',
    buildingHeight: 'Gebäudehöhe',
  },

  actions: {
    drawElement: 'Element zeichnen',
    jumpToElement: 'Springe zum Element',
  },

  labels: {
    determineStoreyHeights: 'Bestimme die finalen Stockwerkshöhen',
    elementData: 'Elementdaten',
    adaptModel: 'Modell anpassen',
  },

  prompts: {
    goBack:
      'Falls du einen Arbeitsschritt zurückgehst und die Zuweisungen oder die Filter änderst, wird das Modell erneut berechnet.',
  },

  // COMPONENT GROUPS

  export: {
    exportToStatik: 'Export nach Statik',
    downloadJson: 'JSON herunterladen',
    modelExportHint:
      'Sobald das Modell den gewünschten Zustand erreicht hat, kannst du es hier für die ModuGen Statik herunterladen',
  },

  modelControls: {
    imported: 'Importiert',
    original: 'Original',
    modelCurrent: 'Aktuell',
  },

  tabs: {
    alignment: 'Ausrichtung',
    edit: 'Bearbeiten',
    placement: 'Platzierung',
  },

  editModelTab: {
    chooseStorey: 'Wähle ein Stockwerk um in den Bearbeitungsmodus zu wechseln',
    chooseSingleStorey: 'Stockwerk auswählen',
    showGltfFloorplan: 'Grundriss Gltf anzeigen',
    leaveEditMode: 'Bearbeitungsmodus verlassen',
    remove: 'Entfernen',
    draw: 'Einzeichnen',
    change: 'Ändern',
    moveWall: 'Wand verschieben',
    drawOpening: 'Opening einzeichnen',
    drawHint:
      'Um Elemente zeichnen zu können, musst du zuerst die Stockwerkshöhen über die Wandausrichtung bestimmen',
    clickWall: 'Klicke auf eine bestehende Wand',
    drawOpeningByDragNDrop: 'Zeichne per Drag & Drop ein Opening',
    saveDrawnOpening: 'Opening speichern',
    alignDrawing: 'Zeichnen an Elementen ausrichten',
    alignDrawingOnAngles: 'Zeichnen an Winkel ausrichten',
    start: 'Start',
    end: 'Ende',
    addNewWall: 'Neue Wand hinzufügen',
    drawNewInnerWall: 'Zeichne eine neue Innenwand',
    innerWall: 'Innenwand',
    outerWall: 'Außenwand',

    wallType: 'Wandtyp',
    wall: 'Wand',
    changeWallPlacement: 'Wandplatzierung ändern',
  },

  // ERRORS

  errors: {
    schnittbombeCalculation: 'Fehler beim Durchführen der Wandausrichtung',
    modugenStatikTransition: 'Fehler beim Übergang zu Modugen Statik',
    addWall: 'Fehler beim Hinzufügen der Wand',
    removeWall: 'Fehler beim Entfernen der Wand',
    wallsOverlapping: 'Wände dürfen nicht übereinander stehen',
    openingsOverlapping: 'Öffnungen dürfen sich nicht schneiden',
    openingsExceedingWall: 'Öffnungen dürfen nicht größer als die Wand sein',
    changeWall: 'Fehler beim Ändern der Wand',
    addOpening: 'Fehler beim Hinzufügen einer Öffnung zur Wand',
    removeOpening: 'Fehler beim Entfernen einer Öffnung der Wand',
    changePlacement: 'Fehler beim Ändern der Wandplatzierung',
    removeStorey: 'Fehler beim Löschen des Stockwerks',
    rotate90: 'Fehler beim rotieren des Modells',
    removeElement: 'Fehler beim Entfernen des Elements',
  },

  // SUCCESS MESSAGES
  successMessages: {
    changePlacement: 'Wandplatzierung geändert',
  },

  // STATUS MESSAGES

  statusMessages: {
    noDataForElement: 'Keine Daten zu diesem Element verfügbar',
  },
}
