import de from './de'

const en: typeof de = {
  actions: {
    cancel: 'Cancel',
    leave: 'Leave',
    close: 'Close',
    create: 'Create',
    save: 'Save',
    delete: 'Delete',
    confirm: 'Confirm',

    show: 'Show',

    deselectElements: 'Deselect all',
    confirmAndContinue: 'Confirm & continue',
    saveAndContinue: 'Save & Continue',
    deleteAndClose: 'Delete & Close',
  },

  terms: {
    hint: 'Hint',
    attention: 'Attention!',
    transparent: 'Transparent',
    model: 'Model',
    errors: 'Errors',
    warnings: 'Warnings',
    error: 'Error',
    warning: 'Warning',

    ifcType: 'Ifc type',
    toType: 'To type',

    ifcElementTypes: {
      walls: 'Walls',
      slabs: 'Slabs',
      openings: 'Openings',
      roofs: 'Roofs',
      roof_slabs: 'Roof slabs',
      columns: 'Columns',
      beams: 'Beams',
    },
  },

  properties: {
    chosen: 'Selected',
  },

  prompts: {
    processNotCompleted:
      'Process is not yet completed. If you leave the view the process will be aborted',
  },

  errors: {
    retriesExhausted: 'Process repeatedly failed',
    taskExecution: 'Fehler beim Ausführen der Aufgabe',
    tokenRequest: 'User token could not be received. Are you signed in?',
  },
}

export default en
