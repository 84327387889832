import { filter } from 'lodash-es'

import { useMemo } from 'react'

import { useGeneratedModelStore } from '../controllers/GeneratedModelController/generatedModelStore'
import { useEditModelStore } from '../stores/editModelStore'
import { PlanarWall } from '../types'

export function usePlanarWallsInStorey(): PlanarWall[] {
  const activeStorey = useEditModelStore(state => state.activeStorey)

  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)
  const currentModelStoreyByGuid = useGeneratedModelStore(state => state.currentModelStoreyByGuid)

  const planarWallsInStorey = useMemo(
    () =>
      filter(
        currentModelPlanar?.walls,
        wall => currentModelStoreyByGuid[wall.guid] === activeStorey,
      ),
    [currentModelPlanar, currentModelStoreyByGuid, activeStorey],
  )

  return planarWallsInStorey
}
