import React, { forwardRef, ReactElement, RefObject } from 'react'

import { ToggleButtonProps } from '@mui/material'

import useStoreHotkey from 'src/hooks/useStoreHotkey'

import { HeaderToggleButton } from './HeaderToggleButtonGroup'
import { HotKeyComponentProps } from './HotKeyButton'

const HotKeyHeaderToggleButton = forwardRef(function HotKeyHeaderToggleButton(
  {
    hotkeys,
    hotkeyDescription,
    onHotkey,
    hotkeysDeps,
    ...props
  }: HotKeyComponentProps<ToggleButtonProps>,
  ref,
): ReactElement {
  useStoreHotkey({
    keys: hotkeys,
    description: hotkeyDescription,
    callback: onHotkey,
    options: undefined,
    deps: hotkeysDeps,
  })

  return (
    <HeaderToggleButton {...props} ref={ref as RefObject<HTMLButtonElement>}>
      {props.children}
    </HeaderToggleButton>
  )
})

export default HotKeyHeaderToggleButton
