import { flatten } from 'lodash-es'
import { Vector3, Mesh, BufferGeometry } from 'three'

import { useMemo } from 'react'

import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'

import { useEditModelStore } from '../stores/editModelStore'

const useTapLineCentersSnapTargets = () => {
  const isTapelineVisible = useTapelineStore(state => state.isVisible)
  const isTapelineActive = useTapelineStore(state => state.isActive)
  const tapelines = useTapelineStore(state => state.lines)
  const snapToTapelineCenters = useEditModelStore(state => state.snapToTapelineCenters)

  const snapTargets = useMemo(
    () =>
      (isTapelineVisible || isTapelineActive) && snapToTapelineCenters
        ? flatten(
            tapelines.map(({ start, end }) => {
              const p1 = start
              const p2 = end
              const center = new Vector3().addVectors(p1.v, p2.v).multiplyScalar(0.5)
              const centerPointMesh = new Mesh(new BufferGeometry().setFromPoints([center]))
              return [centerPointMesh]
            }),
          )
        : [],
    [tapelines, snapToTapelineCenters, isTapelineVisible, isTapelineActive],
  )
  return snapTargets
}

export { useTapLineCentersSnapTargets }
