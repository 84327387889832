import React, { forwardRef, ReactElement } from 'react'

import { Box, BoxProps } from '@mui/material'

// @ts-ignore required due to a TS bug caused by the interplay of MUI and R3F
// (which seems to be an unfortunate combination). adding component="div to
// every Box might be another solution. more context:
// * https://stackoverflow.com/questions/68692230/ts-expression-produces-a-union-type-that-is-too-complex-to-represent-with-materi
// * https://stackoverflow.com/questions/69625898/typescript-box-produces-a-union-type-that-is-too-complex-to-represent
export const MuiBox = forwardRef(function MuiBox(props: BoxProps, ref): ReactElement {
  // @ts-ignore
  return <Box {...props} ref={ref} />
})
