import { without } from 'lodash-es'

import React, { ReactElement, useEffect, useState } from 'react'

import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
} from '@mui/material'

interface Props {
  onCheckItem: (allChecked: boolean) => void
  checkboxItems: string[]
  sx?: SxProps<Theme>
}

export default function Checklist({ onCheckItem, checkboxItems, sx }: Props): ReactElement {
  const [checkedItemIndexes, setCheckedItemIndexes] = useState<number[]>([])

  const handleToggleCheckItem = (itemIndex: number) => {
    if (checkedItemIndexes.includes(itemIndex)) {
      setCheckedItemIndexes(without(checkedItemIndexes, itemIndex))
    } else {
      setCheckedItemIndexes([...checkedItemIndexes, itemIndex])
    }
  }

  useEffect(() => {
    onCheckItem(checkedItemIndexes.length === checkboxItems.length)
  }, [checkedItemIndexes])

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper', ...sx }}>
      {checkboxItems.map((checkItem, itemIndex) => {
        const labelId = `checkbox-list-label-${checkItem}`

        return (
          <ListItem key={checkItem} disablePadding>
            <ListItemButton role={undefined} onClick={() => handleToggleCheckItem(itemIndex)} dense>
              <ListItemIcon>
                <Checkbox checked={checkedItemIndexes.includes(itemIndex)} disableRipple />
              </ListItemIcon>
              <ListItemText id={labelId} primary={checkItem} />
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}
