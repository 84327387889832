import { cloneDeep } from 'lodash-es'
import create from 'zustand'
import { combine } from 'zustand/middleware'

interface ArchViewStoreState {
  lockInterface: boolean
}

const initialState: ArchViewStoreState = {
  lockInterface: false,
}

export const useArchViewStore = create(
  combine(cloneDeep(initialState), set => ({
    setLockInterface: (lockInterface: boolean) => set({ lockInterface }),

    clear: () => set(cloneDeep(initialState)),
  })),
)
