import dayjs from 'dayjs'
import { isUndefined } from 'lodash-es'
import { useSnackbar } from 'notistack'

import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from 'react-query'
import { Link } from 'react-router-dom'

import { Folder, Delete } from '@mui/icons-material'
import {
  Stack,
  Typography,
  Button,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  CircularProgress,
} from '@mui/material'

import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'

import AlertDialog from 'src/components/generic/feedback/AlertDialog'
import { config } from 'src/config/config'
import { Project } from 'src/types'
import { deleteRequest, getRequest } from 'src/utils/requests'

import { useGeneratedModelStore } from '../IfcImporter/controllers/GeneratedModelController/generatedModelStore'
import { useGltfModelStore } from '../IfcImporter/controllers/GltfModelController/gltfModelStore'
import { useOpeningsStore } from '../IfcImporter/controllers/OpeningsControllers/openingsStore'
import { useAssignmentsStore } from '../IfcImporter/stores/assignmentsStore'
import { useEditModelStore } from '../IfcImporter/stores/editModelStore'
import { useFiltersStore } from '../IfcImporter/stores/filtersStore'
import { useIfcElementsStore } from '../IfcImporter/stores/ifcElementsStore'
import { useIssuesStore } from '../IfcImporter/stores/issuesStore'
import { useParseAndOrientModelStore } from '../IfcImporter/stores/parseAndOrientModelStore'
import { useProjectStore } from '../IfcImporter/stores/projectStore'
import { typeVisibilityStoreInstances } from '../IfcImporter/stores/typeVisibilityStore'
import { CreationDialog } from './CreationDialog'

function resetStores() {
  ;[
    useCameraStore,
    useTapelineStore,
    useGltfModelStore,
    useAssignmentsStore,
    useTapelineStore,
    useAssignmentsStore,
    useFiltersStore,
    useIfcElementsStore,
    useParseAndOrientModelStore,
    useOpeningsStore,
    useGeneratedModelStore,
    useEditModelStore,
    useIssuesStore,
    useProjectStore,
    ...Object.values(typeVisibilityStoreInstances),
  ].forEach(store => store.getState().clear())
}

export function Main(): ReactElement {
  const { t } = useTranslation(['common', 'main'])
  const [isCreationDialogOpen, setIsCreationDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  // we use this effect to reset all data so the user gets a clean
  // scene/interface when he switches to / creates a new projects
  useEffect(() => resetStores, [])

  const projectsQuery = useQuery(
    'getProjects',
    async () => {
      const { data } = await getRequest<Project[]>({ url: config.apiRoutes.getPostProjects })
      return data
    },
    {
      onError: () => {
        enqueueSnackbar(t('main:errors.loadingProjects'), { variant: 'error' })
      },
    },
  )

  const remainingProjectsQuery = useQuery('getRemainingProjects', async () => {
    const { data } = await getRequest<{ remaining_projects: number }>({
      url: config.apiRoutes.getRemainingProjects,
    })
    return data.remaining_projects
  })

  const deleteProjectMutation = useMutation(
    async (projectId: string) => {
      await deleteRequest({ url: config.apiRoutes.deleteProject(projectId) })
      await projectsQuery.refetch()
    },
    {
      onError: () => {
        enqueueSnackbar(t('main:errors.deleteProject'), { variant: 'error' })
      },
    },
  )

  const projects = projectsQuery.isLoading ? [] : projectsQuery.data ? projectsQuery.data : []
  const projectsSorted = useMemo(() => {
    return [...projects].sort((a, b) =>
      dayjs(a.created_timestamp).isAfter(dayjs(b.created_timestamp)) ? -1 : 1,
    )
  }, [projects])

  return (
    <>
      <Stack spacing={2} alignItems="center" mt={4}>
        <Typography variant="h2" gutterBottom>
          Modugen IFC Importer
        </Typography>

        <Button
          variant="contained"
          onClick={() => setIsCreationDialogOpen(true)}
          disabled={deleteProjectMutation.isLoading}
        >
          {t('main:actions.createNewProject')}
          {!isUndefined(remainingProjectsQuery.data) && ` (${remainingProjectsQuery.data})`}
        </Button>

        {projectsQuery.isLoading ? (
          <Stack>
            <CircularProgress size={24} sx={{ mt: 2 }} />
          </Stack>
        ) : (
          <List sx={{ width: theme => theme.spacing(50) }}>
            {projectsSorted.map((project: Project) => (
              <ListItemButton
                key={project.guid}
                component={Link}
                to={`/ifc-importer/${project.guid}/`}
              >
                <ListItemAvatar>
                  <Avatar>
                    <Folder />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary={project.name}
                  secondary={new Date(project.created_timestamp).toLocaleDateString('de-DE')}
                  sx={{
                    wordWrap: 'break-word',
                    // see: http://bit.ly/3WXOTtg on why this needs to be
                    // hardcoded like this
                    paddingRight: 5,
                  }}
                />

                <ListItemSecondaryAction>
                  <AlertDialog
                    title={t('main:actions.deleteProject')}
                    text={t('main:prompts.confirmProjectDeletion') + ` (${project.name})`}
                    confirmLabel={t('common:actions.confirm')}
                    cancelLabel={t('common:actions.cancel')}
                    onConfirm={async () => deleteProjectMutation.mutate(project.guid)}
                  >
                    <IconButton edge="end" disabled={deleteProjectMutation.isLoading}>
                      <Delete />
                    </IconButton>
                  </AlertDialog>
                </ListItemSecondaryAction>
              </ListItemButton>
            ))}
          </List>
        )}
      </Stack>

      <CreationDialog
        isOpen={isCreationDialogOpen}
        onClose={() => setIsCreationDialogOpen(false)}
      />
    </>
  )
}
