import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { ArrowForward, ErrorOutline } from '@mui/icons-material'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Tooltip,
  Badge,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  ListItemButton,
  Checkbox,
  Theme,
} from '@mui/material'

import { ModuGenScene } from '@modugen/scene/lib'

import { HeaderToggleButton } from 'src/components/generic/HeaderToggleButtonGroup'
import { MuiBox } from 'src/components/generic/MuiBox'
import { HeaderPortalLeft } from 'src/components/global/HeaderBar'
import { codeToKeyMapping } from 'src/config/errors'

import { GltfModelController } from '../controllers/GltfModelController'
import IssuesOpeningsController from '../controllers/OpeningsControllers/IssuesOpeningsController'
import useSelectElement from '../hooks/useSelectElement'
import { useIfcElementsStore } from '../stores/ifcElementsStore'
import { useIssuesStore } from '../stores/issuesStore'

export function IssuesModal(): ReactElement {
  const exceptionModalOpen = useIssuesStore(state => state.exceptionModalOpen)
  const closeExceptionModal = useIssuesStore(state => state.closeExceptionModal)
  const toggleExceptionModalOpen = useIssuesStore(state => state.toggleExceptionModalOpen)
  const setEmphasizedIssue = useIssuesStore(state => state.setEmphasizedIssue)
  const clearEmphasizedIssue = useIssuesStore(state => state.clearEmphasizedIssue)
  const emphasizedIssue = useIssuesStore(state => state.emphasizedIssue)
  const toggleEmphasizedIssueLock = useIssuesStore(state => state.toggleEmphasizedIssueLock)
  const emphasizedIssueLocked = useIssuesStore(state => state.emphasizedIssueLocked)

  const taskErrors = useIssuesStore(state => state.errors)
  const taskWarnings = useIssuesStore(state => state.warnings)
  const clearIssues = useIssuesStore(state => state.clear)

  const numWarningsAndErrors = taskErrors.length + taskWarnings.length
  const hasErrors = !!taskErrors

  const history = useHistory()
  const { projectId } = useParams<{ projectId: string }>()

  const selectElement = useSelectElement()
  const deselectAllIds = useIfcElementsStore(state => state.deselectAllIds)

  const { t } = useTranslation(['ifcImporter', 'common'])

  // log if unknown errors/warnings occured -> if the code is unknnown
  useEffect(() => {
    taskErrors.forEach(taskError => {
      if (!codeToKeyMapping[taskError.code]) {
        console.error(`Unknown task error code occured: ${taskError.code}`)
      }
    })
  }, [taskErrors])

  useEffect(() => {
    taskWarnings.forEach(taskWarning => {
      if (!codeToKeyMapping[taskWarning.code]) {
        console.error(`Unknown task warning code occured: ${taskWarning.code}`)
      }
    })
  }, [taskWarnings])

  return (
    <>
      {numWarningsAndErrors !== 0 && (
        <HeaderPortalLeft>
          <Tooltip title={t('ifcImporter:issuesModal.openIssues')} sx={{ mr: 1 }}>
            <HeaderToggleButton
              value="active"
              size="small"
              selected={exceptionModalOpen}
              onClick={toggleExceptionModalOpen}
            >
              <Badge badgeContent={numWarningsAndErrors} color={hasErrors ? 'error' : 'warning'}>
                <ErrorOutline fontSize="small" />
              </Badge>
            </HeaderToggleButton>
          </Tooltip>
        </HeaderPortalLeft>
      )}

      <Dialog open={exceptionModalOpen} fullWidth maxWidth={false}>
        <DialogTitle style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="h6">{t('ifcImporter:issuesModal.modalTitle')}</Typography>
        </DialogTitle>

        <Grid container height={(theme: Theme) => `calc(100vh - ${theme.spacing(24)})`}>
          <Grid
            item
            xs={8}
            sx={{
              backgroundColor: 'grey.50',
              display: 'flex',
              flex: 1,
              alignItems: 'stretch',
            }}
          >
            <MuiBox position="relative" flexGrow={1} alignSelf="stretch" overflow="hidden">
              <ModuGenScene>
                <ambientLight intensity={0.75} />
                <pointLight position={[15, 10, 15]} intensity={0.25} />
                <pointLight position={[-15, -10, 15]} intensity={0.25} />

                <GltfModelController displayMode="issue" />
                <IssuesOpeningsController />
              </ModuGenScene>
            </MuiBox>
          </Grid>
          <Grid
            item
            xs={4}
            style={{ overflowY: 'scroll', flex: 1, alignItems: 'stretch', maxHeight: '100%' }}
            padding={2}
          >
            {!!taskErrors.length && (
              <>
                <Typography variant="h3" component="h2">
                  <>
                    {t('common:errors')} ({taskErrors.length})
                  </>
                </Typography>
                <List>
                  {taskErrors.map((taskError, i) => (
                    <ListItem
                      key={i}
                      secondaryAction={
                        <IconButton
                          onClick={() => {
                            closeExceptionModal()
                            history.push(`/ifc-importer/${projectId}/step-3-filter`)
                            if (taskError.element_guid) {
                              deselectAllIds()
                              selectElement(taskError.element_guid)
                            }
                          }}
                          edge="end"
                          aria-label="delete"
                        >
                          <ArrowForward />
                        </IconButton>
                      }
                    >
                      <ListItemButton
                        onMouseEnter={
                          emphasizedIssueLocked ? undefined : () => setEmphasizedIssue(taskError)
                        }
                        onMouseLeave={emphasizedIssueLocked ? undefined : clearEmphasizedIssue}
                        onClick={toggleEmphasizedIssueLock}
                        disabled={emphasizedIssueLocked && emphasizedIssue !== taskError}
                      >
                        <Checkbox checked={emphasizedIssue === taskError} />
                        <ListItemText
                          primary={
                            codeToKeyMapping[taskError.code]
                              ? t(`ifcImporter:issues.${codeToKeyMapping[taskError.code]}`)
                              : t('ifcImporter:issuesModal.unknownIssue')
                          }
                          secondary={
                            taskError.element_guid || t('ifcImporter:issuesModal.unknownCause')
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </>
            )}

            {!!taskWarnings.length && (
              <>
                <Typography variant="h3" component="h2">
                  {t('common:terms.warnings')} ({taskWarnings.length})
                </Typography>
                <List>
                  {taskWarnings.map((taskWarning, i) => (
                    <ListItem
                      key={i}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            closeExceptionModal()
                            history.push(`/ifc-importer/${projectId}/step-3-filter`)
                            if (taskWarning.element_guid) {
                              deselectAllIds()
                              selectElement(taskWarning.element_guid)
                            }
                          }}
                        >
                          <ArrowForward />
                        </IconButton>
                      }
                    >
                      <ListItemButton
                        onMouseEnter={
                          emphasizedIssueLocked ? undefined : () => setEmphasizedIssue(taskWarning)
                        }
                        onMouseLeave={emphasizedIssueLocked ? undefined : clearEmphasizedIssue}
                        onClick={toggleEmphasizedIssueLock}
                        disabled={emphasizedIssueLocked && emphasizedIssue !== taskWarning}
                      >
                        <Checkbox checked={emphasizedIssue === taskWarning} />
                        <ListItemText
                          primary={
                            codeToKeyMapping[taskWarning.code]
                              ? t(`ifcImporter:issues.${codeToKeyMapping[taskWarning.code]}`)
                              : t('ifcImporter:issuesModal.unknownIssue')
                          }
                          secondary={
                            taskWarning.element_guid || t('ifcImporter:issuesModal.unknownCause')
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Grid>
        </Grid>

        <DialogActions>
          <Button
            onClick={() => {
              clearIssues()
              closeExceptionModal()
            }}
          >
            {t('common:actions.deleteAndClose')}
          </Button>
          <Button onClick={closeExceptionModal}>{t('common:actions.close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
