import de from './de'

const en: typeof de = {
  actions: {
    downloadJson: 'Download JSON',
  },

  labels: {
    exportToStatik: 'Export to Statik',
  },

  hints: {
    modelExport:
      'As soon as the model has reached the desired state, you are able to download it for ModuGen Statik',
  },
}

export default en
