import produce from 'immer'
import { cloneDeep } from 'lodash-es'
import create from 'zustand'
import { combine } from 'zustand/middleware'

import { IfcElementAssignments } from '../types'

interface AssignmentsStoreState {
  assignments: IfcElementAssignments[]
}

const initialState: AssignmentsStoreState = {
  assignments: [],
}

export const useAssignmentsStore = create(
  combine(cloneDeep(initialState), set => ({
    clear: () => set(cloneDeep(initialState)),

    setAssignments: (assignments: IfcElementAssignments[]) => set({ assignments }),

    addAssignment: (assignment: IfcElementAssignments) =>
      set(
        produce((state: AssignmentsStoreState) => {
          state.assignments.push(assignment)
        }),
      ),

    removeLastAssignment: () =>
      set(
        produce((state: AssignmentsStoreState) => {
          state.assignments.pop()
        }),
      ),
  })),
)
