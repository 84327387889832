import { styled, ButtonGroup, Button } from '@mui/material'
import { alpha } from '@mui/material/styles'

export const HeaderButtonGroup = styled(ButtonGroup)({
  backgroundColor: 'transparent',

  // if we do not do this there is a weird white border on the right side of
  // each button on hover
  '& .MuiButtonGroup-grouped': {
    '&:not(:last-of-type)': {
      '&:not(:last-of-type):hover': {
        borderRightColor: 'transparent',
      },
    },
  },
})

export const HeaderButton = styled(Button)(({ theme }) => ({
  color: alpha(theme.palette.common.white, 0.75),
  borderColor: alpha(theme.palette.common.white, 0.5),

  '&.Mui-selected': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
  },

  '&.Mui-disabled': {
    color: alpha(theme.palette.common.white, 0.25),
    borderColor: alpha(theme.palette.common.white, 0.25),
  },

  '&:hover': {
    borderColor: alpha(theme.palette.common.white, 0.5),
  },
})) as typeof Button
