import { cloneDeep } from 'lodash-es'
import { Object3D } from 'three'
import create from 'zustand'
import { combine } from 'zustand/middleware'

import { RectangleXYZ } from '@modugen/scene/lib/types'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import { WallPlacement } from '../types'
import { ArchViewTabs } from '../views/step5-Arch'

export type EditMode = 'delete' | 'draw' | 'change' | 'move' | 'opening' | 'placement'

export interface DrawnOpening {
  points: RectangleXYZ
}

export interface SelectedWalls {
  guid: string
  placement: string
}

export type EditedWall = { start: ImmutableVector3; end: ImmutableVector3 }

export interface DrawnWall extends EditedWall {
  startSnapGuid?: string
  endSnapGuid?: string
  placement: WallPlacement
}

interface EditModelStoreState {
  activeStorey?: string
  selectedWall?: string // only one wall can be selected at a time
  selectedSlab?: string // only one slab can be selected at a time
  selectedRoofSlab?: string // only one roof slab can be selected at a time

  drawnWall?: DrawnWall // only one wall can be drawn at a time
  isDrawnWallSelected?: boolean

  drawnOpening?: DrawnOpening
  selectedOpening?: string
  isDrawingOpeningActive: boolean

  snapToCornersAndEdges: boolean
  snapToAngles: boolean
  snapOrthogonal: boolean
  snapToTapelineCenters: boolean
  additionalSnapTargets?: Object3D[]

  editMode?: EditMode

  editedWall?: EditedWall

  isMovingActive: boolean
  isExtendingActive: boolean

  selectedWallsForPlacement: string[]

  activeTabIndex: ArchViewTabs
}

const initialState: EditModelStoreState = {
  snapToCornersAndEdges: true,
  snapToAngles: false,
  snapOrthogonal: true,
  snapToTapelineCenters: true,

  isDrawingOpeningActive: false,
  isMovingActive: false,
  isExtendingActive: false,

  selectedWallsForPlacement: [],

  activeTabIndex: ArchViewTabs.Alignment,
}

export const useEditModelStore = create(
  combine(cloneDeep(initialState), set => ({
    clear: () => set(cloneDeep(initialState)),

    // STOREY SELECTION RELATED

    setActiveStorey: (activeStorey?: string) => set({ activeStorey }),

    // ELEMENT SELECTION RELATED

    setSelectedWall: (selectedWall?: string) => set({ selectedWall }),
    setSelectedSlab: (selectedSlab?: string) => set({ selectedSlab }),
    setSelectedRoofSlab: (selectedRoofSlab?: string) => set({ selectedRoofSlab }),

    // DRAWING RELATED

    setDrawnWall: (drawnWall?: DrawnWall) => set({ drawnWall }),

    setIsDrawnWallSelected: (isDrawnWallSelected?: boolean) => set({ isDrawnWallSelected }),

    setDrawnOpening: (drawnOpening?: DrawnOpening) => set({ drawnOpening }),
    setSelectedOpening: (selectedOpening?: string) => set({ selectedOpening }),

    setSnapToCornersAndEdges: (snapToCornersAndEdges: boolean) => set({ snapToCornersAndEdges }),
    setSnapToAngles: (snapToAngles: boolean) => set({ snapToAngles }),
    setSnapOrthogonal: (orthoSnap: boolean) => set({ snapOrthogonal: orthoSnap }),
    setSnapToTapelineCenters: (snapToTapelineCenters: boolean) => set({ snapToTapelineCenters }),
    setAdditionalSnapTargets: (additionalSnapTargets?: Object3D[]) =>
      set({ additionalSnapTargets }),

    setEditMode: (editMode?: EditMode) => set({ editMode }),

    setEditedWall: (editedWall: EditedWall | undefined) => set({ editedWall }),
    setIsMovingActive: (isMovingActive: boolean) => set({ isMovingActive }),
    setIsDrawingOpeningActive: (isDrawingOpeningActive: boolean) => set({ isDrawingOpeningActive }),
    setIsExtendingActive: (isExtendingActive: boolean) => set({ isExtendingActive }),

    setSelectedWallsForPlacement: (selectedWallsForPlacement: string[]) =>
      set({ selectedWallsForPlacement }),

    setActiveTabIndex: (activeTabIndex: ArchViewTabs) => set({ activeTabIndex }),
  })),
)
