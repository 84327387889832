import { get } from 'lodash-es'

import React, { ReactElement, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import ErrorFieldBase from './ErrorFieldBase'

interface Props {
  name: string
  severity?: 'error' | 'warning'
}

const ErrorField = ({ name, severity }: Props): ReactElement | null => {
  const {
    formState: { errors, submitCount },
    watch,
  } = useFormContext()
  const fieldWatch = watch(name)

  const error = useMemo(() => {
    return submitCount ? get(errors, name) : null
  }, [errors, name, fieldWatch, submitCount])

  if (!error) return null

  return <ErrorFieldBase message={error.message as string} severity={severity} />
}

export default ErrorField
