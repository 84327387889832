export default {
  terms: {
    assign: 'Zuweisen',
    assignment: 'Zuweisung',
  },

  actions: {
    removeAssignments: 'Zuweisung entfernen',
  },

  hints: {
    chooseIfcGroup: 'Bestimme den IFC Typ, dem die Elemente zugewiesen werden',
  },

  labels: {
    existingAssignments: 'Bestehende Zuweisungen',
    capturedElements: 'Erfasste Elemente',
    assignConstructionParts: 'Zuweisung von Bauteilen',
    filterElements: 'Filtern von Bauteilen',
  },

  hotkeys: {
    deleteLastAssignment: 'Letzte Zuweisung löschen',
  },

  errors: {
    saveAssignments: 'Fehler beim Speichern der Zuweisungen',
  },
}
