import { config } from 'src/config/config'
import { getRequest, postRequest, retryUntilSuccessOrFailure } from 'src/utils/requests'

import { ProcessingSteps, useParseAndOrientModelStore } from '../stores/parseAndOrientModelStore'
import { CoordinateSystem, PlanarModel, TaskSummary } from '../types'

export async function getParseAndOrientModel(
  projectId: string,
  signal: AbortSignal,
): Promise<{
  planarModel?: PlanarModel
  coordinateSystem?: CoordinateSystem
  taskSummary: TaskSummary
}> {
  const {
    setProcessingStep,
    addProcessedStep,
    clear: clearProcessing,
  } = useParseAndOrientModelStore.getState()
  clearProcessing()

  setProcessingStep(ProcessingSteps.ParseModel)

  const transformsResponse = await postRequest<{ task_id: string }>({
    url: config.apiRoutes.postStartParsing(projectId),
    signal,
  })

  await retryUntilSuccessOrFailure({
    url: config.apiRoutes.getTaskStatus(projectId, transformsResponse.data.task_id),
    params: transformsResponse.data,
    signal,
  })

  addProcessedStep(ProcessingSteps.ParseModel)
  setProcessingStep(ProcessingSteps.CalculateOrientation)

  const externalWallStartResponse = await postRequest<{ task_id: string }>({
    url: config.apiRoutes.postStartExternalWallOrientation(projectId),
    signal,
  })

  await retryUntilSuccessOrFailure({
    url: config.apiRoutes.getTaskStatus(projectId, externalWallStartResponse.data.task_id),
    signal,
  })

  addProcessedStep(ProcessingSteps.CalculateOrientation)
  setProcessingStep(ProcessingSteps.VerticalAlignment)

  const schnittbombeStartResponse = await postRequest<{ task_id: string }>({
    url: config.apiRoutes.postStartVerticalAlignment(projectId),
    signal,
  })

  await retryUntilSuccessOrFailure({
    url: config.apiRoutes.getTaskStatus(projectId, schnittbombeStartResponse.data.task_id),
    signal,
  })

  await postRequest({
    url: config.apiRoutes.postInitializeArchView(projectId),
    signal,
  })

  addProcessedStep(ProcessingSteps.VerticalAlignment)
  setProcessingStep(ProcessingSteps.GetOrientationResult)

  const taskSummary = (
    await getRequest<TaskSummary>({
      url: config.apiRoutes.getTaskSummary(projectId),
      signal,
    })
  ).data

  const planarModel =
    taskSummary.errors?.length === 0
      ? (
          await getRequest<PlanarModel>({
            url: config.apiRoutes.getCurrentModelPlanar(projectId),
            signal,
          })
        ).data
      : undefined

  const coordinateSystem =
    taskSummary.errors?.length === 0
      ? (
          await getRequest<CoordinateSystem>({
            url: config.apiRoutes.getCoordinateSystem(projectId),
            signal,
          })
        ).data
      : undefined

  setProcessingStep(undefined)

  return {
    planarModel,
    taskSummary,
    coordinateSystem,
  }
}
