import { flatten } from 'lodash-es'
import { Vector3, BufferGeometry, Mesh, Line } from 'three'

import { useMemo } from 'react'

import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'

/**
 * Will read the current tapelines from the store and return targets to be used
 * by DrawController. When tapelines are not visible no targets will be returned
 */
const useTapelineSnapTargets = () => {
  const isTapelineVisible = useTapelineStore(state => state.isVisible)
  const tapelines = useTapelineStore(state => state.lines)

  return useMemo(
    () =>
      isTapelineVisible
        ? flatten(
            tapelines.map(tapeline => {
              const start = tapeline.start
              const end = tapeline.end
              const startGeometry = new BufferGeometry().setFromPoints([start.v])
              const startPoint = new Mesh(startGeometry)
              const endGeometry = new BufferGeometry().setFromPoints([end.v])
              const endPoint = new Mesh(endGeometry)

              const p1 = new Vector3(start.x, start.y, start.z)
              const p2 = new Vector3(end.x, end.y, end.z)

              const geometry = new BufferGeometry().setFromPoints([p1, p2])
              const line = new Line(geometry)

              return [startPoint, endPoint, line]
            }),
          )
        : [],
    [tapelines, isTapelineVisible],
  )
}

export default useTapelineSnapTargets
