import { Group, Mesh, Box3, Vector3, Object3D, Matrix4 } from 'three'

import React, { useRef, useMemo, useLayoutEffect, ComponentType, ReactElement } from 'react'

import { toImmutable } from '@modugen/scene/lib'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'

import { getCoordinateSystemMatrix } from 'src/utils/getCoordinateSystemMatrix'

import { useIfcElementsStore } from '../../stores/ifcElementsStore'
import { DisplayMode } from '../../types'
import { fixGroupChildIds } from '../../utils/fixGroupChildIds'
import { useGeneratedModelStore } from '../GeneratedModelController/generatedModelStore'
import { useGltfModelStore } from './gltfModelStore'
import GltfIssueMesh from './meshes/GltfIssueMesh'
import GltfMesh from './meshes/GltfMesh'

// memoizing the controller is essential to prevent useless re-renders
export const GltfModelController = React.memo(function GltfModelController(props: {
  displayMode: DisplayMode
}): ReactElement | null {
  const groupRef = useRef<Group>(null)

  const gltfModel = useGltfModelStore(state => state.gltfModel)
  const coordinateSystem = useGltfModelStore(state => state.coordinateSystem)
  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)

  const allIfcIds = useIfcElementsStore(state => state.allIfcIds)
  const setCameraRotationTarget = useCameraStore(state => state.setRotationTarget)

  const gltfModelFixedIfcIds = useMemo(() => {
    if (!gltfModel || !allIfcIds.size) return

    const model = gltfModel.clone()
    fixGroupChildIds(allIfcIds, model, null)

    return model
  }, [gltfModel, allIfcIds])

  // set camera rotation target to center of the model
  useLayoutEffect(() => {
    if (!gltfModelFixedIfcIds || !groupRef.current || currentModelPlanar) return

    const boundingBox = new Box3().setFromObject(groupRef.current)
    const center = boundingBox.getCenter(new Vector3())

    // we have a plate really far outside the building in one of the zieglerhaus
    // projects with nothing in between. this breaks the initial viewpoint as
    // the center of the model is just empty space. hence we decided to just use
    // the minimum z as initial viewpoint
    center.setZ(boundingBox.min.z)

    setCameraRotationTarget(toImmutable(center))
  }, [gltfModelFixedIfcIds, groupRef.current])

  return gltfModelFixedIfcIds ? (
    // TODO: figure out what "dispose" value to set (see https://gltf.pmnd.rs/)
    <group
      ref={groupRef}
      // we need to first apply rotation (inner group below) and then apply
      // coordinate system, hence this is defined in the outer group. if we both
      // define it within the same group the order cannot be guaranteed
      matrixAutoUpdate={false}
      matrix={coordinateSystem ? getCoordinateSystemMatrix(coordinateSystem) : new Matrix4()}
    >
      {/* the default up direction is in upper y-direction. we use global
          z-Direction, hence we need to rotate the model here (seems to be the
          case with most models) */}
      <group rotation={[Math.PI / 2, 0, 0]}>
        <RecursiveGltfElement displayMode={props.displayMode} object={gltfModelFixedIfcIds} />
      </group>
    </group>
  ) : null
})

interface RecursiveGltfElementProps {
  object: Object3D
  displayMode: DisplayMode
}

function RecursiveGltfElement({
  object,
  displayMode,
}: RecursiveGltfElementProps): ReactElement | null {
  let GltfElement!: ComponentType

  if (object instanceof Group) GltfElement = 'group' as unknown as ComponentType
  if (object instanceof Mesh) GltfElement = displayMode === 'edit' ? GltfMesh : GltfIssueMesh

  if (GltfElement) {
    return (
      <>
        {/* @ts-ignore */}
        <GltfElement {...object}>
          {object.children.map(child => (
            <RecursiveGltfElement key={child.uuid} object={child} displayMode={displayMode} />
          ))}
        </GltfElement>
      </>
    )
  }

  return null
}
