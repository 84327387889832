import de from './de'

const en: typeof de = {
  terms: {
    address: 'Address',
    projectName: 'Project name',
    constructionProject: 'Construction project',
  },

  actions: {
    createNewProject: 'Create new project',
    deleteProject: 'Delete project',
  },

  prompts: {
    confirmProjectDeletion: 'Confirm deletion of the project',
  },

  errors: {
    loadingProjects: 'Error loading projects',
    createProject: 'Error creating project',
    deleteProject: 'Error deleting project',
    noProjectsLeft:
      'You do not have any projects remaining. Please contact support to obtain new ones.',
  },
}

export default en
