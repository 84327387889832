import { cloneDeep } from 'lodash-es'
import create from 'zustand'
import { combine } from 'zustand/middleware'

import React, { ReactElement, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import LogoutIcon from '@mui/icons-material/Logout'
import {
  AppBar,
  Toolbar,
  Box,
  Stack,
  Link,
  IconButton,
  useTheme,
  Select,
  MenuItem,
  alpha,
  Typography,
} from '@mui/material'

import { useAuth0 } from '@auth0/auth0-react'

import ModugenLogo from 'src/assets/modugen-logo.png'
import { useProjectStore } from 'src/pages/IfcImporter/stores/projectStore'

import { MuiBox } from '../generic/MuiBox'
import HelpMenu from './HelpMenu'
import HotKeysWidget from './HotkeysWidget'

interface HeaderStoreState {
  portalRootLeft: HTMLDivElement | null
  portalRootRight: HTMLDivElement | null
}

const initialState: HeaderStoreState = {
  portalRootLeft: null,
  portalRootRight: null,
}

const useHeaderStore = create(
  combine(cloneDeep(initialState), set => ({
    setPortalRootLeft: (portalRootLeft: HTMLDivElement | null) => set({ portalRootLeft }),
    setPortalRootRight: (portalRootRight: HTMLDivElement | null) => set({ portalRootRight }),
  })),
)

export function HeaderBar(): ReactElement {
  const { i18n } = useTranslation()

  // IMPORTANT: extracting these setters using destructuring can lead the whole
  // application to crash, state picking is therefore recommended
  const setPortalRootLeft = useHeaderStore(state => state.setPortalRootLeft)
  const setPortalRootRight = useHeaderStore(state => state.setPortalRootRight)

  const { logout, isAuthenticated } = useAuth0()
  const theme = useTheme()

  const project = useProjectStore(state => state.project)

  return (
    <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
      <Toolbar
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        <Stack direction="row" flex={1} gap={2} display="flex" alignItems="center">
          <Link component={RouterLink} to="/" sx={{ flexGrow: 0 }}>
            <Box component="img" height={theme => theme.spacing(5)} src={ModugenLogo} />
          </Link>

          <Stack direction="row" spacing={1} ref={ref => setPortalRootLeft(ref as HTMLDivElement)}>
            <HotKeysWidget />
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" flex={1} justifyContent="center">
          <Typography>{project?.name}</Typography>
        </Stack>

        <Stack direction="row" flex={1} justifyContent="flex-end" gap={2} alignItems="center">
          <Stack
            direction="row"
            spacing={1}
            ref={ref => setPortalRootRight(ref as HTMLDivElement)}
          />
          <MuiBox>
            <Select
              size="small"
              value={i18n.language}
              label=""
              onChange={lang => i18n.changeLanguage(lang.target.value)}
              variant="outlined"
              sx={{
                color: alpha(theme.palette.common.white, 0.75),

                '&:hover, &.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 1,
                    borderColor: alpha(theme.palette.common.white, 0.5),
                  },
                },

                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: alpha(theme.palette.common.white, 0.25),
                },

                '& .MuiSvgIcon-root': {
                  color: theme.palette.common.white,
                },
              }}
            >
              <MenuItem value={'de'}>De</MenuItem>
              <MenuItem value={'en'}>En</MenuItem>
            </Select>
          </MuiBox>
          <HelpMenu />
          {isAuthenticated && (
            <IconButton onClick={() => logout({ returnTo: `${location.origin}/authorize` })}>
              <LogoutIcon
                sx={{
                  color: theme.palette.common.white,
                }}
              />
            </IconButton>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export function HeaderPortalLeft(props: { children: ReactNode }): ReactElement {
  const portalRootLeft = useHeaderStore(state => state.portalRootLeft)
  return <>{portalRootLeft && createPortal(<div>{props.children}</div>, portalRootLeft)}</>
}

export function HeaderPortalRight(props: { children: ReactNode }): ReactElement {
  const portalRootRight = useHeaderStore(state => state.portalRootRight)
  return <>{portalRootRight && createPortal(<div>{props.children}</div>, portalRootRight)}</>
}
