import { find, toNumber } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Vector3 } from 'three'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { LoadingButton } from '@mui/lab'
import { Stack, Typography } from '@mui/material'

import { RectangleXYZ } from '@modugen/scene/lib/types'
import { getRectCornersFromStartAndEndPoint } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import HotKeyButton from 'src/components/generic/HotKeyButton'
import { Toolbox } from 'src/components/generic/Toolbox'
import { ErrorField, Form } from 'src/components/generic/forms'
import { config } from 'src/config/config'
import { useGeneratedModelStore } from 'src/pages/IfcImporter/controllers/GeneratedModelController/generatedModelStore'
import { setCurrentModel } from 'src/pages/IfcImporter/queries/getAndSetCurrentModel'
import { useEditModelStore } from 'src/pages/IfcImporter/stores/editModelStore'
import { PlanarModel } from 'src/pages/IfcImporter/types'
import { LocalCoordinateSystem } from 'src/pages/IfcImporter/utils/LocalCoordinateSystem'
import { postRequest } from 'src/utils/requests'

import FormFields from './FormFields'
import { DrawOpeningSchema, schema } from './schema'

export function AddOpeningSubTab(): ReactElement {
  const { t } = useTranslation(['common', 'step5Arch', 'ifcImporter'])

  const { projectId } = useParams<{ projectId: string }>()

  const { enqueueSnackbar } = useSnackbar()

  const drawnOpening = useEditModelStore(state => state.drawnOpening)
  const setDrawnOpening = useEditModelStore(state => state.setDrawnOpening)

  const selectedOpening = useEditModelStore(state => state.selectedOpening)
  const setSelectedOpening = useEditModelStore(state => state.setSelectedOpening)

  const selectedWall = useEditModelStore(state => state.selectedWall)
  const setSelectedWall = useEditModelStore(state => state.setSelectedWall)

  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)

  const wall = useMemo(
    () => find(currentModelPlanar?.walls, { guid: selectedWall }),
    [selectedWall, currentModelPlanar],
  )

  const wallCoordinateSystem = useMemo(() => {
    if (!wall) return
    const wallShapeAsVector = wall.shape.points.map(p => new ImmutableVector3(p.x, p.y, p.z))
    return LocalCoordinateSystem.makeFromPolygon(wallShapeAsVector)
  }, [wall])

  const openingSelected = useMemo(
    () => wall && find(wall.openings, { guid: selectedOpening }),
    [wall, selectedOpening],
  )
  const openingSelectedPoints = useMemo(
    () => openingSelected?.shape.points.map(p => new Vector3(p.x, p.y, p.z)),
    [openingSelected],
  )

  const defaultValues = useMemo<DrawOpeningSchema | undefined>(() => {
    if (!wall || !wallCoordinateSystem || (!drawnOpening && !openingSelectedPoints))
      return undefined

    const retransform = wallCoordinateSystem.matrixTransform.clone().invert()

    const [openingP1, , openingP3] = openingSelectedPoints || (drawnOpening?.points as RectangleXYZ)

    const projectedOpeningP1 = openingP1.applyMatrix4(retransform)
    const projectedOpeningP3 = openingP3.applyMatrix4(retransform)

    return {
      startX: Math.abs(projectedOpeningP1.x),
      startY: Math.abs(projectedOpeningP1.y),
      endX: Math.abs(projectedOpeningP3.x),
      endY: Math.abs(projectedOpeningP3.y),
    }
  }, [drawnOpening, wall, openingSelectedPoints, wallCoordinateSystem])

  // MUTATION

  const addOpeningMutation = useMutation(
    async ({ startX, startY, endX, endY }: DrawOpeningSchema) => {
      const start = new ImmutableVector3(toNumber(startX), toNumber(startY), 0)
      const end = new ImmutableVector3(toNumber(endX), toNumber(endY), 0)

      const points = getRectCornersFromStartAndEndPoint(start, end)

      const openingPoints = points.map(p =>
        p.applyMatrix4((wallCoordinateSystem as LocalCoordinateSystem).matrixTransform),
      )

      const response = await postRequest<PlanarModel>({
        url: config.apiRoutes.postAddOpeningToWall(projectId),
        data: {
          wall_guid: selectedWall,
          shape: {
            points: openingPoints.map(p => ({ x: p.x, y: p.y, z: p.z })),
          },
        },
      })

      return response.data
    },
    {
      onSuccess: currenModelResponse => {
        setDrawnOpening(undefined)
        setCurrentModel(currenModelResponse)
      },
      onError: () => {
        enqueueSnackbar(t('step5Arch:errors.addOpening'), { variant: 'error' })
      },
    },
  )

  const deleteOpeningMutation = useMutation(
    async () => {
      const response = await postRequest<PlanarModel>({
        url: config.apiRoutes.postRemoveOpening(projectId),
        data: {
          wall_guid: selectedWall,
          opening_guid: selectedOpening,
        },
      })

      return response.data
    },
    {
      onSuccess: currenModelResponse => {
        setSelectedOpening(undefined)
        setCurrentModel(currenModelResponse)
      },
      onError: () => {
        enqueueSnackbar(t('step5Arch:errors.removeOpening'), { variant: 'error' })
      },
    },
  )

  const onClickDelete = useCallback(
    () => (selectedOpening ? deleteOpeningMutation.mutate() : setDrawnOpening(undefined)),
    [selectedOpening],
  )

  if (!selectedWall)
    return <Typography textAlign="center">{t('step5Arch:editModelTab.clickWall')}</Typography>

  return (
    <>
      <Toolbox>
        <HotKeyButton
          onClick={() => setSelectedWall(undefined)}
          disabled={!selectedWall}
          size="small"
          variant="outlined"
          fullWidth
          hotkeys="q"
          onHotkey={() => setSelectedWall(undefined)}
          hotkeyDescription={t('ifcImporter:hotkeys.deselectElements')}
        >
          {t('common:actions.deselectElements')}
        </HotKeyButton>
      </Toolbox>

      {drawnOpening || openingSelected ? (
        <Form
          id="add-opening"
          onSubmit={addOpeningMutation.mutate}
          validationSchema={schema}
          defaultValues={defaultValues}
          validationContext={{ wall }}
        >
          <Toolbox>
            <FormFields disabled={!!selectedOpening} />

            <ErrorField name="isIntersecting" />
            <ErrorField name="isExceedingWall" />

            <Stack direction="row" spacing={1} overflow="hidden">
              <LoadingButton
                loading={addOpeningMutation.isLoading}
                variant="contained"
                type="submit"
                fullWidth
                disabled={!!selectedOpening}
              >
                {t('common:actions.save')}
              </LoadingButton>

              <LoadingButton
                loading={deleteOpeningMutation.isLoading}
                variant="outlined"
                fullWidth
                onClick={onClickDelete}
              >
                {t('common:actions.delete')}
              </LoadingButton>
            </Stack>
          </Toolbox>
        </Form>
      ) : (
        <Typography textAlign="center">
          {t('step5Arch:editModelTab.drawOpeningByDragNDrop')}
        </Typography>
      )}
    </>
  )
}
