import { Object3D } from 'three'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'

import { config } from 'src/config/config'
import { gltfLoader } from 'src/utils/gltfLoader'
import { getRequest } from 'src/utils/requests'

export async function getAndParseGltfModel(
  projectId: string,
  signal: AbortSignal,
): Promise<Object3D> {
  const geometryResponse = await getRequest<BlobPart>({
    url: config.apiRoutes.getGltfModel(projectId),
    responseType: 'blob',
    signal,
  })

  const blob = new Blob([geometryResponse.data], { type: 'application/glb' })
  const bufferedBlob = await blob.arrayBuffer()
  const parsedGltf = await parseGltf(bufferedBlob)

  return parsedGltf.scene
}

function parseGltf(bufferedBlob: ArrayBuffer): Promise<GLTF> {
  return new Promise((resolve, reject) => {
    gltfLoader.parse(bufferedBlob, '/', resolve, reject)
  })
}
