import React, { ReactElement } from 'react'

import { ExpandMore, Delete } from '@mui/icons-material'
import {
  Typography,
  Button,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'

export function AFAccordion(props: {
  title: string
  children: React.ReactNode
  expanded: boolean
  onChange: () => void
}): ReactElement {
  return (
    <Accordion expanded={props.expanded} onChange={props.onChange}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography fontWeight="bold">{props.title}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={1}>{props.children}</Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export function AFContentLine(props: { title: string; value: string | number }): ReactElement {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px={1}
      py={0.5}
      borderRadius={1}
      bgcolor="grey.100"
    >
      <Typography>{props.title}</Typography>
      <Typography fontWeight="bold">{props.value}</Typography>
    </Stack>
  )
}

export function AFDeleteButton(props: { onDelete: () => void; label: string }): ReactElement {
  return (
    <Stack direction="row" justifyContent="end">
      <Button onClick={props.onDelete} startIcon={<Delete />} size="small">
        {props.label || 'Löschen'}
      </Button>
    </Stack>
  )
}
