import de from './de'

const en: typeof de = {
  hints: {
    confirmExport: 'Confirm model export',

    // All hints for the checkbox the user has to check
    removedGroundPlates: 'All ground slabs are removed',
    removedFrontWalls: 'End panels are removed',
    removedAtticasAndBalustrades: 'Atticas and parapets are removed',
    appliedRoofHack: 'Only one roof slab is given',
  },

  errors: {
    transformationsApplication: 'Error applying transformations',
  },
}

export default en
