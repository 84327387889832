import knot from 'src/assets/ifc-knot.png'
import stab from 'src/assets/ifc-stab.png'
import model from 'src/assets/logic-model.png'

export const cardContents = [
  {
    iconSrc: knot,
    title: 'terms.ifc',
    text: 'terms.ifcNote',
  },
  {
    iconSrc: model,
    title: 'terms.semanticModel',
    text: 'terms.semanticModelNote',
  },
  {
    iconSrc: stab,
    title: 'terms.static',
    text: 'terms.staticNote',
  },
]
