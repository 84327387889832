export function timeout(ms: number): Promise<never> {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function capitalizeFirstLetter(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1)
}

// I tried to use isNaN(parseFloat(num)) but strings like '2.a' are also parsed
// as number see the relevant docs
// https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Upper_Objects/Number/parseFloat
// the isNumber method of lodash only checks if the type of a given value is
// number, so useless in this case too, hence I implemented the check myself.
export function isNumber(num: string): boolean {
  return /^[0-9,.]*$/.test(num)
}

export function isLatin1(str: string) {
  // Latin-1 characters are encoded in the range 0x00 to 0xFF in Unicode
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i)
    if (code < 0 || code > 255) {
      return false
    }
  }
  return true
}
