import { ToggleButtonGroup, ToggleButton, styled } from '@mui/material'
import { alpha } from '@mui/material/styles'

export const HeaderToggleButtonGroup = styled(ToggleButtonGroup)({
  backgroundColor: 'transparent',
})

export const HeaderToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: alpha(theme.palette.common.white, 0.75),
  borderColor: alpha(theme.palette.common.white, 0.5),

  '&.Mui-selected': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
  },

  '&.Mui-disabled': {
    color: alpha(theme.palette.common.white, 0.25),
    borderColor: alpha(theme.palette.common.white, 0.25),
  },
}))
