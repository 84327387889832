import { config } from 'src/config/config'
import { postRequest, retryUntilSuccessOrFailure } from 'src/utils/requests'

export interface UpdateStoreyHeightsOptions {
  translate_openings: boolean
}

export async function applySchnittbombe(
  projectId: string,
  storeyHeightRows: number[],
  updateStoreyHeightsOptions: UpdateStoreyHeightsOptions,
  signal: AbortSignal,
) {
  const response = await postRequest<{ task_id: string }>({
    url: config.apiRoutes.postUpdateStoreyHeights(projectId),
    signal,
    data: { new_storey_heights: storeyHeightRows, options: updateStoreyHeightsOptions },
  })

  await retryUntilSuccessOrFailure({
    url: config.apiRoutes.getTaskStatus(projectId, response.data.task_id),
    signal,
  })
}
