import { filter, find, maxBy, reduce } from 'lodash-es'
import { Line3 } from 'three'

import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useKey } from 'react-use/lib'

import {
  InteractiveLine,
  InteractiveLineHandles,
  InteractiveLineOperation,
  InteractiveLineRef,
} from '@modugen/scene/lib'
import { config as sceneConfig } from '@modugen/scene/lib/config'
import {
  DrawController,
  DrawControllerRef,
  TransientDrawState,
} from '@modugen/scene/lib/controllers/DrawController'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { useCanvasListener } from '@modugen/scene/lib/hooks/useDocumentListener'
import { isPointOnLineSegment, toImmutable } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { Line } from '@react-three/drei'

import sceneColors from 'src/styles/sceneColors'

import { useTapLineCentersSnapTargets } from '../../hooks/useTapLineCentersSnapTargets'
import useTapelineSnapTargets from '../../hooks/useTapelineSnapTargets'
import { useEditModelStore } from '../../stores/editModelStore'
import { PlanarModel, PlanarWall } from '../../types'
import { projectOpening } from '../../utils/projectOpening'
import { projectPlanarWall } from '../../utils/projectPlanarWall'
import { useGeneratedModelStore } from '../GeneratedModelController/generatedModelStore'

interface Props {
  guid: string
}

const wallEndToOpeningThreshold = 0.1
const minWallLength = 0.1

export function WallLengthController({ guid }: Props): ReactElement {
  const snapToCornersAndEdges = useEditModelStore(state => state.snapToCornersAndEdges)
  const orthoSnap = useEditModelStore(state => state.snapOrthogonal)

  const activeStorey = useEditModelStore(state => state.activeStorey)
  const additionalSnapTargets = useEditModelStore(state => state.additionalSnapTargets)
  const editedWall = useEditModelStore(state => state.editedWall)
  const setEditedWall = useEditModelStore(state => state.setEditedWall)
  const setSelectedWall = useEditModelStore(state => state.setSelectedWall)

  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)
  const currentModelStoreyByGuid = useGeneratedModelStore(state => state.currentModelStoreyByGuid)

  const setIsExtendingActive = useEditModelStore(state => state.setIsExtendingActive)
  const isExtendingActive = useEditModelStore(state => state.isExtendingActive)

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const tapelineTargets = useTapelineSnapTargets()
  const tapelineCentersTargets = useTapLineCentersSnapTargets()

  const [activeHandle, setActiveHandle] = useState<InteractiveLineHandles | undefined>()

  const drawControllerRef = useRef<DrawControllerRef>(null)
  const wallLineRef = useRef<InteractiveLineRef>(null)

  const rawPlanarWall = useMemo(
    () =>
      find(
        (currentModelPlanar as PlanarModel).walls,
        wall => currentModelStoreyByGuid[wall.guid] === activeStorey && wall.guid === guid,
      ) as PlanarWall,
    [guid, currentModelPlanar, activeStorey],
  )

  const wallPoints = useMemo(
    () => rawPlanarWall.shape.points.map(p => new ImmutableVector3(p.x, p.y, p.z)),
    [rawPlanarWall],
  )

  const projectedWall = useMemo(() => projectPlanarWall(rawPlanarWall), [rawPlanarWall])

  const wallDirection = useMemo(() => {
    const projectedWallStart = projectedWall.points[0]
    const projectedWallEnd = projectedWall.points[1]

    return projectedWallStart.sub(projectedWallEnd).normalize()
  }, [projectedWall])

  const projectedOpenings = useMemo(
    () => rawPlanarWall.openings.map(opening => projectOpening(opening)),
    [rawPlanarWall],
  )

  const projectedOpeningPoints = useMemo(
    () =>
      reduce(
        projectedOpenings,
        (collector, opening) => [...collector, ...opening.points],
        [] as ImmutableVector3[],
      ),
    [projectedOpenings],
  )

  const projectedEditedWallStart = useMemo(
    () => new ImmutableVector3(editedWall?.start.x, editedWall?.start.y, 0),
    [editedWall],
  )

  const projectedEditedWallEnd = useMemo(
    () => new ImmutableVector3(editedWall?.end.x, editedWall?.end.y, 0),
    [editedWall],
  )

  const [drawingOrigin, drawingEnd] = useMemo(() => {
    if (activeHandle === InteractiveLineHandles.End) {
      return [
        new ImmutableVector3(wallPoints[0].x, wallPoints[0].y, 0),
        new ImmutableVector3(wallPoints[1].x, wallPoints[1].y, 0),
      ]
    } else {
      return [
        new ImmutableVector3(wallPoints[1].x, wallPoints[1].y, 0),
        new ImmutableVector3(wallPoints[0].x, wallPoints[0].y, 0),
      ]
    }
  }, [activeHandle, projectedEditedWallStart, projectedEditedWallEnd])

  const drawingDirection = useMemo(
    () => drawingOrigin && drawingEnd?.sub(drawingOrigin).normalize(),
    [drawingEnd, drawingOrigin],
  )

  const infiniteDrawingLine = useMemo(() => {
    if (drawingOrigin && drawingEnd && drawingDirection) {
      const infiniteStart = drawingOrigin.addScaledVector(drawingDirection, minWallLength)
      const infiniteEnd = drawingEnd?.addScaledVector(drawingDirection, 1000)
      return new Line3(infiniteStart.v, infiniteEnd.v)
    }
  }, [drawingOrigin, drawingEnd, drawingDirection])

  // EFFECTS

  // edited wall will be the full wall length by default
  useEffect(() => {
    setEditedWall({
      start: wallPoints[0],
      end: wallPoints[1],
    })
    return () => setEditedWall(undefined)
  }, [wallPoints])

  useEffect(() => () => setIsExtendingActive(false), [])

  // WALL EDIT EVENTS

  const onWallEditStart = (activeHandle: InteractiveLineHandles) => {
    if (!editedWall) return

    const wallStartXY = new ImmutableVector3(editedWall.start.x, editedWall.start.y)
    const wallEndXY = new ImmutableVector3(editedWall.end.x, editedWall.end.y)

    setIsExtendingActive(true)
    setActiveHandle(activeHandle)

    const originalWallStartXY = new ImmutableVector3(wallPoints[0].x, wallPoints[0].y)
    const originalWallEndXY = new ImmutableVector3(wallPoints[1].x, wallPoints[1].y)

    // we can only update one side of the wall at once (restriction by the
    // backend). Hence we have to reset the drawn wall to it's original state in
    // case it has been updated without saving
    if (activeHandle === InteractiveLineHandles.Start && !wallEndXY.equals(originalWallEndXY)) {
      wallLineRef.current?.updateLine([originalWallStartXY, originalWallEndXY])
    } else if (
      activeHandle === InteractiveLineHandles.End &&
      !wallStartXY.equals(originalWallStartXY)
    ) {
      wallLineRef.current?.updateLine([originalWallStartXY, originalWallEndXY])
    }
  }

  const onWallEditEnd = () => {
    setActiveHandle(undefined)
    setIsExtendingActive(false)

    if (wallLineRef.current && wallLineRef.current.transientLine.current) {
      const line = wallLineRef.current.transientLine.current
      const start = new ImmutableVector3(line[0].x, line[0].y, 0)
      const end = new ImmutableVector3(line[1].x, line[1].y, 0)
      setEditedWall({ start, end })
    }
  }

  const onDrawMouseMove = (transientDrawState: TransientDrawState) => {
    if (wallLineRef.current?.inputActive.current) return

    if (
      drawingOrigin &&
      transientDrawState.drawPoint &&
      infiniteDrawingLine &&
      drawingDirection &&
      activeHandle
    ) {
      const drawingEnd = transientDrawState.drawPoint

      // find all opening points that are not on the line drawn by the user. We
      // want to prevent the user from clipping an opening so he/she should only
      // be able to draw until he encounters an opening
      const openingPointsNotOnLine = filter(
        projectedOpeningPoints,
        point =>
          !isPointOnLineSegment(
            new Line3(drawingOrigin.v, drawingEnd.v),
            point.v,
            wallEndToOpeningThreshold,
          ),
      )

      let drawPoint = transientDrawState.drawPoint
      if (openingPointsNotOnLine.length) {
        drawPoint = maxBy(openingPointsNotOnLine, a =>
          a.distanceTo(drawingOrigin),
        ) as ImmutableVector3
      }

      // when the drawpoint is one of the projected opening points we want to set a small
      // threshold so the user is not removing one side of the opening and
      // therefore open the opening (pun intended)
      const openingPointWithingThresholdDistance = find(
        projectedOpeningPoints,
        point => point.distanceTo(drawPoint) < wallEndToOpeningThreshold,
      )
      if (openingPointWithingThresholdDistance) {
        const drawingDirection =
          // TODO: add negate to ImmutableVector class
          activeHandle === InteractiveLineHandles.End
            ? wallDirection
            : toImmutable(wallDirection.v.negate())

        drawPoint = openingPointWithingThresholdDistance.addScaledVector(
          drawingDirection,
          -wallEndToOpeningThreshold,
        )
      }

      // we do not want to set the new end point before the start point of the
      // wall, and therefore changing it's orientation. This limitation is
      // imposed by the backend. We also want to impose a minimum length.
      const newLineLength = drawingOrigin.distanceTo(drawPoint)
      if (
        newLineLength <= minWallLength ||
        !isPointOnLineSegment(infiniteDrawingLine, drawPoint.v, 0.1)
      ) {
        drawPoint = drawingOrigin.addScaledVector(drawingDirection, minWallLength)
      }

      // wallLineRef.current?.updateActiveHandle(drawPoint)
      wallLineRef.current?.updateHandle(activeHandle, drawPoint)
    }
  }

  const findDrawPoint = (
    drawingOrigin: ImmutableVector3,
    drawPoint: ImmutableVector3,
    openingPoints: ImmutableVector3[],
  ) => {
    // find all opening points that are not on the line drawn by the user. We
    // want to prevent the user from clipping an opening so he/she should only
    // be able to draw until he encounters an opening
    const openingPointsNotOnLine = filter(
      openingPoints,
      point =>
        !isPointOnLineSegment(
          new Line3(drawingOrigin.v, drawPoint.v),
          point.v,
          wallEndToOpeningThreshold,
        ),
    )

    let finalDrawPoint = drawPoint
    if (openingPointsNotOnLine.length) {
      drawPoint = maxBy(openingPointsNotOnLine, a =>
        a.distanceTo(drawingOrigin),
      ) as ImmutableVector3
    }

    // when the drawpoint is one of the projected opening points we want to set a small
    // threshold so the user is not removing one side of the opening and
    // therefore open the opening (pun intended)
    const openingPointWithingThresholdDistance = find(
      projectedOpeningPoints,
      point => point.distanceTo(drawPoint) < wallEndToOpeningThreshold,
    )
    if (openingPointWithingThresholdDistance) {
      const drawingDirection =
        activeHandle === InteractiveLineHandles.End ? wallDirection.v : wallDirection.v.negate()

      finalDrawPoint = openingPointWithingThresholdDistance.addScaledVector(
        toImmutable(drawingDirection),
        wallEndToOpeningThreshold,
      )
    }

    return finalDrawPoint
  }

  const onEnterExtend = (distance: number, operation: InteractiveLineOperation) => {
    const transientLine = wallLineRef.current?.transientLine.current
    if (!transientLine || !activeHandle) return

    const [start, end] = transientLine
    const referenceStartPoint = activeHandle === InteractiveLineHandles.Start ? end : start
    const referenceEndPoint = activeHandle === InteractiveLineHandles.Start ? start : end
    const direction = referenceEndPoint.sub(referenceStartPoint).normalize()
    const newPoint =
      operation === 'replace'
        ? referenceStartPoint.addScaledVector(direction, distance)
        : referenceEndPoint.addScaledVector(direction, distance)

    const finalPoint = findDrawPoint(drawingOrigin, newPoint, projectedOpeningPoints)
    wallLineRef.current?.updateHandle(activeHandle, finalPoint)
    onWallEditEnd()
  }

  useKey('Escape', () => {
    setSelectedWall(undefined)
  })

  useCanvasListener(
    'mouseup',
    () => {
      if (activeHandle) {
        onWallEditEnd()
      }
    },
    [activeHandle, onWallEditEnd],
  )

  const key = useMemo(() => {
    return (
      projectedEditedWallStart.v.toArray().toString() +
      projectedEditedWallEnd.v.toArray().toString()
    )
  }, [projectedEditedWallStart, projectedEditedWallEnd])

  return (
    <>
      {drawingOrigin && (
        <DrawController
          enabled={!isTapelineActive}
          ref={drawControllerRef}
          color={
            projectedWall?.placement === 'Internal'
              ? sceneColors.elements2d.internalWalls
              : sceneColors.elements2d.externalWalls
          }
          xyOnly
          enableIndicator={!!activeHandle && isExtendingActive}
          onMouseMove={onDrawMouseMove}
          additionalSnapTargets={[
            ...(additionalSnapTargets || []),
            ...tapelineTargets,
            ...tapelineCentersTargets,
          ]}
          snapToCornersAndEdges={snapToCornersAndEdges}
          orthoSnap={isExtendingActive && orthoSnap}
          drawingAxis={{
            origin: drawingOrigin,
            direction: wallDirection,
          }}
          indicatorType="crosshair"
        />
      )}

      {editedWall && (
        <InteractiveLine
          ref={wallLineRef}
          // this is a bit hacky and should rather be fixed in the underlying
          // interactive line component. But I am unsure at the moment if this
          // introduces any side effects
          key={key}
          line={[projectedEditedWallStart, projectedEditedWallEnd]}
          color={'blue'}
          isSelected
          input={activeHandle}
          onInputEnter={onEnterExtend}
          onClickHandle={onWallEditStart}
          showHandles
          nonSelectable={!!activeHandle}
          clickDisabled={!!activeHandle}
          handleColor={activeHandle ? 'purple' : 'blue'}
          clickableHandles={activeHandle && [activeHandle]}
        />
      )}

      {/* slight elevation of 0.1 to put openings above the walls, different
        layer as openings should not be selectable */}
      <group position={[0, 0, 0.1]} layers={sceneConfig.R3FNonSelectableObjectLayer}>
        {projectedOpenings &&
          projectedOpenings.map(opening => (
            <Line
              key={opening.guid}
              points={opening.pointsV}
              color={sceneColors.elements2d.openings}
            />
          ))}
      </group>
    </>
  )
}
