import { useSnackbar } from 'notistack'

import React, { ReactElement, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

import { Dialog, DialogTitle, DialogContent, CircularProgress } from '@mui/material'

import {
  fetchProjectHydrationData,
  getProjectState,
  hydrateProject,
} from '../../queries/hydrateProject'
import { getRedirectForProjectState } from '../../utils/getRedirectForProjectState'

export function Step0Hydrate(): ReactElement {
  const { t } = useTranslation('step0Hydrate')
  const history = useHistory()
  const { projectId } = useParams<{ projectId: string }>()
  const controllerRef = useRef(new AbortController())
  const { enqueueSnackbar } = useSnackbar()

  const hydrationQuery = useQuery(
    ['hydrateProject', projectId],
    () => fetchProjectHydrationData(projectId, controllerRef.current.signal),
    {
      onSuccess: data => {
        hydrateProject(data)

        const projectState = getProjectState({
          isInitialUpload: data.isInitialUpload,
          ifcElements: data.elements?.propertyData,
          filters: data.filters,
          model: data.gltfModel,
          planarModel: data.currentModelPlanar,
        })

        history.push(getRedirectForProjectState(projectState, projectId))
      },
      onError: () => {
        enqueueSnackbar(t('errors.hydrateProject'), { variant: 'error', persist: true })
        history.replace('/')
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  useEffect(() => {
    return history.listen(() => {
      if (hydrationQuery.isFetching) {
        controllerRef.current.abort()
      }
    })
  }, [])

  return (
    <Dialog open={true}>
      <DialogTitle>{t('hints.loadingProject')}...</DialogTitle>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )
}
