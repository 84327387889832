import React, { ReactElement } from 'react'

import { Card, styled, Typography } from '@mui/material'

import { MuiBox as Box } from 'src/components/generic/MuiBox'

interface Props {
  iconSrc: string
  title: string
  text: string
}

const Icon = styled('img')`
  height: 120px;
  margin: 0 auto ${({ theme }) => theme.spacing(5)} auto;
  min-height: 120px;
`
const IconCard = ({ iconSrc, title, text }: Props): ReactElement => {
  return (
    <Card elevation={4} sx={{ height: '100%' }}>
      <Box display="flex" flexDirection="column" padding={4}>
        <Icon src={iconSrc} />
        <Typography variant="h4">{title}</Typography>
        <Box mt={2}>
          <Typography>{text}</Typography>
        </Box>
      </Box>
    </Card>
  )
}

export default IconCard
