import { first, filter } from 'lodash-es'
import { Group } from 'three'

import React, { ReactElement, useEffect, useMemo, useRef } from 'react'
import { useKey } from 'react-use/lib'

import { MovableLine, MovableLineRef } from '@modugen/scene/lib/components/Lines/MovableLine'
import { config as sceneConfig } from '@modugen/scene/lib/config'
import {
  DrawControllerRef,
  TransientDrawState,
  DrawController,
} from '@modugen/scene/lib/controllers/DrawController'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { getCenterMultiple } from '@modugen/scene/lib/utils'
import ImmutableLine3 from '@modugen/scene/lib/utils/ImmutableLine3'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { Line } from '@react-three/drei'

import sceneColors from 'src/styles/sceneColors'

import { useTapLineCentersSnapTargets } from '../../hooks/useTapLineCentersSnapTargets'
import useTapelineSnapTargets from '../../hooks/useTapelineSnapTargets'
import { useEditModelStore } from '../../stores/editModelStore'
import { PlanarModel, PlanarWall } from '../../types'
import { projectOpening } from '../../utils/projectOpening'
import { projectPlanarWall } from '../../utils/projectPlanarWall'
import { useGeneratedModelStore } from '../GeneratedModelController/generatedModelStore'

interface Props {
  guid: string
}

export function WallMoveOrthogonalController({ guid }: Props): ReactElement {
  const drawControllerRef = useRef<DrawControllerRef>(null)
  const wallLineRef = useRef<MovableLineRef>(null)
  const openingsGroupRef = useRef<Group>(null)

  const activeStorey = useEditModelStore(state => state.activeStorey)
  const editedWall = useEditModelStore(state => state.editedWall)
  const setEditedWall = useEditModelStore(state => state.setEditedWall)
  const setSelectedWall = useEditModelStore(state => state.setSelectedWall)

  const orthoSnap = useEditModelStore(state => state.snapOrthogonal)
  const additionalSnapTargets = useEditModelStore(state => state.additionalSnapTargets)

  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)
  const currentModelStoreyByGuid = useGeneratedModelStore(state => state.currentModelStoreyByGuid)

  const setIsMovingActive = useEditModelStore(state => state.setIsMovingActive)
  const isMovingActive = useEditModelStore(state => state.isMovingActive)

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const tapelineTargets = useTapelineSnapTargets()
  const tapelineCentersTargets = useTapLineCentersSnapTargets()

  const drawStart = useRef<ImmutableVector3 | null>(null)

  const rawWall = useMemo(
    () =>
      first(
        filter(
          (currentModelPlanar as PlanarModel).walls,
          wall => currentModelStoreyByGuid[wall.guid] === activeStorey && wall.guid === guid,
        ),
      ) as PlanarWall,
    [guid, currentModelPlanar, activeStorey, currentModelStoreyByGuid],
  )

  const wallPoints = useMemo(
    () => rawWall.shape.points.map(p => new ImmutableVector3(p.x, p.y, 0)),
    [rawWall.shape.points],
  )

  const movedWall = useMemo(
    () => editedWall || { start: wallPoints[0], end: wallPoints[1] },
    [editedWall, wallPoints],
  )

  const wallCenter = useMemo(() => getCenterMultiple([wallPoints[0], wallPoints[1]]), [wallPoints])

  const projectedOpenings = useMemo(
    () => rawWall.openings.map(opening => projectOpening(opening)),
    [rawWall],
  )

  const projectedWall = useMemo(() => projectPlanarWall(rawWall), [rawWall])

  const wallOrthogonalDirection = useMemo(() => {
    const projectedWallStart = projectedWall.points[0]
    const projectedWallEnd = projectedWall.points[1]

    const wallDirection = projectedWallStart.sub(projectedWallEnd).normalize()

    return wallDirection.cross(new ImmutableVector3(0, 0, 1)).normalize()
  }, [projectedWall])

  const projectedEditedWallStart = useMemo(
    () => new ImmutableVector3(movedWall?.start.x, movedWall?.start.y, 0),
    [movedWall],
  )

  const projectedEditedWallEnd = useMemo(
    () => new ImmutableVector3(movedWall?.end.x, movedWall?.end.y, 0),
    [movedWall],
  )

  // EFFECTS

  // edited wall will be the full wall length by default
  useEffect(() => {
    setEditedWall({
      start: new ImmutableVector3(wallPoints[0].x, wallPoints[0].y, 0),
      end: new ImmutableVector3(wallPoints[1].x, wallPoints[1].y, 0),
    })
    return () => setEditedWall(undefined)
  }, [wallPoints])

  useEffect(() => {
    openingsGroupRef.current?.position.set(0, 0, 0.1)
  }, [rawWall])

  useEffect(() => () => setIsMovingActive(false), [])

  // WALL EDIT EVENTS

  const onWallEditStart = () => {
    const state = drawControllerRef.current?.transientState

    if (state && state.drawPoint) drawStart.current = state.drawPoint

    setIsMovingActive(true)
  }

  const onWallEditEnd = () => {
    setIsMovingActive(false)
    if (wallLineRef.current) {
      const line = wallLineRef.current.getTransientLine()
      const start = new ImmutableVector3(line.start.x, line.start.y)
      const end = new ImmutableVector3(line.end.x, line.end.y)
      setEditedWall({ start, end })
      drawStart.current = null
    }
  }

  const onMouseMove = (transientDrawState: TransientDrawState) => {
    if (drawStart.current && transientDrawState.drawPoint) {
      const wallLine = wallLineRef.current?.getTransientLine() as ImmutableLine3

      const { start, end } = wallLine

      const drawStart2 = getCenterMultiple([start, end])

      const projectedStart = drawStart2.projectOnVector(wallOrthogonalDirection)
      const projectedEnd = transientDrawState.drawPoint.projectOnVector(wallOrthogonalDirection)
      const distance = projectedStart.distanceTo(projectedEnd)

      const drawDirection = transientDrawState.drawPoint.sub(drawStart2).normalize()

      const projected = drawDirection.projectOnVector(wallOrthogonalDirection)

      wallLineRef.current?.moveLine(projected, distance)

      if (openingsGroupRef.current) {
        console.log(drawDirection)
        const newPosition = openingsGroupRef.current.position
          .clone()
          .addScaledVector(drawDirection.v, distance)
        openingsGroupRef.current?.position.set(newPosition.x, newPosition.y, 0)
      }

      // we need to updated drawstart point as we use it as the new reference
      // point on the next iteration to calculate the distance moved
      drawStart.current = transientDrawState.drawPoint
    }
  }

  useKey('Escape', () => {
    setSelectedWall(undefined)
  })

  const key = useMemo(() => {
    return (
      projectedEditedWallStart.v.toArray().toString() +
      projectedEditedWallEnd.v.toArray().toString()
    )
  }, [projectedEditedWallStart, projectedEditedWallEnd])

  return (
    <>
      <DrawController
        enabled={!isTapelineActive}
        ref={drawControllerRef}
        key={guid}
        color={
          projectedWall?.placement === 'Internal'
            ? sceneColors.elements2d.internalWalls
            : sceneColors.elements2d.externalWalls
        }
        xyOnly
        enableIndicator={false}
        onMouseMove={onMouseMove}
        drawingAxis={{
          direction: wallOrthogonalDirection,
          origin: wallCenter,
        }}
        additionalSnapTargets={[
          ...(additionalSnapTargets || []),
          ...tapelineTargets,
          ...tapelineCentersTargets,
        ]}
        snapToCornersAndEdges={orthoSnap && isMovingActive}
        orthoSnap={orthoSnap && isMovingActive}
        indicatorType="crosshair"
      />

      <MovableLine
        ref={wallLineRef}
        key={key}
        line={{
          start: projectedEditedWallStart,
          end: projectedEditedWallEnd,
        }}
        lineName={guid}
        color={sceneColors.elements2d.internalWalls}
        hoverable={true}
        editable={!isTapelineActive}
        isSelected
        onMoveStart={onWallEditStart}
        onMoveStop={onWallEditEnd}
      />

      {/* slight elevation of 0.1 to put openings above the walls, different
        layer as openings should not be selectable */}
      <group
        ref={openingsGroupRef}
        position={[0, 0, 0.1]}
        layers={sceneConfig.R3FNonSelectableObjectLayer}
      >
        {projectedOpenings &&
          projectedOpenings.map(opening => (
            <Line
              key={opening.guid}
              points={opening.pointsV}
              color={sceneColors.elements2d.openings}
            />
          ))}
      </group>
    </>
  )
}
