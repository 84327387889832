import { KeyHandler } from 'hotkeys-js'

import { useEffect } from 'react'
import { useHotkeys, Options } from 'react-hotkeys-hook'

import { useHotkeysStore } from 'src/stores/hotkeysStore'

/**
 * this hook calls useHotkeys (exported by react-hotkeys-hook) internally and
 * will add the used hotkey to useHotkeysStore on mount and remove once the hook
 * is unmounted so the user is able to view all active hotkeys in HotkeysWidget
 * @param keys
 * @param description of the callback, will be added to useHotkeysStore and
 * visible in HotkeysWidget
 * @param callback
 * @param options
 * @param deps
 */
export default function useStoreHotkey({
  keys,
  description,
  callback,
  options,
  deps,
}: {
  keys: string
  description: string
  callback: KeyHandler
  options?: Options
  deps?: unknown[]
}) {
  useHotkeys(keys, callback, options, deps)

  const addHotkey = useHotkeysStore(state => state.addHotkey)
  const removeHotkey = useHotkeysStore(state => state.removeHotkey)

  useEffect(() => {
    const hotKey = {
      key: keys,
      description: description,
    }

    addHotkey(hotKey)

    return () => {
      removeHotkey(hotKey)
    }
  }, [])
}
