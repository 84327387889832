import { useSnackbar } from 'notistack'

import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams, useHistory } from 'react-router'

import { ArrowForward } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Stack, Typography } from '@mui/material'

import { MarqueeSelectionController } from '@modugen/scene/lib/controllers/MarqueeSelectionController'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'

import { CustomSidebar } from 'src/components/generic/CustomSidebar'
import HotKeyButton from 'src/components/generic/HotKeyButton'
import { HeaderPortalLeft } from 'src/components/global/HeaderBar'
import { config } from 'src/config/config'
import { ActiveFilters } from 'src/pages/IfcImporter/components/AssignmentsAndFilters/ActiveFilters'
import { useHotkeysStore } from 'src/stores/hotkeysStore'
import { postRequest } from 'src/utils/requests'

import { ByTypeSidebar } from '../../components/ByTypeSidebar'
import { MarqueeSelectionControls } from '../../components/controls/MarqueeSelectionControls'
import { useShowOpenings } from '../../controllers/OpeningsControllers/openingsStore'
import { useDerivedIfcData } from '../../hooks/useDerivedIfcData'
import { useDerivedIfcDataStore } from '../../stores/derivedIfcDataStore'
import { useFiltersStore } from '../../stores/filtersStore'
import { useIfcElementsStore } from '../../stores/ifcElementsStore'
import { useIssuesStore } from '../../stores/issuesStore'
import {
  useTypeVisibilityStoreByInstance,
  getHiddenIdsFromTypes,
} from '../../stores/typeVisibilityStore'

const viewName = 'filter-view'

export function SidebarLeft(): ReactElement {
  return <ByTypeSidebar viewName={viewName} />
}

export function Scene(): ReactElement {
  const { ifcIdsByType, mergedFilteredIds } = useDerivedIfcData()
  const trueIfcGroups = useDerivedIfcDataStore(state => state.trueIfcGroups)
  const setHiddenIds = useIfcElementsStore(state => state.setHiddenIdsGltfModel)

  const hotKeyModalOpen = useHotkeysStore(state => state.hotKeyModalOpen)

  const exceptionModalOpen = useIssuesStore(state => state.exceptionModalOpen)

  useShowOpenings(!exceptionModalOpen && !hotKeyModalOpen)

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const visibilityByType = useTypeVisibilityStoreByInstance(
    'gltf',
    viewName,
  )(state => state.visibilityByType)

  useEffect(() => {
    const hiddenIdsFromTypesGltfModel = getHiddenIdsFromTypes(
      ifcIdsByType,
      visibilityByType,
      trueIfcGroups,
    )

    setHiddenIds(new Set([...hiddenIdsFromTypesGltfModel, ...mergedFilteredIds]))
  }, [ifcIdsByType, visibilityByType, mergedFilteredIds])

  return <>{!isTapelineActive && <MarqueeSelectionController />}</>
}

export function SidebarRight(): ReactElement {
  const { t } = useTranslation(['common', 'step3Filter', 'ifcImporter'])
  const { projectId } = useParams<{ projectId: string }>()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const { mergedSelectedIds } = useDerivedIfcData()
  const deselectAllIds = useIfcElementsStore(state => state.deselectAllIds)

  const filters = useFiltersStore(state => state.filters)
  const addFilter = useFiltersStore(state => state.addFilter)
  const removeFilter = useFiltersStore(state => state.removeFilter)

  useEffect(() => {
    const unlisten = history.listen(deselectAllIds)
    return unlisten
  }, [])

  const saveFilterMutation = useMutation(
    () =>
      postRequest({
        url: config.apiRoutes.getPostFilterDefinitions(projectId),
        data: filters,
      }),
    {
      onSuccess: () => {
        history.push(`/ifc-importer/${projectId}/step-4-confirm`)
      },
      onError: () => {
        enqueueSnackbar(t('step3Filter:errors.saveFilters'), { variant: 'error' })
      },
    },
  )

  return (
    <CustomSidebar
      title={t('step3Filter:actions.cleanseModel')}
      bottom={
        <LoadingButton
          onClick={() => saveFilterMutation.mutate()}
          loading={saveFilterMutation.isLoading}
          startIcon={<ArrowForward />}
          variant="contained"
          fullWidth
        >
          {t('common:actions.confirmAndContinue')}
        </LoadingButton>
      }
    >
      <Stack spacing={2}>
        <Stack spacing={1} p={1} border={1} borderColor="grey.200" borderRadius={1}>
          <Typography variant="h6" textAlign="center">
            {t('common:properties.chosen')}: {mergedSelectedIds.size}
          </Typography>

          <Stack direction="row" spacing={1}>
            <HotKeyButton
              onClick={() => {
                addFilter([...mergedSelectedIds])
                deselectAllIds()
              }}
              disabled={!mergedSelectedIds.size}
              size="small"
              variant="contained"
              fullWidth
              hotkeys="del"
              hotkeyDescription={t('step3Filter:hotkeys.filterSelectedElements')}
              onHotkey={() => {
                addFilter([...mergedSelectedIds])
                deselectAllIds()
              }}
              hotkeysDeps={[mergedSelectedIds]}
            >
              {t('step3Filter:actions.removeElements')}
            </HotKeyButton>

            <HotKeyButton
              onClick={deselectAllIds}
              disabled={!mergedSelectedIds.size}
              size="small"
              variant="outlined"
              fullWidth
              hotkeys="q"
              onHotkey={deselectAllIds}
              hotkeyDescription={t('ifcImporter:hotkeys.deselectElements')}
            >
              {t('common:actions.deselectElements')}
            </HotKeyButton>
          </Stack>
        </Stack>

        {!!filters?.length && (
          <div>
            <Typography mb={1}>{t('step3Filter:labels.existingFilters')}:</Typography>
            <ActiveFilters filters={filters} removeFilter={removeFilter} />
          </div>
        )}
      </Stack>

      <HeaderPortalLeft>
        <MarqueeSelectionControls />
      </HeaderPortalLeft>
    </CustomSidebar>
  )
}
