import { find } from 'lodash-es'

import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import { HighlightableRectangle } from '@modugen/scene/lib/components/HighlightableRectangle/HighlightableRectangle'
import { RectangleXYZ } from '@modugen/scene/lib/types'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import sceneColors from 'src/styles/sceneColors'

import { useEditModelStore } from '../../stores/editModelStore'
import { useGeneratedModelStore } from '../GeneratedModelController/generatedModelStore'
import { useGltfModelStore } from '../GltfModelController/gltfModelStore'

export enum OpeningElements {
  Content = 'content',
  Border = 'border',
}

interface Props {
  wallGuid: string
}

export function SelectOpeningController({ wallGuid }: Props): ReactElement {
  const selectedOpening = useEditModelStore(state => state.selectedOpening)
  const setSelectedOpening = useEditModelStore(state => state.setSelectedOpening)
  const setDrawnOpening = useEditModelStore(state => state.setDrawnOpening)
  const isDrawingOpeningActive = useEditModelStore(state => state.isDrawingOpeningActive)

  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)

  const setGltfModelNonSelectable = useGltfModelStore(state => state.setGltfModelNonSelectable)

  const [hoveredOpening, setHoveredOpening] = useState<string | undefined>()

  const wall = useMemo(
    () => find(currentModelPlanar?.walls, { guid: wallGuid }),
    [currentModelPlanar],
  )

  const openingsInSelectedWall = useMemo(
    () =>
      wall &&
      Object.fromEntries(
        wall.openings.map(opening => {
          return [opening.guid, opening.shape.points]
        }),
      ),
    [currentModelPlanar, wallGuid],
  )

  useEffect(() => {
    setGltfModelNonSelectable(true)
    return () => setGltfModelNonSelectable(false)
  }, [])

  return (
    <>
      {openingsInSelectedWall &&
        Object.entries(openingsInSelectedWall).map(([openingGuid, shape]) => (
          <HighlightableRectangle
            key={openingGuid}
            color={sceneColors.elements3d.openings}
            points={shape.map(p => new ImmutableVector3(p.x, p.y, p.z)) as RectangleXYZ}
            name={OpeningElements.Content}
            boundingName={OpeningElements.Border}
            onClick={() => {
              setSelectedOpening(openingGuid)
              setDrawnOpening(undefined)
            }}
            hoverable={!isDrawingOpeningActive}
            onHover={hovered => setHoveredOpening(hovered ? openingGuid : undefined)}
            outlines={hoveredOpening === openingGuid || selectedOpening === openingGuid}
            boundingColor={sceneColors.elements3d.openings}
            boundingWidth={2}
          />
        ))}
    </>
  )
}
