import React, { useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Cameraswitch } from '@mui/icons-material/'
import { Stack, Tooltip } from '@mui/material'

import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'

import { HeaderToggleButton } from 'src/components/generic/HeaderToggleButtonGroup'

import { useGeneratedModelStore } from '../../controllers/GeneratedModelController/generatedModelStore'
import { useEditModelStore } from '../../stores/editModelStore'

export function CameraControls(): ReactElement {
  const { t } = useTranslation('ifcImporter')

  const isOrthographic = useCameraStore(state => state.isOrthographic)
  const setIsOrthographic = useCameraStore(state => state.setIsOrthographic)

  const setOutlinesColor = useGeneratedModelStore(state => state.setOutlinesColor)

  const activeDrawingStorey = useEditModelStore(state => state.activeStorey)
  useEffect(() => setIsOrthographic(!!activeDrawingStorey), [activeDrawingStorey])

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title={t('camera.perspectiveSwitch')}>
        <HeaderToggleButton
          value="snap"
          size="small"
          onClick={() => {
            setIsOrthographic(!isOrthographic)
            setOutlinesColor(!isOrthographic ? 'dark' : 'light')
          }}
          selected={isOrthographic && !activeDrawingStorey}
          disabled={!!activeDrawingStorey}
        >
          <Cameraswitch fontSize="small" />
        </HeaderToggleButton>
      </Tooltip>
    </Stack>
  )
}
