import { useSnackbar } from 'notistack'

import React, { ReactElement, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router'

import { ArrowForward } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Stack, Button, Typography, TextField, MenuItem, FormControl } from '@mui/material'

import { MarqueeSelectionController } from '@modugen/scene/lib/controllers/MarqueeSelectionController'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'

import { CustomSidebar } from 'src/components/generic/CustomSidebar'
import HotKeyButton from 'src/components/generic/HotKeyButton'
import { HeaderPortalLeft } from 'src/components/global/HeaderBar'
import { config } from 'src/config/config'
import { directIfcExports } from 'src/config/exportConfig'
import { postRequest } from 'src/utils/requests'

import { ActiveAssignments } from '../../components/AssignmentsAndFilters/ActiveAssignments'
import { ByTypeSidebar } from '../../components/ByTypeSidebar'
import { MarqueeSelectionControls } from '../../components/controls/MarqueeSelectionControls'
import { useShowOpenings } from '../../controllers/OpeningsControllers/openingsStore'
import { useDerivedIfcData } from '../../hooks/useDerivedIfcData'
import { useAssignmentsStore } from '../../stores/assignmentsStore'
import { useDerivedIfcDataStore } from '../../stores/derivedIfcDataStore'
import { useIfcElementsStore } from '../../stores/ifcElementsStore'
import {
  useTypeVisibilityStoreByInstance,
  getHiddenIdsFromTypes,
} from '../../stores/typeVisibilityStore'
import { ElementTypes, IfcElementAssignments } from '../../types'

const viewName = 'assignment-view'

export function SidebarLeft(): ReactElement {
  return <ByTypeSidebar viewName={viewName} />
}

export function Scene(): ReactElement {
  const { ifcIdsByType } = useDerivedIfcData()
  const trueIfcGroups = useDerivedIfcDataStore(state => state.trueIfcGroups)
  const setHiddenIds = useIfcElementsStore(state => state.setHiddenIdsGltfModel)
  useShowOpenings(false)

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const visibilityByType = useTypeVisibilityStoreByInstance(
    'gltf',
    viewName,
  )(state => state.visibilityByType)

  useEffect(() => {
    setHiddenIds(new Set(getHiddenIdsFromTypes(ifcIdsByType, visibilityByType, trueIfcGroups)))
  }, [ifcIdsByType, visibilityByType])

  return <>{!isTapelineActive && <MarqueeSelectionController />}</>
}

export function SidebarRight(): ReactElement {
  const { t } = useTranslation(['common', 'step2Assignments', 'ifcImporter'])

  const { projectId } = useParams<{ projectId: string }>()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const { mergedSelectedIds } = useDerivedIfcData()
  const deselectAllIds = useIfcElementsStore(state => state.deselectAllIds)

  const assignments = useAssignmentsStore(state => state.assignments)
  const addAssignment = useAssignmentsStore(state => state.addAssignment)
  const removeLastAssignment = useAssignmentsStore(state => state.removeLastAssignment)

  const [selectedType, setSelectedType] = useState<string | undefined>(directIfcExports[0])

  const assignmentMutation = useMutation(
    () =>
      postRequest({
        url: config.apiRoutes.getPostAssignmentDefinitions(projectId),
        data: assignments,
      }),
    {
      onSuccess: () => history.push(`/ifc-importer/${projectId}/step-3-filter`),
      onError: () => {
        enqueueSnackbar(t('step2Assignments:errors.saveAssignments'), { variant: 'error' })
      },
    },
  )

  useEffect(() => {
    return deselectAllIds
  }, [])

  return (
    <CustomSidebar
      title={t('step2Assignments:labels.assignConstructionParts')}
      bottom={
        <LoadingButton
          onClick={() => assignmentMutation.mutate()}
          loading={assignmentMutation.isLoading}
          startIcon={<ArrowForward />}
          variant="contained"
          fullWidth
        >
          {t('common:actions.confirmAndContinue')}
        </LoadingButton>
      }
    >
      <Stack spacing={2}>
        <Stack spacing={1} p={1} border={1} borderColor="grey.200" borderRadius={1}>
          <Typography variant="h6" textAlign="center">
            {t('common:properties.chosen')}: {mergedSelectedIds.size}
          </Typography>

          <Typography>{t('step2Assignments:hints.chooseIfcGroup')}:</Typography>

          <FormControl>
            <TextField
              select
              label={t('common:terms.ifcType')}
              value={selectedType}
              onChange={event => setSelectedType(event.target.value as string)}
              disabled={!mergedSelectedIds.size}
              size="small"
              sx={{ mt: 1, bgcolor: 'grey.50' }}
            >
              {directIfcExports.map((type, index) => (
                <MenuItem value={type} key={index}>
                  {t(`common:terms.ifcElementTypes.${type as ElementTypes}`)}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <Stack direction="row" spacing={1}>
            <Button
              onClick={() => {
                if (!selectedType) return

                const newAssignments: IfcElementAssignments = [...mergedSelectedIds].map(id => ({
                  element_guid: id,
                  element_type: selectedType as ElementTypes,
                }))

                addAssignment(newAssignments)
                deselectAllIds()
              }}
              disabled={!mergedSelectedIds.size}
              size="small"
              variant="contained"
              fullWidth
            >
              {t('step2Assignments:terms.assign')}
            </Button>

            <HotKeyButton
              onClick={deselectAllIds}
              disabled={!mergedSelectedIds.size}
              size="small"
              variant="outlined"
              fullWidth
              hotkeys="q"
              onHotkey={deselectAllIds}
              hotkeyDescription={t('ifcImporter:hotkeys.deselectElements')}
            >
              {t('common:actions.deselectElements')}
            </HotKeyButton>
          </Stack>
        </Stack>

        {!!assignments.length && (
          <div>
            <Typography mb={1}>{t('step2Assignments:labels.existingAssignments')}:</Typography>
            <ActiveAssignments assignments={assignments} removeAssignment={removeLastAssignment} />
          </div>
        )}
      </Stack>

      <HeaderPortalLeft>
        <MarqueeSelectionControls />
      </HeaderPortalLeft>
    </CustomSidebar>
  )
}
