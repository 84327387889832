import React from 'react'
import { useTranslation } from 'react-i18next'

import { Divider, Stack, Switch, Typography } from '@mui/material'

import { directIfcExports } from 'src/config/exportConfig'
import { capitalizeFirstLetter } from 'src/utils/misc'

import { ElementTypes } from '../../../types'

export function VisibilitySwitches(props: {
  modelVisible: boolean
  onChangeModelVisibility: (visibility: boolean) => void

  modelTranslucent: boolean
  setModelTranslucent: (opaque: boolean) => void

  typeVisibility: Record<ElementTypes, boolean>
  onChangeTypeVisibility: (type: ElementTypes, visible: boolean) => void
}) {
  const { t } = useTranslation('common')
  return (
    <>
      <Stack direction="row" alignItems="center">
        <Switch
          checked={props.modelVisible}
          onChange={event => props.onChangeModelVisibility(event.target.checked)}
          size="small"
        />
        <Typography sx={{ p: 1 }}>{t('terms.model')}</Typography>
      </Stack>

      <Divider sx={{ my: 0.5 }} />

      <Stack direction="row" alignItems="center">
        <Switch
          checked={props.modelTranslucent}
          onChange={event => props.setModelTranslucent(event.target.checked)}
          size="small"
        />
        <Typography sx={{ p: 1 }}>{t('terms.transparent')}</Typography>
      </Stack>

      <Divider sx={{ my: 0.5 }} />

      {directIfcExports.map(type => (
        <Stack key={type} direction="row" alignItems="center">
          <Switch
            checked={props.typeVisibility[type as ElementTypes]}
            onChange={event =>
              props.onChangeTypeVisibility(type as ElementTypes, event.target.checked)
            }
            size="small"
          />
          <Typography sx={{ p: 1 }}>{capitalizeFirstLetter(type)}</Typography>
        </Stack>
      ))}
    </>
  )
}
