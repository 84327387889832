import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography, Stack } from '@mui/material'

import { CustomSidebar } from 'src/components/generic/CustomSidebar'
import { directIfcExports, indirectIfcExports } from 'src/config/exportConfig'
import sceneColors from 'src/styles/sceneColors'

import { useOnClickGltfModel } from '../../controllers/GltfModelController/useOnClickGltfModel'
import { useDerivedIfcData } from '../../hooks/useDerivedIfcData'
import useSelectElement from '../../hooks/useSelectElement'
import { useHiddenElementsStore } from '../../stores/hiddenElementsIdsStore'
import { useIfcElementsStore } from '../../stores/ifcElementsStore'
import { useTypeVisibilityStoreByInstance } from '../../stores/typeVisibilityStore'
import { IfcElementsDetails } from './IfcElementsDetails'
import { IfcTypeItem } from './IfcTypeItem'

export function ByTypeSidebar({ viewName }: { viewName: string }): ReactElement {
  const { t } = useTranslation(['common', 'ifcImporter'])
  const { setTypeVisibility, visibilityByType } = useTypeVisibilityStoreByInstance(
    'gltf',
    viewName,
  )()

  const isElementVisibilityMode = useHiddenElementsStore(state => state.isElementVisibilityMode)

  const { ifcIdsByType, mergedSelectedIds, reassignedIfcElements } = useDerivedIfcData()

  // no state picking as we use almost all store methods and items
  const { allIfcIds, selectGroupIds, selectStandAloneIds } = useIfcElementsStore()

  const selectElement = useSelectElement()

  useOnClickGltfModel(
    event => {
      if (!allIfcIds.size || isElementVisibilityMode) return

      const ifcElementId = event.object.name

      if (!ifcElementId) return

      selectElement(ifcElementId)
    },
    [mergedSelectedIds, isElementVisibilityMode],
  )

  // TODO: consider turning reassignedIfcElements into a Dict / Map to
  // increase the lookup speed
  const selectedIfcElements = reassignedIfcElements?.filter(ifcElement =>
    mergedSelectedIds.has(ifcElement.PSET_data.id),
  )

  const importedIfcTypes = ifcIdsByType.filter(idsByType =>
    directIfcExports.includes(idsByType.type),
  )

  const notImportedIfcTypes = ifcIdsByType.filter(
    idsByType =>
      !directIfcExports.includes(idsByType.type) && !indirectIfcExports.includes(idsByType.type),
  )

  return (
    <CustomSidebar title={t('ifcImporter:byTypeSidebar.ifcTypes')}>
      <Stack spacing={2}>
        {!!importedIfcTypes.length && (
          <Stack spacing={0.5}>
            <Typography mb={0.5}>
              {' '}
              {t('ifcImporter:byTypeSidebar.ifcTypesImportedLabel')}:
            </Typography>

            {importedIfcTypes.map(idsByType => {
              return (
                <IfcTypeItem
                  key={idsByType.type}
                  title={t(`common:terms.ifcElementTypes.${idsByType.type}`) as string}
                  visible={!!visibilityByType[idsByType.type]}
                  onToggleVisibility={() =>
                    setTypeVisibility(idsByType.type, !visibilityByType[idsByType.type])
                  }
                  // TODO: it's currently possible to select hidden elements
                  // through the type selection. implement a fix but be aware
                  // that the set of hidden elements can change per view
                  onSelectType={() => {
                    selectGroupIds(idsByType.groupIds)
                    selectStandAloneIds(idsByType.standAloneIds)
                  }}
                  active
                  color={sceneColors.elements3d[idsByType.type]}
                />
              )
            })}

            <Typography pt={0.5} fontStyle="italic" color="grey.500">
              <strong>{t('common:terms.hint')}:</strong>{' '}
              {t('ifcImporter:byTypeSidebar.ifcOpeningsHint')}
            </Typography>
          </Stack>
        )}

        {!!notImportedIfcTypes.length && (
          <Stack spacing={0.5}>
            <Typography mb={0.5}>
              {' '}
              {t('ifcImporter:byTypeSidebar.ifcGroupsNotImported')}:
            </Typography>

            {notImportedIfcTypes.map(idsByType => {
              return (
                <IfcTypeItem
                  key={idsByType.type}
                  title={idsByType.type}
                  visible={!!visibilityByType[idsByType.type]}
                  onToggleVisibility={() =>
                    setTypeVisibility(idsByType.type, !visibilityByType[idsByType.type])
                  }
                  onSelectType={() => {
                    selectGroupIds(idsByType.groupIds)
                    selectStandAloneIds(idsByType.standAloneIds)
                  }}
                />
              )
            })}
          </Stack>
        )}

        {!!selectedIfcElements?.length && <IfcElementsDetails elements={selectedIfcElements} />}
      </Stack>
    </CustomSidebar>
  )
}
