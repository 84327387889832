import { ProjectState } from '../types'

// this function does not return the allowed routes per state but the default
// route to redirect to in case a redirect is required (as for some states
// multiple routes are allowed)
export function getRedirectForProjectState(projectState: ProjectState, projectId: string): string {
  if (projectState.isAligned) {
    return `/ifc-importer/${projectId}/step-5-arch`
  }

  if (projectState.hasModel && projectState.hasFilters) {
    return `/ifc-importer/${projectId}/step-4-confirm`
  }

  if (projectState.hasModel && !projectState.isInitialUpload) {
    return `/ifc-importer/${projectId}/step-2-assignment`
  }

  if (projectState.isInitialUpload) {
    return `/ifc-importer/${projectId}/step-1-upload`
  }

  return `/ifc-importer/${projectId}`
}
