import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useStoreHotkey from 'src/hooks/useStoreHotkey'

import { IfcElementAssignments } from '../../types'
import { AFAccordion, AFContentLine, AFDeleteButton } from './AFElements'

export function ActiveAssignments(props: {
  assignments: IfcElementAssignments[]
  removeAssignment: () => void
}): ReactElement {
  const { t } = useTranslation(['common', 'step2Assignments', 'ifcImporter'])
  const [activePanel, setActivePanel] = useState<number | undefined>(props.assignments.length - 1)

  // always open the last element in case a new filter gets added or removed
  useEffect(() => {
    setActivePanel(props.assignments.length - 1)
  }, [props.assignments])

  useStoreHotkey({
    keys: 'd',
    description: t('step2Assignments:hotkeys.deleteLastAssignment'),
    callback: () => {
      props.removeAssignment()
    },
    deps: [props.assignments, props.removeAssignment],
  })

  return (
    <>
      {props.assignments.map((assignment, index) => (
        <AFAccordion
          title={`${t('step2Assignments:terms.assignment')} ${index + 1}`}
          expanded={activePanel === index}
          onChange={() => setActivePanel(index === activePanel ? undefined : index)}
          key={index}
        >
          <AFContentLine
            title={`${t('common:terms.toType')}:`}
            value={t(`common:terms.ifcElementTypes.${assignment[0].element_type}`) as string}
          />
          {index === props.assignments.length - 1 && (
            <AFDeleteButton
              onDelete={props.removeAssignment}
              label={t('step2Assignments:actions.removeAssignments') + ' (d)'}
            />
          )}
        </AFAccordion>
      ))}
    </>
  )
}
