export default {
  selection: '#eb4034',
  outlines: '#ffffff',
  outlinesDark: '#000000',

  elements3d: {
    walls: '#98a2ab',
    wallsExternal: '#7e8182',
    openings: '#3777ff',
    slabs: '#a6d0ff',
    roofs: '#340068',
    roof_slabs: '#340068',
    beams: '#f5c21b',
    columns: '#a5be00',
  },

  elements2d: {
    externalWalls: '#222',
    internalWalls: '#888',
    openings: '#ccc',
  },

  openings: {
    selected: '#16de52',
    removed: '#f03535',
  },

  issues: {
    error: '#f44336',
    warning: '#ffc400',
  },
}
