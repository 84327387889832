import { SnackbarKey, useSnackbar } from 'notistack'

import React, { ReactElement, useEffect, useRef, useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router'

import { Check, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Typography,
  Stack,
  CircularProgress,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'

import { AbortionPrompt, useUnblockHistory } from 'src/components/generic/AbortionPrompt'
import { CustomSidebar } from 'src/components/generic/CustomSidebar'
import { MuiBox } from 'src/components/generic/MuiBox'
import { directIfcExports } from 'src/config/exportConfig'
import sceneColors from 'src/styles/sceneColors'
import { theme } from 'src/styles/theme'

import Checklist from '../../../../components/generic/Checklist'
import { IfcTypeItem } from '../../components/ByTypeSidebar/IfcTypeItem'
import { useGltfModelStore } from '../../controllers/GltfModelController/gltfModelStore'
import { useShowOpenings } from '../../controllers/OpeningsControllers/openingsStore'
import { useDerivedIfcData } from '../../hooks/useDerivedIfcData'
import { setCurrentModel } from '../../queries/getAndSetCurrentModel'
import { getParseAndOrientModel } from '../../queries/getParseAndOrientModel'
import { saveFilterOutput } from '../../queries/saveFilterOutput'
import { useDerivedIfcDataStore } from '../../stores/derivedIfcDataStore'
import { useIfcElementsStore } from '../../stores/ifcElementsStore'
import { useIssuesStore } from '../../stores/issuesStore'
import { ProcessingSteps, useParseAndOrientModelStore } from '../../stores/parseAndOrientModelStore'
import {
  getHiddenIdsFromTypes,
  useTypeVisibilityStoreByInstance,
} from '../../stores/typeVisibilityStore'

const viewName = 'confirmation-view'

export function SidebarLeft(): ReactElement {
  const { t } = useTranslation(['step4Confirm', 'ifcImporter', 'common'])
  const { ifcIdsByType } = useDerivedIfcData()
  const { setTypeVisibility, visibilityByType } = useTypeVisibilityStoreByInstance(
    'gltf',
    viewName,
  )()

  return (
    <CustomSidebar title={t('ifcImporter:byTypeSidebar.ifcTypes')}>
      <Stack spacing={0.5}>
        <Typography mb={0.5}>{t('ifcImporter:byTypeSidebar.ifcTypesImportedLabel')}:</Typography>

        {ifcIdsByType
          .filter(idsByType => directIfcExports.includes(idsByType.type))
          .map(idsByType => {
            return (
              <IfcTypeItem
                key={idsByType.type}
                title={t(`common:terms.ifcElementTypes.${idsByType.type}`) as string}
                visible={!!visibilityByType[idsByType.type]}
                onToggleVisibility={() =>
                  setTypeVisibility(idsByType.type, !visibilityByType[idsByType.type])
                }
                active
                color={sceneColors.elements3d[idsByType.type]}
              />
            )
          })}

        <Typography pt={0.5} fontStyle="italic" color="grey.500">
          <strong>{t('common:terms.hint')}:</strong>{' '}
          {t('ifcImporter:byTypeSidebar.ifcOpeningsHint')}
        </Typography>
      </Stack>
    </CustomSidebar>
  )
}

export function Scene(): ReactElement | null {
  const { ifcIdsByType, notExportedIds } = useDerivedIfcData()
  const setHiddenIds = useIfcElementsStore(state => state.setHiddenIdsGltfModel)

  const exceptionModalOpen = useIssuesStore(state => state.exceptionModalOpen)
  useShowOpenings(!exceptionModalOpen)

  const trueIfcGroups = useDerivedIfcDataStore(state => state.trueIfcGroups)
  const mergedFilteredIds = useDerivedIfcDataStore(state => state.mergedFilteredIds)

  const visibilityByType = useTypeVisibilityStoreByInstance(
    'gltf',
    viewName,
  )(state => state.visibilityByType)

  useEffect(() => {
    const hiddenIdsFromTypesGltf = getHiddenIdsFromTypes(
      ifcIdsByType,
      visibilityByType,
      trueIfcGroups,
    )

    setHiddenIds(new Set([...hiddenIdsFromTypesGltf, ...notExportedIds, ...mergedFilteredIds]))
  }, [ifcIdsByType, visibilityByType, notExportedIds])

  return null
}

export function SidebarRight(): ReactElement {
  const { t } = useTranslation(['common', 'step4Confirm', 'ifcImporter'])
  const history = useHistory()
  const unblockHistory = useUnblockHistory()
  const controllerRef = useRef(new AbortController())
  const { projectId } = useParams<{ projectId: string }>()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { ifcIdsByType } = useDerivedIfcData()
  const ifcOpenings = useIfcElementsStore(state => state.ifcOpenings)

  const clearProcessing = useParseAndOrientModelStore(state => state.clear)
  const currentProcessedStep = useParseAndOrientModelStore(state => state.currentProcessingStep)
  const processedSteps = useParseAndOrientModelStore(state => state.processedSteps)

  const setCoordinateSystem = useGltfModelStore(state => state.setCoordinateSystem)

  const trueIfcGroups = useDerivedIfcDataStore(state => state.trueIfcGroups)
  const mergedFilteredIds = useDerivedIfcDataStore(state => state.mergedFilteredIds)

  const clearIssues = useIssuesStore(state => state.clear)
  const setSummary = useIssuesStore(state => state.setSummary)
  const setExceptionModalOpen = useIssuesStore(state => state.setExceptionModalOpen)

  const Action = (props: { actionId: SnackbarKey }): ReactElement => (
    <Fragment>
      <Button
        onClick={() => {
          setExceptionModalOpen(true)
          closeSnackbar(props.actionId)
        }}
        variant="text"
        sx={{
          color: theme.palette.common.white,
        }}
        size="small"
      >
        {t('ifcImporter:issuesModal.openIssues')}
      </Button>
      <Button
        onClick={() => {
          closeSnackbar(props.actionId)
        }}
        variant="text"
        sx={{
          color: theme.palette.common.white,
        }}
        size="small"
      >
        {t('common:actions.close')}
      </Button>
    </Fragment>
  )

  const saveFiltersAndParseModelMutation = useMutation(
    async () => {
      clearIssues()

      await saveFilterOutput(projectId, ifcIdsByType, mergedFilteredIds, ifcOpenings, trueIfcGroups)

      return await getParseAndOrientModel(projectId, controllerRef.current.signal)
    },
    {
      onSuccess: result => {
        if (result.coordinateSystem) {
          setCoordinateSystem(result.coordinateSystem)
        }

        setSummary(result.taskSummary.errors || [], result.taskSummary.warnings || [])

        if (result.taskSummary.warnings && result.taskSummary.warnings.length) {
          enqueueSnackbar(t('ifcImporter:issuesModal.warningsOccured'), {
            variant: 'warning',
            persist: true,
            action: (actionId: SnackbarKey) => <Action actionId={actionId} />,
          })
        }

        if (result.taskSummary.errors && result.taskSummary.errors.length) {
          enqueueSnackbar(t('ifcImporter:issuesModal.errorsOccured'), {
            variant: 'error',
            persist: true,
            action: (actionId: SnackbarKey) => <Action actionId={actionId} />,
          })
        } else {
          if (result.planarModel) {
            setCurrentModel(result.planarModel)
          }

          unblockHistory()
          history.push(`/ifc-importer/${projectId}/step-5-arch`)
        }
      },
      onError: () => {
        enqueueSnackbar(t('step4Confirm:errors.transformationsApplication'), {
          variant: 'error',
          persist: true,
        })
      },
    },
  )

  const [allHintsAccepted, setAllHintsAccepted] = useState(false)

  return (
    <CustomSidebar
      title={t('step4Confirm:hints.confirmExport')}
      bottom={
        <LoadingButton
          onClick={() => saveFiltersAndParseModelMutation.mutate()}
          loading={saveFiltersAndParseModelMutation.isLoading}
          startIcon={<Save />}
          variant="contained"
          fullWidth
          disabled={!allHintsAccepted}
        >
          {t('common:actions.saveAndContinue')}
        </LoadingButton>
      }
    >
      <MuiBox
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <div>
          {!saveFiltersAndParseModelMutation.isLoading && (
            <>
              <Typography variant="h5" textAlign="center">
                Tips & Tricks
              </Typography>

              <List>
                <ListItem
                  component="a"
                  href={'https://www.loom.com/share/b4818dac1ed04da7b64de7b75dddd609'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemText primary="Roof Hack" />
                </ListItem>
              </List>

              <Typography variant="h5" textAlign="center">
                Checkliste
              </Typography>
              <Checklist
                onCheckItem={setAllHintsAccepted}
                checkboxItems={[
                  t('step4Confirm:hints.removedGroundPlates'),
                  t('step4Confirm:hints.removedFrontWalls'),
                  t('step4Confirm:hints.removedAtticasAndBalustrades'),
                  t('step4Confirm:hints.appliedRoofHack'),
                ]}
                sx={{ mb: 1 }}
              />
            </>
          )}
        </div>

        {saveFiltersAndParseModelMutation.isLoading && (
          <div>
            <Typography variant="h5" pt={2} pb={2} m={0}>
              {t('ifcImporter:parsingModal.processParsing')}
            </Typography>

            <MuiBox>
              {(Object.keys(ProcessingSteps) as Array<keyof typeof ProcessingSteps>).map(
                (step, index) => (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    style={{ minWidth: 300 }}
                    key={index}
                  >
                    <Typography>
                      <strong>{index + 1}.</strong>
                      {step === 'ParseModel' && ' ' + t('ifcImporter:parsingModal.parsingModel')}
                      {step === 'CalculateOrientation' &&
                        ' ' + t('ifcImporter:parsingModal.alignWalls')}
                      {step === 'VerticalAlignment' &&
                        ' ' + t('ifcImporter:parsingModal.verticalAlignment')}
                      {step === 'GetOrientationResult' &&
                        ' ' + t('ifcImporter:parsingModal.loadOrientedModel')}
                    </Typography>
                    {currentProcessedStep === ProcessingSteps[step] && (
                      <CircularProgress size={20} />
                    )}

                    {processedSteps.includes(ProcessingSteps[step]) && <Check />}
                  </Stack>
                ),
              )}
            </MuiBox>
          </div>
        )}
      </MuiBox>

      <AbortionPrompt
        blocked={saveFiltersAndParseModelMutation.isLoading}
        onLeave={() => {
          clearProcessing()
          controllerRef.current.abort()
        }}
      />
    </CustomSidebar>
  )
}
