import { config } from 'src/config/config'
import { directIfcExports } from 'src/config/exportConfig'
import { postRequest } from 'src/utils/requests'

import { IfcGroups, IfcIdsByType, IfcOpenings } from '../types'

export async function saveFilterOutput(
  projectId: string,
  ifcIdsByType: IfcIdsByType[],
  mergedFilteredIds: Set<string>,
  ifcOpenings: IfcOpenings | null,
  trueIfcGroups: IfcGroups,
): Promise<void> {
  const exportedOpenings: { [ifcId: string]: string[] } = {}
  // compose the ifc ids exported to the backend by collecting the element ids
  // that belong to an exported type as well as collecting all opening ids that
  // belong to one of the exported element ids
  const data = ifcIdsByType
    .filter(idsByType => directIfcExports.includes(idsByType.type))
    .reduce((collector, idsByType) => {
      const exportedIds = {} as { [ifcId: string]: string[] }

      idsByType.standAloneIds
        .filter(id => !mergedFilteredIds.has(id))
        .forEach(id => {
          // export the standalone element id as an array containing itself as
          // requested by the backend
          exportedIds[id] = [id]

          // check if element has unremoved openings, export them if yes
          if (ifcOpenings?.[id]) {
            const openingsToExport = ifcOpenings[id].filter(id => !mergedFilteredIds.has(id))
            if (openingsToExport.length) exportedOpenings[id] = openingsToExport
          }
        })

      idsByType.groupIds
        .filter(id => !mergedFilteredIds.has(id))
        .forEach(id => {
          // export the group id as an array containing its children
          exportedIds[id] = trueIfcGroups[id].filter(id => !mergedFilteredIds.has(id))

          // check if group has unremoved openings, export them if yes
          if (ifcOpenings?.[id]) {
            const openingsToExport = ifcOpenings[id].filter(id => !mergedFilteredIds.has(id))
            if (openingsToExport.length) exportedOpenings[id] = openingsToExport
          }

          exportedIds[id].forEach(childId => {
            // check if group children have unremoved openings, export them
            // under id of the group parent element if yes
            if (ifcOpenings?.[childId]) {
              const openingsToExport = ifcOpenings[childId].filter(id => !mergedFilteredIds.has(id))
              if (openingsToExport.length) exportedOpenings[id] = openingsToExport
            }
          })
        })

      // only add the type in case there are elements to export, sort
      // everything (down to the smallest level) alphabetically as requested by
      // the backend
      if (Object.keys(exportedIds).length) {
        collector[idsByType.type] = Object.keys(exportedIds)
          .sort((a, b) => a.localeCompare(b, 'de'))
          .reduce((collector2, id) => {
            collector2[id] = exportedIds[id].sort((a, b) => a.localeCompare(b, 'de'))
            return collector2
          }, {} as { [ifcId: string]: string[] })
      }

      return collector
    }, {} as { [ifcType: string]: { [ifcId: string]: string[] } })

  // only add the openings in case there are elements to export, sort everything
  // (down to the smallest level) alphabetically as requested by the backend
  if (Object.keys(exportedOpenings).length) {
    data.openings = Object.keys(exportedOpenings)
      .sort((a, b) => a.localeCompare(b, 'de'))
      .reduce((collector, id) => {
        collector[id] = exportedOpenings[id].sort((a, b) => a.localeCompare(b, 'de'))
        return collector
      }, {} as { [ifcId: string]: string[] })
  }

  await postRequest({
    url: config.apiRoutes.postFilterOutput(projectId),
    data,
  })
}
