import { Box3, Vector3 } from 'three'

import { toImmutable } from '@modugen/scene/lib'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import { PlanarModel } from '../types'

// bounding box calculation is buggy for extruded geometries (see
// bit.ly/3pf2UDm), hence we need to do it manually for the camera
// rotation below
export function getCenterFromPlanarModel(model: PlanarModel): ImmutableVector3 {
  const customBoundingBox = new Box3()
  model.walls.forEach(wall => {
    wall.shape.points.forEach(p => customBoundingBox.expandByPoint(new Vector3(p.x, p.y, p.z)))
  })

  model.slabs.forEach(slab => {
    slab.shape.points.forEach(p => customBoundingBox.expandByPoint(new Vector3(p.x, p.y, p.z)))
  })

  model.roof_slabs.forEach(roofSlab => {
    roofSlab.shape.points.forEach(p => customBoundingBox.expandByPoint(new Vector3(p.x, p.y, p.z)))
  })

  return toImmutable(customBoundingBox.getCenter(new Vector3()))
}
