import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useStoreHotkey from 'src/hooks/useStoreHotkey'

import { IfcElementsFilter } from '../../types'
import { AFAccordion, AFContentLine, AFDeleteButton } from './AFElements'

export function ActiveFilters(props: {
  filters: IfcElementsFilter[]
  removeFilter: (indexToRemove: number) => void
}): ReactElement {
  const { t } = useTranslation('step3Filter')

  const [activePanel, setActivePanel] = useState<number | undefined>(props.filters.length - 1)

  // always open the last element in case a new filter gets added or removed
  useEffect(() => {
    setActivePanel(props.filters.length - 1)
  }, [props.filters])

  useStoreHotkey({
    keys: 'd',
    description: t('hotkeys.deleteLastFilter'),
    callback: () => {
      props.removeFilter(props.filters.length - 1)
    },
    deps: [props.filters, props.removeFilter],
  })

  return (
    <>
      {props.filters.map((filter, index) => (
        <AFAccordion
          title={`${t('labels.filter')} ${index + 1}`}
          expanded={activePanel === index}
          onChange={() => setActivePanel(index === activePanel ? undefined : index)}
          key={index}
        >
          <AFContentLine title={`${t('labels.filteredElements')}:`} value={filter.length} />
          <AFDeleteButton
            onDelete={() => props.removeFilter(index)}
            label={`${t('labels.removeFilter')} ${
              props.filters.length - 1 === index ? ' (d)' : ''
            }`}
          />
        </AFAccordion>
      ))}
    </>
  )
}
