import flatten from 'flat'
import produce from 'immer'
import { filter, find } from 'lodash-es'

import { useMemo } from 'react'
import { singletonHook } from 'react-singleton-hook'

import { directIfcExports } from 'src/config/exportConfig'

import { useAssignmentsStore } from '../stores/assignmentsStore'
import { useDerivedIfcDataStore } from '../stores/derivedIfcDataStore'
import { useFiltersStore } from '../stores/filtersStore'
import { useIfcElementsStore } from '../stores/ifcElementsStore'
import {
  IfcElementsByType,
  IfcElement,
  IfcGroups,
  IfcIdsByType,
  IfcElementAssignments,
  SearchableIfcElements,
} from '../types'

function useDerivedIfcDataFn(): {
  trueIfcGroups: IfcGroups
  groupIdByChildId: Record<string, string>
  mergedSelectedIds: Set<string>
  mergedFilteredIds: Set<string>
  reassignedIfcElements: IfcElement[] | null
  searchableIfcElements: SearchableIfcElements
  ifcIdsByType: IfcIdsByType[]
  notExportedIds: string[]
} {
  // VANILLA ELEMENTS

  const ifcElements = useIfcElementsStore(state => state.ifcElements)
  const ifcGroups = useIfcElementsStore(state => state.ifcGroups)

  const selectedGroupIds = useIfcElementsStore(state => state.selectedGroupIds)
  const selectedStandAloneIds = useIfcElementsStore(state => state.selectedStandAloneIds)

  const assignments = useAssignmentsStore(state => state.assignments)
  const filters = useFiltersStore(state => state.filters)

  // AGGREGATES RELATED

  // in some cases we receive "false groups", e.g. groups that only
  // consist of one element and / or contain itself. we treat "false groups"
  // as simple stand-alone elements
  const trueIfcGroups = useMemo(() => {
    if (!ifcGroups) return {}

    // create a list of groups that do not contain themselves
    const trueIfcGroups = Object.entries(ifcGroups).reduce((collector, [groupId, childIds]) => {
      const trueChildren = childIds.filter(childId => childId !== groupId)
      if (trueChildren.length > 0) collector[groupId] = trueChildren
      return collector
    }, {} as IfcGroups)

    // remove all groups that are also child to another group
    Object.values(trueIfcGroups).forEach(childIds => {
      childIds.forEach(childId => {
        if (trueIfcGroups[childId]) delete trueIfcGroups[childId]
      })
    })

    useDerivedIfcDataStore.getState().setPartialState({ trueIfcGroups })

    return trueIfcGroups
  }, [ifcGroups])

  // lookup dict to easily verify that an element is / is not inside an
  // group and for finding its parent (= group) id. based on
  // trueGroups, groups containing itself are therefore not included
  const groupIdByChildId = useMemo(() => {
    if (!trueIfcGroups) return {}

    const groupIdByChildId = Object.entries(trueIfcGroups).reduce(
      (collector, [groupId, childIds]) => {
        childIds.forEach(childId => (collector[childId] = groupId))
        return collector
      },
      {} as Record<string, string>,
    )

    useDerivedIfcDataStore.getState().setPartialState({ groupIdByChildId })

    return groupIdByChildId
  }, [trueIfcGroups])

  // SELECTION, ASSIGNMENTS AND FILTERS RELATED

  // all ids from all selections, including child ids from groups. mainly
  // used for marking or filtering selected elements
  const mergedSelectedIds = useMemo(() => {
    const mergedSelectedIds = new Set<string>()

    selectedGroupIds.forEach(id => mergedSelectedIds.add(id))
    selectedStandAloneIds.forEach(id => mergedSelectedIds.add(id))

    useDerivedIfcDataStore.getState().setPartialState({ mergedSelectedIds })

    return mergedSelectedIds
  }, [selectedGroupIds, selectedStandAloneIds, trueIfcGroups])

  // all ids from all filters merged into one (black-)list
  const mergedFilteredIds = useMemo(() => {
    const mergedFilteredIds = new Set<string>()

    filters.forEach(filter => filter.forEach(id => mergedFilteredIds.add(id)))

    useDerivedIfcDataStore.getState().setPartialState({ mergedFilteredIds })

    return mergedFilteredIds
  }, [filters])

  // reassigned groups, group children and stand-alone elements
  const reassignedIfcElements = useMemo(() => {
    // all assignments merged into a single dict (which automatically reduces
    // duplicate assignments to the last assignment)
    const mergedAssignments = assignments.reduce((collector, elementAssignments) => {
      // in case the element is a group we also reassign its children
      const childAssignments = elementAssignments.reduce((collector, assignment) => {
        if (trueIfcGroups[assignment.element_guid]) {
          trueIfcGroups[assignment.element_guid].forEach(childId => {
            return [
              ...filter(collector, { element_guid: childId }),
              {
                element_guid: childId,
                element_type: assignment.element_type,
              },
            ]
          })
        }

        return collector
      }, [] as IfcElementAssignments)

      return [...collector, ...elementAssignments, ...childAssignments]
    }, [] as IfcElementAssignments)

    return produce(ifcElements, draftState => {
      draftState?.forEach(ifcElement => {
        const assignment = find(mergedAssignments, { element_guid: ifcElement.PSET_data.id })
        if (assignment) {
          // TODO: consider reassigning the IfcType as well
          ifcElement.PSET_data.MGroup = assignment.element_type
        }
      })
    })
  }, [ifcElements, assignments, trueIfcGroups])

  // flattened ifc elements mainly used for simplified and faster lookups (for
  // example for matching all elements with the same properties)
  const searchableIfcElements = useMemo(() => {
    const searchableIfcElements = !reassignedIfcElements
      ? {}
      : reassignedIfcElements?.reduce((collector, ifcElement) => {
          // mainly used for reconstructing IfcElement objects
          const flatDict: Record<string, string | number> = flatten(ifcElement)

          // mainly used for searching exact property matches and finding the
          // intersecting (=equal) properties of multiple IfcElements
          const flatSet: Set<string> = new Set(
            Object.entries(flatDict).map(([key, value]) => `${key}.${value}`),
          )

          collector[ifcElement.PSET_data.id] = { ifcElement, flatDict, flatSet }

          return collector
        }, {} as SearchableIfcElements)

    useDerivedIfcDataStore.getState().setPartialState({ searchableIfcElements })

    return searchableIfcElements
  }, [reassignedIfcElements])

  // TYPES RELATED

  // grouped-by-type object mainly used for selecting ifc groups and
  // elements inside the scene as well as for exporting the final result
  const ifcIdsByType = useMemo(() => {
    if (!reassignedIfcElements) return []

    return Object.entries(getIfcElementsByType(reassignedIfcElements))
      .map(([type, elements]) => {
        const { groupIds, standAloneIds } = elements.reduce(
          (collector, element) => {
            const elementId = element.PSET_data.id

            // element is a group
            if (trueIfcGroups[elementId]) collector.groupIds.push(elementId)
            // element is not a group and not a group child
            else if (!groupIdByChildId[elementId]) collector.standAloneIds.push(elementId)
            // in context of types we do not care about group children as
            // they can be selected through their parents

            return collector
          },
          { groupIds: [], standAloneIds: [] } as {
            groupIds: string[]
            standAloneIds: string[]
          },
        )

        return {
          type,
          groupIds,
          standAloneIds,
          visible: directIfcExports.includes(type),
        } as IfcIdsByType
      })
      .sort((a, b) => (a === b ? 0 : a.type < b.type ? -1 : 1))
  }, [reassignedIfcElements, trueIfcGroups, groupIdByChildId])

  // blacklist for filtering out all ifc elements not to be imported
  const notExportedIds = useMemo(() => {
    if (!ifcElements) return []

    const exportedIds = ifcIdsByType
      .filter(idsByType => directIfcExports.includes(idsByType.type))
      .reduce((collector, idsByType) => {
        return [
          ...collector,
          ...idsByType.standAloneIds,
          ...idsByType.groupIds.reduce(
            (collector2, id) => [...collector2, id, ...trueIfcGroups[id]],
            [] as string[],
          ),
        ]
      }, [] as string[])
      .filter(id => !mergedFilteredIds.has(id))

    return ifcElements
      .filter(element => !exportedIds.includes(element.PSET_data.id))
      .map(element => element.PSET_data.id)
  }, [ifcElements, ifcIdsByType, mergedFilteredIds])

  return {
    trueIfcGroups,
    groupIdByChildId,
    mergedSelectedIds,
    mergedFilteredIds,
    reassignedIfcElements,
    searchableIfcElements,
    ifcIdsByType,
    notExportedIds,
  }
}

// in order to reduce the execution of the (relatively) expensive filter
// functions to a minimum, we convert the hook into a singleton. more context to
// be found here: https://github.com/Light-Keeper/react-singleton-hook
export const useDerivedIfcData = singletonHook(
  {
    trueIfcGroups: {},
    groupIdByChildId: {},
    mergedSelectedIds: new Set<string>(),
    mergedFilteredIds: new Set<string>(),
    reassignedIfcElements: [],
    searchableIfcElements: {},
    ifcIdsByType: [],
    notExportedIds: [],
  },
  useDerivedIfcDataFn,
)

function getIfcElementsByType(ifcElements: IfcElement[]): IfcElementsByType {
  return ifcElements.reduce((collector, ifcElement) => {
    if (!collector[ifcElement.PSET_data.MGroup]) collector[ifcElement.PSET_data.MGroup] = []
    collector[ifcElement.PSET_data.MGroup].push(ifcElement)
    return collector
  }, {} as IfcElementsByType)
}
