import { Vector3 } from 'three'

import { removeDuplicatePoints, toImmutable } from '@modugen/scene/lib/utils'

import { Opening } from '../types'

// project openings on plane defined by x & y coordinates and remove all
// duplicate vertices caused by the projection
export function projectOpening(opening: Opening) {
  const points = removeDuplicatePoints(
    opening.shape.points.map(point => new Vector3(point.x, point.y, 0)),
  )

  return {
    guid: opening.guid,
    points: points.map(p => toImmutable(p)),
    pointsV: points,
  }
}
