import React, { ReactElement, ReactNode } from 'react'

import { Stack, Typography } from '@mui/material'

import { MuiBox } from 'src/components/generic/MuiBox'

export function CustomSidebar(props: {
  title?: string
  children?: ReactNode
  bottom?: ReactNode
}): ReactElement {
  return (
    <Stack height="100%">
      {props.title && (
        <Typography variant="h5" p={2} m={0}>
          {props.title}
        </Typography>
      )}

      <Stack flexGrow={1} pl={2} pr={2} sx={{ overflowY: 'auto' }} mb={2}>
        {props.children}
      </Stack>

      {props.bottom && (
        <MuiBox flexShrink={0} p={2}>
          {props.bottom}
        </MuiBox>
      )}
    </Stack>
  )
}
